export enum EadContentTypeEnum {
    VIDEO = 'VIDEO',
    IMAGE = 'IMAGE',
    HTML = 'HTML',
    PDF = 'PDF',
    TEST = 'TEST',
    UPLOAD = 'UPLOAD',
    FORUM_POST = 'FORUM_POST',
    YOUTUBE = 'YOUTUBE',
}
