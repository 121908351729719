import React from 'react'
import styled from 'styled-components'
import { IconICP } from 'common/framework-ui/components/icons/inner/IconICP'
import { FlexCP } from 'common/framework-ui/components/flex/FlexCP'
import { ProgressCP } from 'common/framework-ui/components/progress/ProgressCP'
import { MathUtils } from 'common/framework-ui/utils/MathUtils'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import defaultBgImg from 'modules/auth/components/auth-screen-cover/inner/auth-cover.jpg'
import { UserPurchaseResponseDTO } from 'submodules/neriteduca-sdk/services/sales/invoice-account/dtos/response/UserPurchaseResponseDTO'
import { ClassTypeEnum } from 'submodules/neriteduca-sdk/services/product/product/enums/ClassTypeEnum'
import { InvoiceAccountStatusEnum } from 'submodules/neriteduca-sdk/services/sales/old/invoices/enums/InvoiceAccountStatusEnum'

interface ICPProps {
    purchase: UserPurchaseResponseDTO
}

/**
 * Card de um curso comprado pelo usuario.
 */
export function UserPurchaseCoverICP(props: ICPProps): JSX.Element {

    const isActive: boolean = [InvoiceAccountStatusEnum.OK, InvoiceAccountStatusEnum.CONCLUDED].includes(props.purchase.invoiceAccountStatus)

    return (
        <ContentWrapperSCP
            isActive={isActive}
        >

            <div
                className={'image'}
                style={{ backgroundImage: `url('${props.purchase.productMainImageUrl ?? defaultBgImg}')`}}
            />

            <div className={'overlay'}>

                {
                    props.purchase.classType === ClassTypeEnum.VIRTUAL &&
                    <>
                        <FlexCP justifyContent={'center'}>
                            <IconICP iconName={'play-circle'} size={44} color={'#FFF'}/>
                        </FlexCP>

                        <div className={'progress'}>
                            <div className="text-progress">
                                <span>{MathUtils.getPercentage(props.purchase.onlineTypeData.totalEadContentsWatched, props.purchase.onlineTypeData.totalEadContents, true)}%</span>
                                <span>Conclusão</span>
                            </div>
                            <ProgressCP
                                percent={MathUtils.getPercentage(props.purchase.onlineTypeData.totalEadContentsWatched, props.purchase.onlineTypeData.totalEadContents, true)}
                                showInfo={false}
                                primaryColor={ThemeAnt.successColor}
                            />
                        </div>
                    </>
                }

            </div>

        </ContentWrapperSCP>
    )
}

const ContentWrapperSCP = styled.div<{ isActive: boolean }>`

  cursor: ${props => props.isActive ? 'pointer' : 'not-allowed'};
  flex: 3;
  background: cyan;
  position: relative;
  border-radius: 0.5rem 0.5rem 0 0;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-color: var(--primary-color);
    z-index: 10;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 15;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(180deg, rgba(255,255,255,0) -30%, var(--primary-color) 75%);
    border-radius: 0.5rem 0.5rem 0 0;

    .progress {

      padding: 10px;

      .text-progress {
        width: 85%;
        padding: 10px 0;
        color: #fff;
        font-size: 0.68rem;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
      }

      .initial-days {
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: 700;
        box-sizing: border-box;
        padding-top: 5rem;
        padding-left: 0.5rem;

        svg {
          flex: 2;
        }

        span {
          flex: 6;
          font-size: 0.68rem;
        }
      }
    }
  }

  .type {
    position: absolute;
    color: #fff;
    font-size: 0.8rem;
    text-transform: uppercase;
    top: 0.5rem;
    left: 0;
    z-index: 3333;
    background: var(--secondary-color);
    width: fit-content;
    text-align: left;
    padding: .3rem .5rem;
  }

`
