import { DevTestScreen } from 'common/framework-ui/screens/DevTestScreen'
import { ScreenUserProfile } from 'modules/user/screens/user-profile/ScreenUserProfile'
import React from 'react'
import { ScreenUserCourses } from 'modules/user/screens/user-courses/ScreenUserCourses'
import { ScreenUserCouseDetail } from 'modules/user/screens/user-course-detail/ScreenUserCouseDetail'
import { ScreenEadCourseView } from 'modules/ead/screens/ead-course-view/ScreenEadCourseView'
import { ScreenUserCertificates } from 'modules/user/screens/user-certificates/ScreenUserCertificates'
import { ScreenAdmin } from 'modules/admin/screens/screen-admin-beerads/ScreenAdmin'
import { RouterSwitchCP } from 'submodules/nerit-framework-ui/common/router/components/router-switch/RouterSwitchCP'
import { ScreenError404 } from 'submodules/nerit-framework-ui/common/screens/ScreenError404'

PrivateRouter.ROOT = 'interna'

PrivateRouter.USER_COURSES_ROOT = `${PrivateRouter.ROOT}/meus-cursos`
PrivateRouter.USER_COURSES_DETAIL = `${PrivateRouter.USER_COURSES_ROOT}/:accountCode/:courseCode`

PrivateRouter.USER_COURSE_EAD_ROOT = `${PrivateRouter.ROOT}/ead`
PrivateRouter.USER_COURSE_EAD_VIEW = `${PrivateRouter.USER_COURSE_EAD_ROOT}/:accountCode/:courseCode/:contentCode`

PrivateRouter.USER_CERTIFICATES_ROOT = `${PrivateRouter.ROOT}/certificados`

PrivateRouter.ADMIN_ROOT = `${PrivateRouter.ROOT}/admin`
PrivateRouter.ADMIN_IMPORT_SUBSCRIPTIONS = `${PrivateRouter.ADMIN_ROOT}/importar-inscricoes`

PrivateRouter.PROFILE = `${PrivateRouter.ROOT}/perfil`

/**
 * Define rotas que so podem ser acessadas apos realizar login.
 */
export function PrivateRouter(): JSX.Element {

    return (
        <RouterSwitchCP
            routes={[
                { path: PrivateRouter.PROFILE, component: ScreenUserProfile },
                { path: PrivateRouter.USER_COURSES_DETAIL, component: ScreenUserCouseDetail },
                { path: PrivateRouter.USER_COURSE_EAD_VIEW, component: ScreenEadCourseView },
                { path: PrivateRouter.ADMIN_ROOT, component: ScreenAdmin },
                { path: PrivateRouter.USER_CERTIFICATES_ROOT, component: ScreenUserCertificates },
                { path: PrivateRouter.USER_COURSES_ROOT, component: ScreenUserCourses },

                { path: 'dev-test', component: DevTestScreen },

                { component: () => <ScreenError404 redirectTo={PrivateRouter.USER_COURSES_ROOT}/> },
            ]}
        />
    )
}
