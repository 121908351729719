import React from 'react'
import styled from 'styled-components'
import { AppStateUtils } from 'common/framework-ui/utils/AppStateUtils'

/**
 * Logo no cabeçalho do menu.
 */
export function MenuLogoCP(): JSX.Element {

    const systemLogo = AppStateUtils.getDomainData()!.schema.logoUrl ?? `${process.env.PUBLIC_URL}/images/logo.png`

    return (
        <MenuLogoSCP>
            <img
                height={30}
                src={systemLogo}
                alt={'MinhaCentral'}
            />
        </MenuLogoSCP>
    )
}

const MenuLogoSCP = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 13px 0;
`
