import React from 'react'
import styled from 'styled-components'

export interface BasicStyleWrapperCPProps {
    margin?: {
        right?: number,
        left?: number,
        bottom?: number,
        top?: number,
    }
    onClick?: () => void
    className?: string
}

interface ICPProps extends BasicStyleWrapperCPProps {
    children: React.ReactNode
}

/**
 * Card.
 */
export function BasicStyleWrapperCP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP
            margin={props.margin}
            onClick={props.onClick}
            className={props.className}
        >
            { props.children }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<BasicStyleWrapperCPProps>`

  margin-left: ${props => props.margin?.left ?? 0}px;
  margin-right: ${props => props.margin?.right ?? 0}px;
  margin-bottom: ${props => props.margin?.bottom ?? 0}px;
  margin-top: ${props => props.margin?.top ?? 0}px;
  
  cursor: ${props => !!props.onClick ? 'pointer' : undefined};
`
