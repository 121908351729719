import { ConfigProvider as AntDProvider } from 'antd'
import { EmptyCP } from 'common/framework-ui/components/empty/EmptyCP'
import { LoadingOverlayCP } from 'common/framework-ui/components/loading/loading-overlay/LoadingOverlayCP'
import { ReduxHelper } from 'common/framework-ui/redux/helpers/ReduxHelper'
import { ThemeProvider } from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { IThemeAnt } from 'submodules/nerit-framework-ui/theme/ant/IThemeAnt'
import { AppRouter } from 'app/routes/AppRouter'

/**
 */
export function App(): JSX.Element {

    return (
        <ReduxProvider store={ReduxHelper.getInstance().store}>
            <PersistGate loading={<LoadingOverlayCP show={true}/>} persistor={ReduxHelper.getInstance().persistor}>
                <AntDProvider renderEmpty={() => <EmptyCP/>}>
                    <ThemeProvider theme={ThemeAnt as IThemeAnt}>
                        <BrowserRouter>
                            <AppRouter/>
                        </BrowserRouter>
                    </ThemeProvider>
                </AntDProvider>
            </PersistGate>
        </ReduxProvider>
    )
}
