import { TeacherIndividualMessageSendRequestDTO } from 'submodules/neriteduca-sdk/services/product/teacher/dtos/request/TeacherIndividualMessageSendRequestDTO'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NeritEducaSystemApiEnums } from 'submodules/neriteduca-sdk/services/common/NeritEducaSystemApiEnums'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'

export class TeacherRequests {

    public static CONTROLLER_ROOT = 'teachers'
    public static GET_NAMES_EP = ':releaseCode/names'
    public static SEND_MESSAGE_EP = 'send-message'

    static getNames = (code: number): RequestConfigTP => ({
        baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
        url: `${TeacherRequests.CONTROLLER_ROOT}/${TeacherRequests.GET_NAMES_EP.replace(':releaseCode', code.toString())}`,
        method: HttpMethodEnum.GET,
    })

    static sendIndividualMessage (dto: TeacherIndividualMessageSendRequestDTO, formData?: unknown): RequestConfigTP {

        // const formData = new FormData()

        /*
         * if (!!file)
         *     formData.append('file', file)
         */

        // formData.append('data', JSON.stringify(dto))

        return {
            baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
            url: `${TeacherRequests.CONTROLLER_ROOT}/${TeacherRequests.SEND_MESSAGE_EP}`,
            method: HttpMethodEnum.POST,
            params: formData
        }
    }
}
