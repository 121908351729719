import { OrNullTP } from 'common/framework-ui/types/OrNullTP'
import { ReduxHelper } from 'common/framework-ui/redux/helpers/ReduxHelper'
import { OrUndefTP } from 'common/framework-ui/types/OrUndefTP'
import { DomainDataTP } from 'common/framework-ui/redux/types/DomainDataTP'
import { AuthUserResponseDTO } from 'submodules/neriteduca-sdk/services/auth/authenticate/dtos/response/AuthUserResponseDTO'
import { AuthResponseDTO } from 'submodules/neriteduca-sdk/services/auth/authenticate/dtos/response/AuthResponseDTO'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'

/**
 * Para manipular estado global da aplicacao (redux).
 *
 * NOTE: Esses metodos nao podem pertencer a utilitaria do redux por problemas de depencia circular com a helper do redux.
 */
export const AppStateUtils = {

    /** Retorna dados do usuario logado atual. */
    getLoggedUserData(): OrNullTP<AuthResponseDTO> {
        return ReduxHelper.getInstance().store.getState().userData
    },

    /** Retorna dados do usuario logado atual. */
    getLoggedUser(): OrUndefTP<AuthUserResponseDTO> {
        return ReduxHelper.getInstance().store.getState().userData?.user
    },

    /** Retorna dados do cliente (schema) a que pertence o usuario logado atual. */
    getDomainData(): OrNullTP<DomainDataTP> {
        return ReduxHelper.getInstance().store.getState().domainData
    },

    /** Retorna nome do schema/slug do dominio atual. */
    getDomainSlug(): OrUndefTP<string> {
        return AppStateUtils.getDomainData()?.schema?.slug ?? NeritFrameworkRoutingHelper.getUrlCustomerDomain()
    },
}
