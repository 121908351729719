import React from 'react'
import { AppStateUtils } from 'common/framework-ui/utils/AppStateUtils'
import { SystemConfig } from 'config/SystemConfig'
import { EnvironmentEnum } from 'common/framework-ui/enums/EnvironmentEnum'

/**
 * Encapsula chat. Podem ser intercom ou whatsapp
 */
export function ChatBalloonCP(): JSX.Element {

    const loggedUser = AppStateUtils.getLoggedUserData()
    const domainData = AppStateUtils.getDomainData()

    if (SystemConfig.getInstance().environment !== EnvironmentEnum.PROD)
        return <></>

    if (domainData === null || loggedUser === null)
        return <></>

    return (
        <>
        </>
    )
}
