import React, { useEffect, useState } from 'react'
import { useRequest } from 'common/framework-ui/request-manager/use-request/UseRequest'
import { RequestUtils } from 'common/framework-ui/request-manager/RequestUtils'
import { LoadingCP } from 'common/framework-ui/components/loading/LoadingCP'
import { ProductReleaseRequests } from 'submodules/neriteduca-sdk/services/product/product-releases/ProductReleaseRequests'
import { ProductReleaseWelcomePageResponseDTO } from 'submodules/neriteduca-sdk/services/product/product-releases/dtos/responses/ProductReleaseWelcomePageResponseDTO'
import { TitleCP } from 'common/framework-ui/components/title/TitleCP'
import { FlexCP } from 'common/framework-ui/components/flex/FlexCP'
import { CardCP } from 'common/framework-ui/components/card/CardCP'

interface ICPProps {
    productReleaseCode: number
}

/**
 * Aba principal.
 */
export function UserCourseMainTabICP(props: ICPProps): JSX.Element {

    const [productRelease, setProductRelease] = useState<ProductReleaseWelcomePageResponseDTO>()
    const getProductReleaseRequest = useRequest<ProductReleaseWelcomePageResponseDTO>()
    useEffect(onGetProductReleaseRequestChange, [getProductReleaseRequest.isAwaiting])

    useEffect(init, [props.productReleaseCode])

    /**
     * Inicializa
     */
    function init(): void {

        getProductReleaseRequest.runRequest(ProductReleaseRequests.getWelcomePage(props.productReleaseCode))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetProductReleaseRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getProductReleaseRequest, 'Erro obter dados do curso'))
            return

        setProductRelease(getProductReleaseRequest.responseData!)
    }

    if (getProductReleaseRequest.isAwaiting)
        return <LoadingCP show={true} />

    return (
        <CardCP margin={'none'}>
            {
                !productRelease?.welcomePage
                    ?
                    <FlexCP justifyContent={'center'}>
                        <TitleCP>{`Seja bem-vindo ao ${productRelease?.productRelease.name ?? ''}`}</TitleCP>
                    </FlexCP>
                    :
                    <div dangerouslySetInnerHTML={{ __html: productRelease.welcomePage }} />
            }
        </CardCP>
    )
}
