import React from 'react'
import 'video.js/dist/video-js.css'
import { VideoCP } from 'modules/ead/components/video/VideoCP'
import { EadClassContentResponseDTO } from 'submodules/neriteduca-sdk/services/ead/ead-course/dtos/responses/EadClassContentResponseDTO'
import { VideoUtils } from 'modules/ead/components/video/inner/VideoUtils'
import styled from 'styled-components'
import { PdfViewerCP } from 'common/framework-ui/components/pdf-viewer/PdfViewerCP'
import { EadContentTypeEnum } from 'submodules/neriteduca-sdk/services/ead/ead-course/enums/EadContentTypeEnum'
import { CardCP } from 'common/framework-ui/components/card/CardCP'
import { ClassContentFormCP, OnTestActionDoneTP } from 'modules/ead/components/class-content-form/ClassContentFormCP'

interface ICPProps {
    accountCode: number
    classContent: EadClassContentResponseDTO
    onActionTest: (type: OnTestActionDoneTP) => void
}

/**
 * Mostra de acordo com o tipo de conteudo correto.
 */
export function ClassContentByTypeCP(props: ICPProps): JSX.Element {

    return (
        <>
            {
                (props.classContent.type === EadContentTypeEnum.VIDEO || props.classContent.type === EadContentTypeEnum.YOUTUBE) && !!props.classContent.content &&
                <VideoCP
                    options={VideoUtils.getVideoJSOptions(props.classContent.content, props.classContent.type === EadContentTypeEnum.YOUTUBE)}
                />
            }
            {
                props.classContent.type === EadContentTypeEnum.IMAGE &&
                <ImageSCP>
                    <img
                        src={props.classContent.content}
                    />
                </ImageSCP>
            }
            {
                props.classContent.type === EadContentTypeEnum.PDF &&
                <CardCP innerSpacing={'none'} margin={'none'}>
                    <PdfViewerCP
                        file={props.classContent.content}
                        paginationType={'scroll'}
                        viewType={'inline'}
                    />
                </CardCP>
            }
            {
                props.classContent.type === EadContentTypeEnum.HTML && !!props.classContent.content &&
                <CardCP>
                    <div dangerouslySetInnerHTML={{__html: props.classContent.content}}/>
                </CardCP>
            }
            {
                props.classContent.type === EadContentTypeEnum.TEST && !!props.classContent.form &&
                <CardCP>
                    <ClassContentFormCP
                        onDoTestAgain={props.onActionTest}
                        eadForm={props.classContent.form}
                        accountCode={props.accountCode}
                    />
                </CardCP>
            }
        </>
    )
}

const ImageSCP = styled.div`
  img {
    width: 100%;
  }
`
