import React, { useEffect, useState } from 'react'
import { SiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/sider/SiderCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { Layout } from 'antd'

interface ICPProps {
    menu: React.ReactNode
    refreshMenuCollapsed?: number
    privateRouter: React.ReactNode
    extraContent?: React.ReactNode
}

/**
 */
export function LayoutUserMainCP(props: ICPProps): JSX.Element {

    const screenSize = useScreenSize()
    const [isSiderCollapsed, setIsSiderCollapsed] = useState<boolean>(screenSize.sm || screenSize.xs)
    useEffect(() => setIsSiderCollapsed(screenSize.sm || screenSize.xs), [props.refreshMenuCollapsed])

    return (
        <LayoutCP
            mainLayout={true}
            content={
                <ContentSCP>
                    {props.privateRouter}

                    {props.extraContent}
                </ContentSCP>
            }
            sider={
                <SiderCP
                    isMainSider={true}
                    collapsedWidth={0}
                    breakpoint={'sm'}
                    id={'main-menu-id'}
                    collapsed={isSiderCollapsed}
                    onCollapse={() => setIsSiderCollapsed(!isSiderCollapsed)}
                    zeroWidthTriggerStyle={{
                        backgroundColor: ThemeProject.collapsedMenuButtonColor,
                        left: isSiderCollapsed ? 0 : 67,
                        top: 10,
                        zIndex: 10
                    }}
                >
                    {props.menu}
                </SiderCP>
            }
        />
    )
}

const ContentSCP = styled(Layout.Content)`
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
`
