/**
 * UTILITARIOS: para state managers.
 */
import { NotificationHelper } from 'common/framework-ui/helpers/NotificationHelper'
import { IFormStateManager } from 'common/framework-ui/form-state-manager/IFormStateManager'

export const FormStateManagerUtils = {

    /**
     * Ao submeter formulario validar campos obrigatorios.
     */
    async validateRequiredFields(formStateManager: IFormStateManager<any>): Promise<boolean> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate()) {
            NotificationHelper.error('Ops!', 'Preencha todos os campos para prosseguir')
            return false
        }

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return false

        return true
    },

}
