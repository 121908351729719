import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PageHeaderCP } from 'common/framework-ui/components/page-header/PageHeaderCP'
import 'video.js/dist/video-js.css'
import { useRequest } from 'common/framework-ui/request-manager/use-request/UseRequest'
import { EadCourseRequests } from 'submodules/neriteduca-sdk/services/ead/ead-course/EadCourseRequests'
import { RequestUtils } from 'common/framework-ui/request-manager/RequestUtils'
import { LoadingOverlayCP } from 'common/framework-ui/components/loading/loading-overlay/LoadingOverlayCP'
import { EadClassContentResponseDTO } from 'submodules/neriteduca-sdk/services/ead/ead-course/dtos/responses/EadClassContentResponseDTO'
import { ButtonsClassContentNavCP } from 'modules/ead/components/buttons-class-content-nav/ButtonsClassContentNavCP'
import { FlexCP } from 'common/framework-ui/components/flex/FlexCP'
import { useScreenSize } from 'common/framework-ui/responsiveness/use-screen-size/UseScreenSize'
import { ClassContentByTypeCP } from 'modules/ead/components/class-content/inner/ClassContentByTypeCP'
import { EadContentTypeEnum } from 'submodules/neriteduca-sdk/services/ead/ead-course/enums/EadContentTypeEnum'
import { EadClassContentRequestDTO } from 'submodules/neriteduca-sdk/services/ead/ead-course/dtos/requests/EadClassContentRequestDTO'
import { BoxCustomerSurveyCP } from 'app/components/customer-survey-box/BoxCustomerSurveyCP'

interface ICPProps {
    productReleaseCode: number
    accounCode: number
    classContentCode: number
}

/**
 * Navegacao pelo curso EAD.
 */
export function ClassContentCP(props: ICPProps): JSX.Element {

    const screenSize = useScreenSize()

    const [eadClassContent, setEadClassContent] = useState<EadClassContentResponseDTO>()
    const getEadCourseStructureRequest = useRequest<EadClassContentResponseDTO>()
    useEffect(onGetEadCourseStructureRequestChange, [getEadCourseStructureRequest.isAwaiting])

    useEffect(init, [props.classContentCode])

    /**
     * Inicializa
     */
    function init(shouldDoTestAgain?: boolean): void {

        const dto: EadClassContentRequestDTO = {
            classContentCode: props.classContentCode,
            accountCode: props.accounCode,
            shouldLoadAsNewTest: shouldDoTestAgain,
        }

        getEadCourseStructureRequest.runRequest(EadCourseRequests.getClassContent(dto))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetEadCourseStructureRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getEadCourseStructureRequest, 'Erro obter conteudo do curso'))
            return

        setEadClassContent(getEadCourseStructureRequest.responseData!)
    }

    if (getEadCourseStructureRequest.isAwaiting)
        return <LoadingOverlayCP show={true}/>

    if (!eadClassContent)
        return <></>

    return (
        <ContentWrapperSCP>
            <PageHeaderCP
                backgroundColor={'#0E1529'}
                title={eadClassContent.name}
            >
                <>
                    {
                        (eadClassContent.type !== EadContentTypeEnum.TEST || !!eadClassContent.form?.userFormAnswer) &&
                        <ButtonsClassContentNavCP
                            accountCode={props.accounCode}
                            productReleaseCode={props.productReleaseCode}
                            nextContentCode={eadClassContent.nextContentCode}
                            previousContentCode={eadClassContent.previousContentCode}
                            hideLabel={screenSize.smd}
                        />
                    }
                </>
            </PageHeaderCP>

            <ClassContentByTypeCP
                classContent={eadClassContent}
                accountCode={props.accounCode}
                onActionTest={(type) => init(type === 'redoTest')}
            />

            {
                !eadClassContent.nextContentCode &&
                <BoxCustomerSurveyCP boxType={'inline'} forceOpen={true}/>
            }

            <FlexCP justifyContent={'center'} marginTop={30}>
                <>
                    {
                        (eadClassContent.type !== EadContentTypeEnum.TEST || !!eadClassContent.form?.userFormAnswer) &&
                        <ButtonsClassContentNavCP
                            accountCode={props.accounCode}
                            productReleaseCode={props.productReleaseCode}
                            nextContentCode={eadClassContent.nextContentCode}
                            previousContentCode={eadClassContent.previousContentCode}
                        />
                    }
                </>
            </FlexCP>
        </ContentWrapperSCP>
    )
}

const ContentWrapperSCP = styled.div`

    .ant-layout-content {
      background: black;
    }
    
`
