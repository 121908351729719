import React from 'react'
import 'video.js/dist/video-js.css'
import { AppStateUtils } from 'common/framework-ui/utils/AppStateUtils'
import { ModalBoxCustomerSurveyCP } from 'submodules/space4leads-components-ui/survey/components/box-customer-survey/ModalBoxCustomerSurveyCP'

interface ICPProps {
    boxType: 'center' | 'bottom' | 'inline'
    forceOpen?: boolean
}

/**
 * Wrapper do Space4Leads.
 */
export function BoxCustomerSurveyCP(props: ICPProps): JSX.Element {

    // Verifica os dominios integrados
    if (AppStateUtils.getDomainSlug() !== 'rl')
        return <></>

    return (
        <ModalBoxCustomerSurveyCP
            person={{ name: AppStateUtils.getLoggedUserData()!.user.name, email: AppStateUtils.getLoggedUserData()!.user.email}}
            timeConfig={{
                daysBetweenAnswers: 15,
                daysToFirstAnswer: 3,
                daysToReopenOnCancel: 5,
            }}
            boxType={props.boxType}
            surveyToken={'MTc3'}
            projectCode={118}
            forceOpen={props.forceOpen}
        />
    )
}
