import React, { useState } from 'react'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { TableCP } from 'submodules/nerit-framework-ui/common/components/table/TableCP'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'
import { LinkCP } from 'common/framework-ui/components/link/LinkCP'
import { UserCertificateScoreByTestResponseDTO } from 'submodules/neriteduca-sdk/services/sales/invoice-account/dtos/response/inner/UserCertificateScoreByTestResponseDTO'

interface ICPProps {
    userScores: UserCertificateScoreByTestResponseDTO[]
}

/**
 * Tabela de Certificados do usuario.
 */
export function PopoverUserScoreDetailCP(props: ICPProps): JSX.Element {

    const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)

    return (
        <PopOverCP
            visible={isPopoverVisible}
            title={'Detalhes da Nota'}
            content={(
                <TableCP
                    data={props.userScores}
                    columns={[
                        {
                            title: 'Questionário',
                            render: (text, item) => item.title,

                        },
                        {
                            title: 'Nota / Total',
                            render: (text, item) => `${MaskUtils.applyMask(item.score, InputMaskTypeEnum.NUMBER)} / ${MaskUtils.applyMask(item.testTotalValue, InputMaskTypeEnum.NUMBER)}`,
                        },
                        {
                            title: 'Resultado / Desejado',
                            render: (text, item) => `${MaskUtils.applyMask(item.performance, InputMaskTypeEnum.NUMBER)} / ${MaskUtils.applyMask(item.testPerformanceToBeAproved, InputMaskTypeEnum.NUMBER)}`,
                        },
                        {
                            render: (text, item) => (
                                <TagCP
                                    content={item.isApproved ? 'Aprovado' : 'Não aprovado'}
                                    color={item.isApproved ? 'green' : 'red'}
                                />
                            ),
                        },
                    ]}
                />
            )}
        >
            <LinkCP
                text={'Ver detalhes da nota'}
                onClick={() => setIsPopoverVisible(true)}
            />
        </PopOverCP>
    )
}
