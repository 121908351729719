import React from 'react'
import { BulkOperationResponseDTO } from 'submodules/nerit-micro-services-sdk/services/bulk/dtos/responses/BulkOperationResponseDTO'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { BulkOperationImportSummaryICP } from 'submodules/nerit-micro-services-ui/common/components/table-bulk-operations/inner/BulkOperationImportSummaryICP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import styled from 'styled-components'
import { IBulkOperationCustomArgs } from 'submodules/nerit-micro-services-sdk/services/bulk/interfaces/IBulkOperationCustomArgs'

interface IImportTableColumnTotalICPProps {
    log: BulkOperationResponseDTO<IBulkOperationCustomArgs>
}

/**
 * Coluna de TOTAL da tabela de historico de importacao.
 */
export function BulkOperationTableColumnTotalICP(props: IImportTableColumnTotalICPProps): JSX.Element {

    const total = (props.log.totalRecordsSuccessfully > (props.log.totalRecords ?? 0)) ? props.log.totalRecordsSuccessfully : props.log.totalRecords

    return (
        <PopOverCP
            trigger={'hover'}
            content={<BulkOperationImportSummaryICP log={props.log}/>}
        >
            <PopoverContainerSCP>
                <TextCP size={'small'} text={`${props.log.totalRecordsSuccessfully} / ${total ?? '-'}`}/>
            </PopoverContainerSCP>
        </PopOverCP>
    )
}

const PopoverContainerSCP = styled.div`
    cursor: help;
`
