export enum InvoiceAccountStatusEnum {
    PENDING = 'PENDING',
    OK = 'OK',
    DEFAULTING = 'DEFAULTING',
    CONCLUDED = 'CONCLUDED',
    CANCELLED = 'CANCELLED',
}

export enum InvoiceAccountStatusLabelEnum {
    PENDING = 'Pagamento pendente',
    OK = 'Em dia',
    DEFAULTING = 'Inadimplente',
    CONCLUDED = 'Pago',
    CANCELLED = 'Cancelado',
}
