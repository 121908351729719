export enum BulkOperationStatusEnum {
    IN_PROGRESS = 'IN_PROGRESS',
    CONCLUDED = 'CONCLUDED',
    ERROR = 'ERROR'
}

export enum BulkOperationStatusLabelEnum {
    IN_PROGRESS = 'Em andamento',
    CONCLUDED = 'Concluído',
    ERROR = 'Erro',
}
