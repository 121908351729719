import React from 'react'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { IIconProps } from 'submodules/nerit-framework-ui/common/components/icon/inner/IIconProps'

export const HelpIconCP = (props: IIconProps): JSX.Element => {

    return (
        <IconICP
            iconName={'question-circle'}
            size={props.size}
            color={props.color}
            style={props.style}
        />
    )
}
