import React from 'react'
import 'video.js/dist/video-js.css'
import { ButtonCP } from 'common/framework-ui/components/button/ButtonCP'
import { FlexCP } from 'common/framework-ui/components/flex/FlexCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PrivateRouter } from 'app/routes/PrivateRouter'
import { EadCourseViewScreenUtils } from 'modules/ead/screens/ead-course-view/inner/EadCourseViewScreenUtils'

interface ICPProps {
    productReleaseCode: number
    mode: 'icon' | 'text'
}

export function ButtonExitCourseView(props: ICPProps): JSX.Element {

    return (
        <FlexCP
            marginBottom={5}
            justifyContent={'center'}
        >
            <ButtonCP
                icon={props.mode === 'icon' ? 'poweroff' : undefined}
                onClick={() => {
                    EadCourseViewScreenUtils.showOrHideMainMenu('show')
                    NeritFrameworkRoutingHelper.historyPush(`${PrivateRouter.USER_COURSES_ROOT}/${props.productReleaseCode}`)
                }}
            >
                {props.mode === 'text' && 'Voltar menu principal'}
            </ButtonCP>
        </FlexCP>
    )
}
