import React from 'react'
import { Icon } from 'antd'
import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'

type ScpPropsTP = {
    marginRight?: number,
    marginLeft?: number,
    marginTop?: number,
    marginBottom?: number,
}

export interface IIconCPProps extends ScpPropsTP {
    antIcon: string
    color?: string
}

/**
 * Componente WRAPPER de Icone
 */
export function IconCP(props: IIconCPProps): JSX.Element {

    return (
        <WrapperSCP
            marginTop={props.marginTop ?? 0}
            marginRight={props.marginRight ?? 0}
            marginBottom={props.marginBottom ?? 0}
            marginLeft={props.marginLeft ?? 0}
        >
            <Icon
                type={props.antIcon}
                style={{ color: props.color }}
            />
        </WrapperSCP>
    )

}

const WrapperSCP = styled.span<ScpPropsTP>`
    margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
`
