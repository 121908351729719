import { AuthActions } from 'modules/auth/AuthActions'
import { RequestHelper } from 'common/framework-ui/request-manager/RequestHelper'
import { AxiosResponse } from 'axios'
import { IApiReturn } from 'common/framework-ui/interfaces/IApiReturn'
import { SchemaResponseDTO } from 'submodules/neriteduca-sdk/services/sys-admin/schema/dtos/response/SchemaResponseDTO'
import { SysAdminRequests } from 'submodules/neriteduca-sdk/services/sys-admin/SysAdminRequests'

/**
 */
export class RoutingHelper {

    /**
     */
    public static async isValidSchema(schema?: string): Promise<boolean> {

        if (!schema)
            return false

        try {

            const reqConfig = SysAdminRequests.validateSchema(schema)
            const validationReturn = await RequestHelper.runRequest(reqConfig)

            const schemaData = ((validationReturn as AxiosResponse)?.data as IApiReturn<SchemaResponseDTO>)?.data
            if (!schemaData)
                return false

            AuthActions.setDomain(schemaData)
            return true

        } catch (err) {
            return false
        }
    }

}
