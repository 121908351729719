import * as _ from 'lodash'

/**
 * UTILS
 * Encapsula logica auxiliar util para manipulacao de vetores.
 */
export class ArrayUtils {

	/** Retorno numero do ultimo indice valido num vetor. */
	static getLastIndex (array: any[]): number {
		return array.length ? (array.length - 1) : 0
	}

	/** Retorna 01 vetor excluindo itens que excedam o comprimento maximo desejado. */
	static limitLength (array: any[], maxLength: number): any[] {
		if (array.length > maxLength)
			array.splice(maxLength, array.length - maxLength)
		return array
	}

	static placeItemAt<ItemTP>(list: ItemTP[], targetValue: ItemTP, targetIndex: number): ItemTP[]
	static placeItemAt<ItemTP, PropTP extends keyof ItemTP>(list: ItemTP[], targetValue: ItemTP[PropTP], targetIndex: number, propName: PropTP,): ItemTP[]

	/**
	 * Procura 01 determinado item especifico dentro de 01 vetor & colaca-o numa posicao especifica dentro do mesmo vetor.
	 * NOTE: Vetor original eh modificado por referencia.
	 * NOTE: Este metodo NAO ordena o vetor inteiro (apenas muda 01 elemento de lugar).
	 */
	static placeItemAt<ItemTP, PropTP extends keyof ItemTP = any> (
		list: ItemTP[],
		targetValue: ItemTP | ItemTP[PropTP],
		targetIndex: number,
		propName?: PropTP,
	): ItemTP[] {

		if (!list.length)
			return []

		const isObjList = (typeof list[0] === 'object')

		for (const [i, item] of list.entries()) {

			const isMatch = (isObjList && propName) ? (item[propName] === targetValue) : (item === targetValue)
			if (!isMatch)
				continue

			if (i !== targetIndex) {
				const [targetItem] = list.splice(i, 1)
				const piece1 = list.splice(0, targetIndex)
				list = [...piece1, targetItem, ...list]
			}

			break
		}

		return list
	}

	/** Se esta vazio. */
	static isEmpty (value: unknown): boolean {
		return ((typeof value !== 'object' && !value)
            || (typeof value === 'object' && _.isEmpty(value))
		)
	}

	/** Gera & retorna vetor de numeros inteiros compreendidos entre os limites superior & inferior (padrao = 0) estabelecidos. */
	static getRange (max: number, min?: number): number[] {
		const range = Array.from(new Array(max + 1).keys())
		return min ? range.map(i => i + min) : range
	}

	/** Verifica se o o arrayOne contem todos elementos do targetArray. */
	static hasAllElements (arrayOne: any[], targetArray: any[]): boolean {
		return targetArray.every(v => arrayOne.includes(v))
	}

}
