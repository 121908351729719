import React from 'react'
import { TitleCP } from 'common/framework-ui/components/title/TitleCP'
import { ListCP } from 'common/framework-ui/components/list/ListCP'
import { ListItemICP } from 'common/framework-ui/components/list/inner/ListItemICP'
import { FlexCP } from 'common/framework-ui/components/flex/FlexCP'
import { ButtonCP } from 'common/framework-ui/components/button/ButtonCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PrivateRouter } from 'app/routes/PrivateRouter'
import { EadCourseModuleClassStructureResponseDTO } from 'submodules/neriteduca-sdk/services/ead/ead-course/dtos/responses/inner/EadCourseModuleClassStructureResponseDTO'
import { EadCourseStructureViewModeTP } from 'modules/ead/components/collapse-ead-course-structure/inner/EadCourseStructureViewModeTP'
import { IconByContentCP } from 'modules/ead/components/icon-by-content-type/IconByContentCP'
import { IconByContentUtils } from 'modules/ead/components/icon-by-content-type/inner/IconByContentUtils'
import styled from 'styled-components'
import { ClassContentNameStyledByViewCP } from 'modules/ead/components/class-content-name-styled-by-viewed/ClassContentNameStyledByViewCP'

interface ICPProps {
    eadCourseModuleClassStructure: EadCourseModuleClassStructureResponseDTO
    accountCode: number
    productReleaseCode: number
    viewMode: EadCourseStructureViewModeTP
    titleColor?: string
}

export function EadCourseStructureCollapseItemICP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <TitleCP
                textSize={'normal'}
                color={props.titleColor}
                marginTop={20}
            >
                <ClassContentNameStyledByViewCP
                    isViewed={props.eadCourseModuleClassStructure.isViewed}
                    text={props.eadCourseModuleClassStructure.eadClass.name}
                />
            </TitleCP>

            <ListCP
                dataSource={props.eadCourseModuleClassStructure.eadContents}
                renderItem={(contentStructure) => (
                    <ListItemICP
                        avatar={
                            props.viewMode === 'full'
                                ? <IconByContentCP contentType={contentStructure.type} />
                                : undefined
                        }
                        title={
                            props.viewMode === 'full'
                                ?
                                <FlexCP justifyContent={'space-between'} alignItems={'center'}>
                                    <ClassContentNameStyledByViewCP
                                        isViewed={contentStructure.isViewed}
                                        text={contentStructure.eadContent.name}
                                    />
                                    <ButtonCP
                                        onClick={() => NeritFrameworkRoutingHelper.historyPush(`${PrivateRouter.USER_COURSE_EAD_ROOT}/${props.accountCode}/${props.productReleaseCode}/${contentStructure.eadContent.code}`)}
                                    >
                                        Ir para aula
                                    </ButtonCP>
                                </FlexCP>
                                :
                                <ButtonCP
                                    icon={IconByContentUtils.getIconName(contentStructure.type)}
                                    onClick={() => NeritFrameworkRoutingHelper.historyPush(`${PrivateRouter.USER_COURSE_EAD_ROOT}/${props.accountCode}/${props.productReleaseCode}/${contentStructure.eadContent.code}`)}
                                    type={'primary'}
                                >
                                    <ClassContentNameStyledByViewCP
                                        isViewed={contentStructure.isViewed}
                                        text={contentStructure.eadContent.name}
                                    />
                                </ButtonCP>

                        }
                    />
                )}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  .ant-list-item {
    overflow: hidden;
  }
`
