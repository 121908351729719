import React from 'react'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { TableUserCertificatesCP } from 'modules/user/components/table-user-certificates/TableUserCertificatesCP'

/**
 * Certificados do usuario.
 */
export function ScreenUserCertificates(): JSX.Element {

    return (
        <>
            <HeaderCP title={'Meus Certificados'}/>

            <TableUserCertificatesCP/>
        </>
    )
}
