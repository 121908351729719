import { Upload } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { UploadListType } from 'antd/lib/upload/interface'
import React from 'react'
import * as _ from 'lodash'
import { MimeTypeEnum } from 'common/framework-ui/enums/MimeTypeEnum'
import { NotificationHelper } from 'common/framework-ui/helpers/NotificationHelper'
import { OrUndefTP } from 'common/framework-ui/types/OrUndefTP'
import { FileUtils } from 'common/framework-ui/utils/FileUtils'
import { MimeTypeUtils } from 'common/framework-ui/utils/MimeTypeUtils'
import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { IconUploadCloudCP } from 'common/framework-ui/components/icons/IconUploadCloudCP'

const MAX_FILE_SIZE_MB = 50

interface IFileUploadCPProps {

    listType?: UploadListType
    uploadFiles: (file: File) => void

    loading?: boolean
    multiple?: boolean
    fileTypes?: MimeTypeEnum[]
    loadingMsg?: string
    disabled?: boolean

    maxMbSize?: number
}

/**
 * Componente responsável por fazer o upload de arquivos para o sitema.
 * Utiliza handlers customizados para fazer este upload.
 */
export function FileUploadCP(props: IFileUploadCPProps): JSX.Element {

    const hasCsv = props.fileTypes?.some(type => MimeTypeUtils.isCsvMimeType(type))

    function validateFile(file: RcFile): boolean {

        // Valida se há algum arquivo
        if (!file) {
            NotificationHelper.error('Nenhum arquivo foi encontrado')
            return false
        }

        // Valida tipo do arquivo
        if (!!props.fileTypes?.length && !props.fileTypes.includes(file.type as MimeTypeEnum) && (!hasCsv || !FileUtils.isCsv(file, file.name))) {
            NotificationHelper.error(`Apenas ${props.fileTypes.join(', ')} são permitidos`)
            return false
        }

        const maxSize = props.maxMbSize ?? MAX_FILE_SIZE_MB
        // Verifica se arquivo possui no maximo 50mb
        if (file.size / 1024 / 1024 > maxSize) {
            NotificationHelper.error(`Somente é permitido arquivos menores que ${maxSize}MB`)
            return false
        }

        return true
    }

    function getAcceptedMimeTypes(): OrUndefTP<string> {
        const acceptedMimes = (props.fileTypes?.length && hasCsv) ? _.uniq([...props.fileTypes, ...MimeTypeUtils.getCsvMimeTypes()]) : props.fileTypes
        return acceptedMimes ? acceptedMimes.join(',') : undefined
    }

    return (
        <WrapperSCP>
            <Upload.Dragger
                accept={getAcceptedMimeTypes()}
                showUploadList={true}
                listType={props.listType}
                beforeUpload={validateFile}
                onChange={(info) => props.uploadFiles(info.fileList[0].originFileObj as File)}
                multiple={!!props.multiple}
                disabled={props.disabled}
            >
                <>
                    <IconUploadCloudCP size={25} color={ThemeAnt.primaryColor}/>

                    <span>
                        Clique ou arraste arquivos aqui para enviar ao sistema
                    </span>

                    {
                        props.fileTypes?.length &&
                        <span>{`Apenas arquivos ${props.fileTypes.join(', ')} são aceitos`}</span>
                    }
                </>
            </Upload.Dragger>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

    .ant-upload.ant-upload-drag {

        .ant-upload-drag-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            opacity: .95;
            transition: opacity .3s, color .3s;
        }
    }
`
