import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { AuthUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/utils/AuthUtils'
import { UserLoginRequestDTO } from 'submodules/neriteduca-sdk/services/auth/authenticate/dtos/requests/UserLoginRequestDTO'
import { NeritEducaSystemApiEnums } from 'submodules/neriteduca-sdk/services/common/NeritEducaSystemApiEnums'

export class AuthRequests {

    public static ROOT = 'auth'
    public static LOGIN_EP = 'login'
    public static RESET_PASSWORD_EP = 'reset-password'
    public static VALIDATE_SCHEMA_EP = 'validate-schema'

    static login = (schema: string, dto: UserLoginRequestDTO): RequestConfigTP => ({
        baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
        url: `${AuthRequests.ROOT}/${AuthRequests.LOGIN_EP}`,
        method: HttpMethodEnum.POST,
        params: dto,
        noAuth: true,
        headers: [AuthUtils.getSchemaHeaderConfig(schema)]
    })

    // @TODO
    static resetPassword = (schema: string, dto: unknown): RequestConfigTP => ({
        baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
        url: `${AuthRequests.ROOT}/${AuthRequests.RESET_PASSWORD_EP}`,
        method: HttpMethodEnum.PUT,
        params: dto,
        noAuth: true,
        headers: [AuthUtils.getSchemaHeaderConfig(schema)]
    })

    static validateSchema = (schema: string): RequestConfigTP => ({
        baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
        url: `${AuthRequests.ROOT}/${AuthRequests.VALIDATE_SCHEMA_EP}`,
        method: HttpMethodEnum.GET,
    })

}
