import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { FormModel } from 'common/framework-ui/form-state-manager/FormModel'
import { IFormStateManager } from 'common/framework-ui/form-state-manager/IFormStateManager'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { RadioOptionTP } from 'common/framework-ui/components/form-fields/radio-group/inner/RadioOptionTP'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'
import * as _ from 'lodash'
import { ConditionalRenderCP } from 'common/framework-ui/components/conditional-render/ConditionalRenderCP'
import { TooltipCP } from 'common/framework-ui/components/tooltip/TooltipCP'

type _AntPropsTP = {
    disabled?: boolean,
}

type _ScpPropsTP = {
    buttonWidth?: number,
    buttonHeight?: number,
    secondary?: boolean,
    paddingTop?: number,
    fontSize?: FontSizeTP,
    type: 'button' | 'vertical-radio',
}

type _CustomPropsTP<OptionTP, FModelTP extends FormModel> = {
    selected?: OptionTP,
    onChange?: (value: OptionTP) => void,
    fieldName?: keyof FModelTP,
    formStateManager?: IFormStateManager<FModelTP>,
    options: Array<RadioOptionTP<OptionTP>>,
    label?: string,
}

interface IRadioGroupCPProps<OptionTP = string, FModelTP extends FormModel = any>
    extends _AntPropsTP, _ScpPropsTP, _CustomPropsTP<OptionTP, FModelTP> { }

/**
 * COMPONENTE
 * Grupo de radio-butons (combo de selecao unica).
 */
export function RadioGroupCP<OptionTP = string, FModelTP extends FormModel = any>(props: IRadioGroupCPProps<OptionTP>): JSX.Element | null {

    const hasStateManager = (!!props.formStateManager && !!props.fieldName)
    const validationsCount = props.formStateManager?.validationsCount ?? 0

    const [validationErrMsg, setValidationErrMsg] = useState<string>()
    const [errorMessage, setErrorMessage] = useState<string>()

    useEffect(parseValidation, [validationsCount])
    useEffect(handleErrMsgUpdate, [validationErrMsg])

    function handleChange(event: RadioChangeEvent): void {

        const nextValue = event.target.value

        if (!!props.onChange)
            props.onChange(nextValue)

        else if (hasStateManager)
            props.formStateManager!.changeFieldValue(props.fieldName!, nextValue)
    }

    function handleErrMsgUpdate(): void {
        setErrorMessage(validationErrMsg)
    }

    function parseValidation(): void {

        if (!hasStateManager || props.formStateManager!.isValid)
            return setValidationErrMsg(undefined)

        const fieldErrors = props.formStateManager!.getFieldError(props.fieldName as keyof FModelTP)
        const constraints = _.get(fieldErrors, 'constraints')

        if (!!constraints) {
            const errMessages: any = Object.values(constraints) || []
            if (!!errMessages.length)
                return setValidationErrMsg(errMessages[0])
        }

        setValidationErrMsg(undefined)
    }

    return (
        <>
            {
                !!props.label &&
                <LabelSCP>{props.label}</LabelSCP>
            }

            <WrapperSCP>
                <Radio.Group
                    value={hasStateManager ? props.formStateManager?.getFieldValue(props.fieldName!) : props.selected}
                    disabled={props.disabled}
                    onChange={handleChange}
                >
                    {
                        props.options.map((opt, index) => (
                            <TooltipCP
                                text={opt.tooltip}
                                key={`opt-radio-group-${((opt.value ?? '') as string || index).toString()}`}
                                placement={props.type === 'vertical-radio' ? 'right' : undefined}
                            >
                                {
                                    props.type === 'button' &&
                                    <Radio.Button value={opt.value} disabled={opt.disabled}>
                                        {opt.content}
                                    </Radio.Button>
                                }
                                {
                                    props.type === 'vertical-radio' &&
                                    <Radio value={opt.value} disabled={opt.disabled}>
                                        {opt.content}
                                    </Radio>
                                }
                            </TooltipCP>
                        ))
                    }
                </Radio.Group>
                <ConditionalRenderCP shouldRender={!!errorMessage}>
                    <ErrorSCP>
                        { errorMessage }
                    </ErrorSCP>
                </ConditionalRenderCP>
            </WrapperSCP>
        </>
    )
}

const LabelSCP = styled.div`
  margin-top: 20px;
  color: ${props => props.theme.darkGray};
`

const WrapperSCP = styled.div`

  .ant-radio-wrapper {
    display: block;
  }
`

const ErrorSCP = styled.div`
  transition: opacity .3s;
  font-size: 12px;
  font-style: italic;
  margin: 2px 0;
  color: ${props => props.theme.errorColor};

  i {
    margin-left: 5px;
  }
`
