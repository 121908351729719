import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NeritEducaSystemApiEnums } from 'submodules/neriteduca-sdk/services/common/NeritEducaSystemApiEnums'
import { EadClassContentRequestDTO } from 'submodules/neriteduca-sdk/services/ead/ead-course/dtos/requests/EadClassContentRequestDTO'
import { EadClassStructureRequestDTO } from 'submodules/neriteduca-sdk/services/ead/ead-course/dtos/requests/EadClassStructureRequestDTO'

export class EadCourseRequests {

	public static CONTROLLER_ROOT = 'ead-courses'
	public static GET_COURSE_STRUCTURE_EP = 'structure'
	public static GET_CLASS_CONTENT_EP = 'class-content'

	static getCourseStructure = (dto: EadClassStructureRequestDTO): RequestConfigTP => ({
		baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
		url: `${EadCourseRequests.CONTROLLER_ROOT}/${EadCourseRequests.GET_COURSE_STRUCTURE_EP}`,
		method: HttpMethodEnum.GET,
		params: dto
	})

	static getClassContent = (dto: EadClassContentRequestDTO): RequestConfigTP => ({
		baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
		url: `${EadCourseRequests.CONTROLLER_ROOT}/${EadCourseRequests.GET_CLASS_CONTENT_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

}
