import { EnvironmentEnum } from 'common/framework-ui/enums/EnvironmentEnum'

/**
 * Classe de configuracao do sistema.
 */
export class SystemConfig {

    readonly defaultTimeZone = 'America/Sao_Paulo'
    readonly environment = process.env.REACT_APP_STAGE as EnvironmentEnum

    readonly reduxEncryptKey = 'bmVzbXRzZWNyZXRrZXk='

    readonly uiBaseUrl: string
    readonly apiBaseUrl: string
    readonly neritDocsBaseUrl: string
    readonly space4LeadsApiBaseUrl: string

    readonly anonymousUserToken: string

    private static _instance: SystemConfig

    private constructor() {
        switch (this.environment) {
            case EnvironmentEnum.DEV:
                this.uiBaseUrl = 'http://localhost:3000'
                this.apiBaseUrl = 'http://api.neriteduca.com.br:3001'
                this.neritDocsBaseUrl = 'http://localhost:3004'
                this.space4LeadsApiBaseUrl = 'http://api.space4leads.com'
                this.anonymousUserToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyQ29kZSI6MSwiaWF0IjoxNjE1NTEyMTI5fQ.pIKmo38fGEyiwuiM4v2L7yG3MK7oLWpsnA8eEvfJXGU'
                break

            case EnvironmentEnum.PROD:
                this.uiBaseUrl = 'https://central.trausedu.com.br'
                this.apiBaseUrl = 'https://neriteduca-api.traus.com.br'
                this.neritDocsBaseUrl = 'http://api.services.nerit.app/docs'
                this.space4LeadsApiBaseUrl = 'http://api.space4leads.com'
                this.anonymousUserToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyQ29kZSI6MSwiaWF0IjoxNjA2OTE3NTkyfQ.iLFIyoyHh0BP17h53cip13fwkfH7j4tv1iNKAJBUd34'
                break

            case EnvironmentEnum.HOM:
            case EnvironmentEnum.TEST:
            default:
                throw new Error('Ambiente não configurado!')
        }
    }

    static getInstance(): SystemConfig {
        if (!this._instance)
            this._instance = new SystemConfig()
        return this._instance
    }
}
