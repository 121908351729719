import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import React, { useEffect, useState } from 'react'
import { FlexCP } from 'common/framework-ui/components/flex/FlexCP'
import { ButtonCP } from 'common/framework-ui/components/button/ButtonCP'
import { TeacherDirectMessageFormModel } from 'modules/user/components/form-teacher-direct-message/inner/TeacherDirectMessageFormModel'
import { useFormStateManager } from 'common/framework-ui/form-state-manager/UseFormStateManager'
import { TextAreaCP } from 'common/framework-ui/components/form-fields/text-area/TextAreaCP'
import { FileUploadCP } from 'common/framework-ui/components/file-upload/FileUploadCP'
import { CardCP } from 'common/framework-ui/components/card/CardCP'
import { useRequest } from 'common/framework-ui/request-manager/use-request/UseRequest'
import { FormStateManagerUtils } from 'common/framework-ui/form-state-manager/FormStateManagerUtils'
import { TeacherIndividualMessageSendRequestDTO } from 'submodules/neriteduca-sdk/services/product/teacher/dtos/request/TeacherIndividualMessageSendRequestDTO'
import { TeacherRequests } from 'submodules/neriteduca-sdk/services/product/teacher/TeacherRequests'
import { RequestUtils } from 'common/framework-ui/request-manager/RequestUtils'
import { NotificationHelper } from 'common/framework-ui/helpers/NotificationHelper'
import { AutocompleteTeacherCP } from 'modules/people/components/autocomplete-teacher/AutocompleteTeacherCP'

interface ICPProps {
    productReleaseCode: number
}

/**
 * Enviar mensagem para o professor
 */
export function FormTeacherDirectMessageCP(props: ICPProps): JSX.Element {

    const [uploadedFile, setUploadedFile] = useState<File>()
    const [formValidator] = useState<TeacherDirectMessageFormModel>(new TeacherDirectMessageFormModel())
    const formStateManager = useFormStateManager<TeacherDirectMessageFormModel>(formValidator)

    const sendMessageRequest = useRequest<void>('none')
    useEffect(onSendMessageRequestChange, [sendMessageRequest.isAwaiting])

    /**
     * Salvar.
     */
    async function save(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!

        const dto: TeacherIndividualMessageSendRequestDTO = {
            teacherCode: formValues.teacherCode,
            productReleaseCode: props.productReleaseCode,
            message: formValues.message,
        }

        sendMessageRequest.runRequest(TeacherRequests.sendIndividualMessage(dto, uploadedFile))
    }

    /**
     * Retorno ao salvar.
     */
    function onSendMessageRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(sendMessageRequest, 'Erro ao enviar mensagem', true))
            return

        NotificationHelper.success('Pronto!', 'Mensagem enviada sucesso.')
    }

    return (
        <CardCP margin={'none'}>
            <AutocompleteTeacherCP
                label={'Selecione o Professor'}
                formStateManager={formStateManager}
                fieldName={'teacherCode'}
                required={true}
                productReleaseCode={props.productReleaseCode}
            />

            <TextAreaCP
                label={'Mensagem para o professor'}
                formStateManager={formStateManager}
                value={formStateManager.getFieldValue('message')}
                fieldName={'message'}
                minRows={5}
            />

            <UploadWrapperSCP>
                <FileUploadCP
                    listType={'text'}
                    uploadFiles={setUploadedFile}
                />
            </UploadWrapperSCP>

            <FlexCP justifyContent={'flex-end'} marginTop={30}>
                <ButtonCP
                    onClick={save}
                    loading={sendMessageRequest.isAwaiting}
                >
                    Enviar
                </ButtonCP>
            </FlexCP>
        </CardCP>
    )
}

const UploadWrapperSCP = styled.div`
    margin-top: 20px;
`
