import { NotificationHelper } from 'common/framework-ui/helpers/NotificationHelper'
import React, { useEffect } from 'react'

/**
 * Pagina para testar componentes de forma rapida
 *
 * Chamar na ROTA: /dev-test
 */
export function DevTestScreen(): JSX.Element {

    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {

        doNothing()
    }

    function doNothing(): void {
        NotificationHelper.warning('Do Nothing')
    }

    return (
        <>
        </>
    )
}
