import React from 'react'
import styled from 'styled-components'
import { TabsBigButtonCP } from 'common/framework-ui/components/tabs/TabsBigButtonCP'
import { UserCourseMainTabICP } from 'modules/user/components/tab-user-course-detail/inner/UserCourseMainTabICP'
import { CollapseEadCourseStructureCP } from 'modules/ead/components/collapse-ead-course-structure/CollapseEadCourseStructureCP'
import { UserCourseForumTabICP } from 'modules/user/components/tab-user-course-detail/inner/UserCourseForumTabICP'
import { useScreenSize } from 'common/framework-ui/responsiveness/use-screen-size/UseScreenSize'
import { FormTeacherDirectMessageCP } from 'modules/user/components/form-teacher-direct-message/FormTeacherDirectMessageCP'

interface ICPProps {
    accountCode: number
    productReleaseCode: number
}

/**
 * Card de um curso comprado pelo usuario.
 */
export function TabUserCourseDetailCP(props: ICPProps): JSX.Element {

    const screenSize = useScreenSize()

    return (
        <WrapperSCP>

            <TabsBigButtonCP
                tabs={[
                    {
                        key: 'main',
                        title: 'Apresentação',
                        content: <UserCourseMainTabICP productReleaseCode={props.productReleaseCode}/>
                    },
                    {
                        key: 'content',
                        title: 'Conteúdo',
                        content: <CollapseEadCourseStructureCP viewMode={screenSize.smd ? 'button' : 'full'} accountCode={props.accountCode} productReleaseCode={props.productReleaseCode}/>
                    },
                    {
                        key: 'direct-message',
                        title: 'Fale com o professor',
                        content: <FormTeacherDirectMessageCP productReleaseCode={props.productReleaseCode}/>,
                        hide: true,
                    },
                    {
                        key: 'forum',
                        title: 'Fórum',
                        content: <UserCourseForumTabICP productReleaseCode={props.productReleaseCode}/>,
                        hide: true,
                    },
                ]}
            />

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
