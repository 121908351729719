import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router'
import { LayoutCP } from 'common/framework-ui/components/screen-layout/layout/LayoutCP'
import 'video.js/dist/video-js.css'
import { ClassContentCP } from 'modules/ead/components/class-content/ClassContentCP'
import { EadCourseViewScreenUtils } from 'modules/ead/screens/ead-course-view/inner/EadCourseViewScreenUtils'
import { SiderEadCourseViewCP } from 'modules/ead/screens/ead-course-view/inner/SiderEadCourseViewCP'
import { useScreenSize } from 'common/framework-ui/responsiveness/use-screen-size/UseScreenSize'
import { CascaderEadCourseStructureCP } from 'modules/ead/components/cascader-ead-course-structure/CascaderEadCourseStructureCP'
import { FlexCP } from 'common/framework-ui/components/flex/FlexCP'
import { ButtonExitCourseView } from 'modules/ead/components/button-exit-course-view/ButtonExitCourseView'
import { FlexOneICP } from 'common/framework-ui/components/flex/inner/FlexOneICP'
import { FallbackScreenContentCP } from 'common/framework-ui/components/fallback-screen-content/FallbackScreenContent'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'

/**
 * Navegacao pelo curso EAD.
 */
export function ScreenEadCourseView(): JSX.Element {

    const screenSize = useScreenSize()

    const routeParams = useParams<{ accountCode: string, courseCode: string, contentCode: string }>()
    const [classContentCode, setClassContentCode] = useState<number>(+routeParams.contentCode)

    useEffect(() => EadCourseViewScreenUtils.showOrHideMainMenu('hide'), [])
    useEffect(init, [routeParams])

    /**
     * Inicializa
     */
    function init(): void {
        setClassContentCode(+routeParams.contentCode)
    }

    if (!routeParams.accountCode || !routeParams.courseCode || !routeParams.contentCode)
        return <FallbackScreenContentCP status={'error'} title={'Conta, curso ou conteúdo inválidos'}/>

    return (
        <ContentWrapperSCP>
            <LayoutCP
                mainLayout={true}
                sider={
                    !screenSize.smd &&
                    <SiderEadCourseViewCP
                        accountCode={+routeParams.accountCode}
                        productReleaseCode={+routeParams.courseCode}
                    />
                }
                content={
                    <>
                        {
                            screenSize.smd &&
                            <FlexCP justifyContent={'space-between'} alignItems={'center'}>
                                <FlexOneICP>
                                    <CascaderEadCourseStructureCP
                                        accountCode={+routeParams.accountCode}
                                        productReleaseCode={+routeParams.courseCode}
                                        classContentCode={+routeParams.contentCode}
                                    />
                                </FlexOneICP>
                                <ButtonExitCourseView
                                    mode={'icon'}
                                    productReleaseCode={+routeParams.courseCode}
                                />
                            </FlexCP>
                        }

                        <ContentCP overflowVertical={true}>
                            <ClassContentCP
                                accounCode={+routeParams.accountCode}
                                productReleaseCode={+routeParams.courseCode}
                                classContentCode={classContentCode}
                            />
                        </ContentCP>
                    </>
                }
            />
        </ContentWrapperSCP>
    )
}

const ContentWrapperSCP = styled.div`

  .ant-layout-content {
    background: black;
  }

`
