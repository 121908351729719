import React from 'react'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { AppStateUtils } from 'common/framework-ui/utils/AppStateUtils'
import { PrivateRouter } from 'app/routes/PrivateRouter'

interface ICPProps {
    children: JSX.Element
    isAdmin: boolean
    redirect?: {
        shouldRedirect: boolean
        redirectTo?: string
    }
}

/**
 * Controle de Acesso.
 */
export function AccessControl(props: ICPProps): JSX.Element {

    if (props.isAdmin && !AppStateUtils.getLoggedUserData()!.user.isAdmin === props.isAdmin) {

        if (props.redirect?.shouldRedirect) {
            NotificationHelper.warning('Ops!', 'Você não tem permissão para acessar essa página')
            NeritFrameworkRoutingHelper.historyPush(props.redirect.redirectTo ?? PrivateRouter.USER_COURSES_ROOT)
            return <></>
        }

        return <></>
    }

    return props.children
}
