import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { UserMainLayoutCP } from 'modules/user/components/user-main-layout/UserMainLayoutCP'
import React from 'react'
import { PublicRouter } from 'app/routes/PublicRouter'
import { AppStateUtils } from 'common/framework-ui/utils/AppStateUtils'

/**
 */
export function PrivateAccessVerifierCP(): JSX.Element {

    const loggedUserData = AppStateUtils.getLoggedUser()
    if (!loggedUserData) {
        NeritFrameworkRoutingHelper.historyPush(PublicRouter.USER_LOGIN)
        return <></>
    }

    // Se estiver logado, inclui a tela do sistema e nela tera o roteador das rotas
    return <UserMainLayoutCP/>
}
