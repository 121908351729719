import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NeritEducaSystemApiEnums } from 'submodules/neriteduca-sdk/services/common/NeritEducaSystemApiEnums'
import { GenerateCertificateRequestDTO } from 'submodules/neriteduca-sdk/services/sales/invoice-account/dtos/request/GenerateCertificateRequestDTO'


export class InvoiceAccountsRequests {

	public static CONTROLLER_NAME = 'invoice-accounts'
	public static GET_USER_PURCHASES_EP = ''
	public static USER_CERTIFICATES_EP = 'user-certificates'
	public static GENERATE_CERTIFICATE = 'generate-cerficate'
	public static ADD_GUESTS_EP = 'add-guests'

	static getUserPurchases = (): RequestConfigTP => ({
		baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
		url: `${InvoiceAccountsRequests.CONTROLLER_NAME}/${InvoiceAccountsRequests.GET_USER_PURCHASES_EP}`,
		method: HttpMethodEnum.GET,
	})

	static getUserCertificates = (): RequestConfigTP => ({
		baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
		url: `${InvoiceAccountsRequests.CONTROLLER_NAME}/${InvoiceAccountsRequests.USER_CERTIFICATES_EP}`,
		method: HttpMethodEnum.GET,
	})

	static generateCertificate = (dto: GenerateCertificateRequestDTO): RequestConfigTP => ({
		baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
		url: `${InvoiceAccountsRequests.CONTROLLER_NAME}/${InvoiceAccountsRequests.GENERATE_CERTIFICATE}`,
		method: HttpMethodEnum.GET,
        params: dto
	})

    static addGuests = (formData: any): RequestConfigTP<void> => {

        return {
            baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
            url: `${InvoiceAccountsRequests.CONTROLLER_NAME}/${InvoiceAccountsRequests.ADD_GUESTS_EP}`,
            method: HttpMethodEnum.POST,
            params: formData,
            avoidParamTransformer: true,
            headers: [{
                headerName: 'Content-Type',
                headerValue: 'multipart/form-data',
            }]
        }
    }
}
