import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { Space4LeadsSystemApiEnum } from 'submodules/space4leads-sdk/common/Space4LeadsSystemApiEnum'
import { PersonSaveRequestDTO } from 'submodules/space4leads-sdk/services/people/person/dtos/request/PersonSaveRequestDTO'

export class PeopleRequests {

    static CONTROLLER_ROOT = 'people'
    static CREATE_OR_UPDATE_EP = 'create-or-update'
    static UPDATE_EP = 'update/:code'

    static createOrUpdate = (dto: PersonSaveRequestDTO, projectCode?: number): RequestConfigTP => ({
        baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
        url: `${PeopleRequests.CONTROLLER_ROOT}/${PeopleRequests.CREATE_OR_UPDATE_EP}`,
        method: HttpMethodEnum.POST,
        params: dto,
        headers: !projectCode ? undefined : [{ headerName: 'on-behalf-of', headerValue: projectCode.toString() }]
    })

    static update = (code: number, dto: PersonSaveRequestDTO): RequestConfigTP => ({
        baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
        url: `${PeopleRequests.CONTROLLER_ROOT}/${PeopleRequests.UPDATE_EP.replace(':code', code.toString())}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

}
