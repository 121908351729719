import React, { useEffect } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

// const videoYoutube = require('videojs-youtube')
require('videojs-youtube')

interface ICPProps {
    options: videojs.PlayerOptions
}

/**
 * Componente para exibir conteudo do tipo video.
 */
export function VideoCP(props: ICPProps): JSX.Element {

    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);

    useEffect(init, [props.options, videoRef])
    useEffect(terminate, [playerRef])

    function init(): void {

        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;

            if (!videoElement) return;

            (playerRef.current as any) = videojs(videoElement, props.options, () => {
                videojs.log('player is ready');
            });

            return
        }

        const player = playerRef.current;
        (player as any).src(props.options.sources);
    }

    /**
     * Dispose the Video.js player when the functional component unmounts
     */
    function terminate(): () => void {

        const player = playerRef.current;

        return () => {
            if (!!player) {
                (player as any).dispose()

                if (!!playerRef)
                    playerRef.current = null
            }
        }
    }

    return (
        <div>
            <video
                autoPlay={false}
                ref={videoRef}
                className={'video-js vjs-big-play-centered'}
            />
        </div>
    )
}
