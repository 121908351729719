import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NeritEducaSystemApiEnums } from 'submodules/neriteduca-sdk/services/common/NeritEducaSystemApiEnums'
import { SystemConfig } from 'config/SystemConfig'
import { EnvironmentEnum } from 'common/framework-ui/enums/EnvironmentEnum'
import { Space4LeadsSystemApiEnum } from 'submodules/space4leads-sdk/common/Space4LeadsSystemApiEnum'
import { AppStateUtils } from 'common/framework-ui/utils/AppStateUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { AuthUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/utils/AuthUtils'
import { NeritDocsApiEnums } from 'submodules/nerit-micro-services-sdk/common/NeritDocsApiEnums'

/**
 * Class especifica do projeto com informacoes necessarios para o projeto.
 */
export class NeritFrameworkProjectConfig {

    static PROJECT_NAME = 'NeritEduca - Minha Central'
    static APP_VERSION = '0.1'

    static isHom(): boolean {
        return SystemConfig.getInstance().environment === EnvironmentEnum.DEV
    }

    static isDev(): boolean {
        return SystemConfig.getInstance().environment === EnvironmentEnum.DEV
    }

    static formatApiBaseUrl(config: RequestConfigTP): string {

        let baseurl = config.url
        if (!config.headers)
            config.headers = []

        if (config.baseUrlType === NeritEducaSystemApiEnums.NERIT_EDUCA_API)
            baseurl = `${SystemConfig.getInstance().apiBaseUrl}/${config.url}`

        else if (config.baseUrlType === Space4LeadsSystemApiEnum.SPACE4LEADS_API) {
            baseurl = `${SystemConfig.getInstance().space4LeadsApiBaseUrl}/${config.url}`

            config.headers.push({
                headerName: 'on-behalf-of',
                headerValue: '100'
            })

        } else if (config.baseUrlType === NeritDocsApiEnums.NERIT_DOCS_API) {
            // API do NeritDocs

            if (!AppStateUtils.getLoggedUserData()?.integrationKeys?.neritDocs?.apiToken) {
                NotificationHelper.error('Ops!', 'O token do NeritDocs não foi definido, entre em contato com o suporte')
                return 'token_nerit_docs_nao_definido'
            }

            baseurl = `${SystemConfig.getInstance().neritDocsBaseUrl}/${config.url}`
            config.headers.push(AuthUtils.getBearerAuthHeaderConfig(AppStateUtils.getLoggedUserData()!.integrationKeys!.neritDocs!.apiToken))
        }

        return baseurl
    }

    static notifyApi(type: 'success' | 'error', title: string, msg: string): void {

        if (type === 'error')
            console.error(title, msg)
        else
            console.info(title, msg)

    }

    static onUnauthorized(): void {
        console.log('onUnauthorized')
    }

}
