const ThemeAnt = {

    /**
     * Do NeritFramework
     */

    primaryMenuColor: '#010102',
    secondaryMenuColor: '#000',

    statusColor: {
        awaitingBg: '#FFDE9F',
        inProgressBg: '#747474',
        doneBg: '#A9E0DC',
        awaiting: '#CBA355',
        inProgress: '#f1f1f1',
        done: '#3C9AA2'
    },

    spaces: ['.2rem', '.5rem', '.8rem', '1rem', '1.2rem', '1.5rem', '2rem', '2.4rem', '2.7rem', '3rem', '3.4rem', '3.5rem', '4rem'],

    antPrimaryColor: '#010102',

    // Propriedades do tema padrao do ant.d
    primaryColor: '#144C90',
    secondaryColor: '#EE5723',

    // Propriedades customizadas
    tertiaryColor: '#ffffff',
    secondMenuBg: '#e2d6e8',
    gray: '#c5c5c5',
    darkGray: '#898989',

    fontSizes: {
        extraSmall: '.6rem',
        small: '.7rem',
        normal: '.85rem',
        large: '1rem',
        extraLarge: '1.3rem',
        title: '2.5rem',
    },

    // Propriedades do tema padrao do ant.d
    infoColor: '#1aa2c4',
    successColor: '#27ae60',
    processingColor: '#1aa2c4',
    errorColor: '#c41a1a',
    highlightColor: '#c41a1a',
    warningColor: '#c4ab1a',
    normalColor: '#d9d9d9',
    white: '#ffffff',
    black: '#010102',

    fontFamily: 'Montserrat, sans-serif',
    borderColorBase: '#010102',
    inputBg: 'transparent',
    inputHoverBorderColor: '#010102',
    btnPrimaryBg: '#6d1c99',
    primary6: '#010102',
    linkColor: '#010102',

    tooltipBg: '#6d1c99',
    menuBg: '#ffffff',
    layoutBodyBackground: '#F1F1F1',
    layoutHeaderBackground: '#ffffff',
    layoutFooterBackground: '@layout-body-background',
    layoutHeaderHeight: '72px',
    layoutSiderBackground: '@layout-header-background',
}

module.exports = { ThemeAnt }
