import { EadContentTypeEnum } from 'submodules/neriteduca-sdk/services/ead/ead-course/enums/EadContentTypeEnum'

export const IconByContentUtils = {

    getIconName(contentType: EadContentTypeEnum): string {

        let iconName = ''
        switch (contentType) {

            case EadContentTypeEnum.PDF:
                iconName = 'file-pdf'
                break

            case EadContentTypeEnum.HTML:
                iconName = 'file-text'
                break

            case EadContentTypeEnum.TEST:
                iconName = 'form'
                break

            case EadContentTypeEnum.VIDEO:
            case EadContentTypeEnum.YOUTUBE:
                iconName = 'video-camera'
                break

            case EadContentTypeEnum.IMAGE:
                iconName = 'file-image'
                break

            default:
                break
        }

        return iconName
    }

}
