import React, { useState } from 'react'
import { BulkOperationTableUtils } from 'submodules/nerit-micro-services-ui/common/components/table-bulk-operations/inner/BulkOperationTableUtils'
import { BulkRequests } from 'submodules/nerit-micro-services-sdk/services/bulk/BulkRequests'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { IBulkOperationCustomArgs } from 'submodules/nerit-micro-services-sdk/services/bulk/interfaces/IBulkOperationCustomArgs'
import { BulkOperationResponseDTO } from 'submodules/nerit-micro-services-sdk/services/bulk/dtos/responses/BulkOperationResponseDTO'
import { ModalBulkOperationsLog } from 'submodules/nerit-micro-services-ui/common/components/modal-bulk-operations-log/ModalBulkOperationsLog'

interface ICPProps {
    reloadList: number
}

/**
 * Componente de tabela que contas bancarias
 */
export function TableBulkOperations(props: ICPProps): JSX.Element {

    const [isLogModalVisible, setIsLogModalVisible] = useState<boolean>(false)
    const [selectedBulkOperation, setSelectedBulkOperation] = useState<BulkOperationResponseDTO<IBulkOperationCustomArgs>>()

    /**
     * Ao clicar para mostrar logs.
     */
    function showLogs(record: BulkOperationResponseDTO<IBulkOperationCustomArgs>): void {

        setIsLogModalVisible(true)
        setSelectedBulkOperation(record)
    }

    return (
        <>
            <TableFromApiCP
                appearance={{
                    showReloadButton: true
                }}
                shouldLoadData={props.reloadList}
                apiConfig={{
                    requestConfigTP: BulkRequests.search
                }}
                columns={BulkOperationTableUtils.getTableColumns(
                    showLogs
                )}
            />

            <ModalBulkOperationsLog
                visible={isLogModalVisible}
                onClose={() => setIsLogModalVisible(false)}
                bulkOperation={selectedBulkOperation}
            />
        </>
    )
}
