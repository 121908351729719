import React, { useEffect, useState } from 'react'
import { useRequest } from 'common/framework-ui/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'common/framework-ui/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'common/framework-ui/request-manager/RequestUtils'
import { CollapseCP } from 'common/framework-ui/components/collapse/CollapseCP'
import { TitleCP } from 'common/framework-ui/components/title/TitleCP'
import { ListCP } from 'common/framework-ui/components/list/ListCP'
import { ListItemICP } from 'common/framework-ui/components/list/inner/ListItemICP'
import { FlexCP } from 'common/framework-ui/components/flex/FlexCP'
import { ButtonCP } from 'common/framework-ui/components/button/ButtonCP'
import { ForumItemResponseDTO } from 'submodules/neriteduca-sdk/services/ead/forum/dtos/response/ForumItemResponseDTO'
import { ForumRequests } from 'submodules/neriteduca-sdk/services/ead/forum/ForumRequests'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

interface ICPProps {
    onSelectTopic: (topicCode: number) => void
    productReleaseCode: number
}

/**
 * Collapse de Forums.
 */
export function CollapseForumsCP(props: ICPProps): JSX.Element {

    const [forums, setForums] = useState<ForumItemResponseDTO[]>([])
    const getForumsRequest = useRequest<IGenericListResponseDTO<ForumItemResponseDTO>>()
    useEffect(onGetForumsRequestChange, [getForumsRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa
     */
    function init(): void {
        getForumsRequest.runRequest(ForumRequests.getForums({ releaseCode: props.productReleaseCode }))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetForumsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getForumsRequest, 'Erro obter forums'))
            return

        setForums(getForumsRequest.responseData!.list)
    }

    return (
        <CollapseCP
            bordered={true}
            bgColor={'#fff'}
            loading={getForumsRequest.isAwaiting}
            defaultPanelKeys={!ArrayUtils.isEmpty(forums) ? [`module-${forums[0].code}`] : undefined}
            panels={
                forums.map((forum) => ({
                    key: `module-${forum.code}`,
                    title: forum.name,
                    content:
                        (
                            <>
                                <TitleCP underLine={true} textSize={'normal'}>
                                    Tópicos
                                </TitleCP>
                                <ListCP
                                    dataSource={forum.topics}
                                    renderItem={(topic) => (
                                        <ListItemICP
                                            title={
                                                <FlexCP justifyContent={'space-between'} alignItems={'center'}>
                                                    <>{topic.name}</>
                                                    <ButtonCP
                                                        onClick={() => props.onSelectTopic(topic.code)}
                                                    >
                                                        Ver postagens
                                                    </ButtonCP>
                                                </FlexCP>
                                            }
                                        />
                                    )}
                                />
                            </>
                        )
                }))
            }
        />
    )
}
