import { InputCP } from 'common/framework-ui/components/form-fields/input/InputCP'
import { ColumnCP } from 'common/framework-ui/components/grid/ColumnCP'
import { RowCP } from 'common/framework-ui/components/grid/RowCP'
import { useFormStateManager } from 'common/framework-ui/form-state-manager/UseFormStateManager'
import { NotificationHelper } from 'common/framework-ui/helpers/NotificationHelper'
import { RequestUtils } from 'common/framework-ui/request-manager/RequestUtils'
import { useRequest } from 'common/framework-ui/request-manager/use-request/UseRequest'
import { AppStateUtils } from 'common/framework-ui/utils/AppStateUtils'
import { AddressPanelCP } from 'modules/people/components/address-panel/AddressPanelCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TitleCP } from 'common/framework-ui/components/title/TitleCP'
import { DateFormatEnum } from 'common/framework-ui/enums/DateFormatEnum'
import { DateUtils } from 'common/framework-ui/utils/date/DateUtils'
import { ButtonCP } from 'common/framework-ui/components/button/ButtonCP'
import { CardCP } from 'common/framework-ui/components/card/CardCP'
import { ProfileFormModel } from 'modules/people/components/logged-user-data-card/inner/ProfileFormModel'
import { InputMaskTypeEnum } from 'common/framework-ui/enums/InputMaskTypeEnum'
import { PeopleResponseDTO } from 'submodules/neriteduca-sdk/services/people/dtos/responses/PeopleResponseDTO'
import { PeopleRequests } from 'submodules/neriteduca-sdk/services/people/PeopleRequests'
import { PeopleSaveRequestDTO } from 'submodules/neriteduca-sdk/services/people/dtos/requests/PeopleSaveRequestDTO'
import { AlertCP } from 'common/framework-ui/components/alert/AlertCP'

/**
 * Card com detalhes do usuario logado e drawer para edicao dos dados.
 */
export function LoggedUserDataCardCP(): JSX.Element {

    const [formValidator, setFormValidator] = useState<ProfileFormModel>(new ProfileFormModel({}))
    const formStateManager = useFormStateManager<ProfileFormModel>(formValidator)

    const [loadedPerson, setLoadedPerson] = useState<PeopleResponseDTO>()
    const savePersonRequest = useRequest<PeopleResponseDTO>()
    useEffect(onSavePersonRequestChange, [savePersonRequest.isAwaiting])

    const getPersonRequest = useRequest<PeopleResponseDTO>()
    useEffect(onGetPersonRequestChange, [getPersonRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa Tela.
     */
    function init(): void {
        getPersonRequest.runRequest(PeopleRequests.getOne(AppStateUtils.getLoggedUserData()!.user.code))
    }

    /**
     * Retorno ao buscar pessoa.
     */
    function onGetPersonRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getPersonRequest, 'Erro ao buscar dados da pessoa logada'))
            return

        const resultPerson = getPersonRequest.responseData!
        setLoadedPerson(resultPerson)

        const profileModel = new ProfileFormModel({
            phone: resultPerson.cellphone,
            birthDate: !!resultPerson.birthday ? DateUtils.transformDateStrFormat(resultPerson.birthday, DateFormatEnum.US_WITHOUT_TIME, DateFormatEnum.BR_WITHOUT_TIME) : undefined,
            zipCode: resultPerson.address?.zipcode,
            street: resultPerson.address?.street,
            number: resultPerson.address?.number,
            complement: resultPerson.address?.complement,
            neighborhood: resultPerson.address?.neighborhood,
            city: resultPerson.address?.city,
            state: resultPerson.address?.state,
        })
        setFormValidator(new ProfileFormModel(profileModel))
    }

    /**
     * Salva edição dos dados da pessoa
     */
    async function savePerson(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        const formValues = formStateManager.getFormValues()
        if (!await formStateManager.validate() || !formValues)
            return NotificationHelper.error('Por favor preencha os campos corretamente')

        const dto: PeopleSaveRequestDTO = {
            cellphone: formValues.phone,
            birthday: formValues.birthDate ? DateUtils.transformDateStrFormat(formValues.birthDate, DateFormatEnum.BR_WITHOUT_TIME, DateFormatEnum.US_WITHOUT_TIME) : undefined,
            address: {
                street: formValues.street,
                neighborhood: formValues.neighborhood,
                complement: formValues.complement,
                number: formValues.number,
                zipcode: formValues.zipCode,
                city: formValues.city,
                state: formValues.state
            }
        }

        savePersonRequest.runRequest(PeopleRequests.update(AppStateUtils.getLoggedUserData()!.user.code, dto))
    }

    /**
     * Retorno ao salvar pessoa.
     */
    function onSavePersonRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(savePersonRequest, 'Erro ao salvar dados.'))
            return

        NotificationHelper.success('Pronto!', 'Seus dados foram atualizados com sucesso')
        init()
    }

    return (
        <ContentWrapperSCP>
            <CardCP
                isLoading={getPersonRequest.isAwaiting}
            >

                <RowCP spaceBetweenCols={16}>
                    <ColumnCP size={24}>
                        <InputCP
                            value={loadedPerson?.name}
                            label={'Nome '}
                            required={true}
                            disabled={true}
                        />
                    </ColumnCP>
                </RowCP>

                <RowCP spaceBetweenCols={16}>
                    <ColumnCP size={24}>
                        <InputCP
                            value={loadedPerson?.email}
                            label={'E-mail'}
                            required={true}
                            formStateManager={formStateManager}
                            disabled={true}
                        />
                    </ColumnCP>
                </RowCP>

                <RowCP spaceBetweenCols={8}>
                    <ColumnCP size={12}>
                        <InputCP
                            label={'CPF'}
                            value={loadedPerson?.cpf}
                            required={true}
                            mask={InputMaskTypeEnum.CPF}
                            disabled={true}
                        />
                    </ColumnCP>
                    <ColumnCP size={12}>
                        <InputCP<ProfileFormModel>
                            label={'Celular'}
                            fieldName={'phone'}
                            required={true}
                            formStateManager={formStateManager}
                            mask={InputMaskTypeEnum.PHONE}

                        />
                    </ColumnCP>
                </RowCP>

                <RowCP spaceBetweenCols={16}>
                    <ColumnCP size={12}>
                        <InputCP<ProfileFormModel>
                            label={'Data Nascimento'}
                            fieldName={'birthDate'}
                            formStateManager={formStateManager}
                            mask={InputMaskTypeEnum.DATE}
                        />
                    </ColumnCP>
                </RowCP>

                <AddressWrapperSCP>
                    <TitleCP
                        textSize={'small'}
                        underLine={true}
                    >
                        Endereço
                    </TitleCP>

                    <AddressPanelCP formStateManager={formStateManager}/>
                </AddressWrapperSCP>

                <AlertCP
                    marginTop={30}
                    message={'Os campos desabilitados não podem ser editados por questões de segurança. Entre em contato com a empresa para altera-los.'}
                    type={'info'}
                />
                <BtnContainerSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={savePerson}
                        loading={savePersonRequest.isAwaiting}
                        disabled={AppStateUtils.getLoggedUserData()!.user.code === 1}
                    >
                        Salvar
                    </ButtonCP>
                </BtnContainerSCP>
            </CardCP>

        </ContentWrapperSCP>

    )
}

const ContentWrapperSCP = styled.div`

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 22px;
    .ant-input {
        &:hover {
            border-color: ${props => props.theme.primaryColor};
        }
    
        &:active {
            border-color: ${props => props.theme.primaryColor};
        }
    
        &:focus {
            border-color: ${props => props.theme.primaryColor};
        }
    }
  
`
const BtnContainerSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    margin-bottom: 7px;
`
const AddressWrapperSCP = styled.div`
    margin-top: 30px;

    h3 {
        margin: 0 0 25px 7px;
    }
`
