import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AlertImportICP } from 'submodules/nerit-micro-services-ui/import/components/modal-import/inner/AlertImportICP'
import { UploadStateTP } from 'submodules/nerit-micro-services-ui/import/components/modal-import/inner/UploadStateTP'
import { PersonImportValidationErrorsTP } from 'submodules/nerit-micro-services-ui/import/components/modal-import/inner/PersonImportValidationErrorsTP'
import { BasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { UploadImportCP } from 'submodules/nerit-micro-services-ui/import/components/upload-import/UploadImportCP'
import { PersonImportValidationErrorsModalICP } from 'submodules/nerit-micro-services-ui/import/components/modal-import/inner/PersonImportValidationErrorsModalICP'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'

interface ICPProps extends BasicModalCPProps {
    children: React.ReactNode
    requestConfigTP: (file: File) => RequestConfigTP | undefined
}

/**
 * Modal para importacao de arquivo com lista de pessoas.
 *
 * @TODO Levar isso para dentro do NP, nao deve ficar generico
 */
export function ModalImportCP(props: ICPProps): JSX.Element {

    const [uploadState, setUploadState] = useState<UploadStateTP>()
    const [validationErrors, setValidationErrors] = useState<PersonImportValidationErrorsTP>()
    const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false)

    useEffect(init, [props.visible])

    /**
     * Inicializa
     */
    function init(): void {

        /**
         * Resetar estado da ultima importacao todas vez que o modal for reaberto.
         * Exceto se tiver ocorrido falha de validacao da ultima tentativa. Neste caso
         * preservamos a notificacao para o usuario poder consultar novamente, se quiser.
         */
        if (props.visible && uploadState !== 'failure')
            setUploadState(undefined)
    }

    /**
     * Ao mudar estado do upload
     */
    function onUploadStateChange(nextState: UploadStateTP, errors?: PersonImportValidationErrorsTP): void {
        setUploadState(nextState)
        if (nextState === 'failure')
            setValidationErrors(errors)
    }

    /**
     */
    function onClose(): void {
        if (uploadState === 'success')
            props.onSave()
    }

    return (
        <ModalCP
            visible={props.visible}
            title={'Importar'}
            onClose={onClose}
            onCancel={props.onCancel}
            noFooter={true}
            width={630}
            closable={uploadState !== 'ongoing'}
            top={45}
        >

            { props.children }

            <UploadImportCP
                canDoUpload={uploadState !== 'ongoing'}
                requestConfigTP={props.requestConfigTP}
                stateCallbacks={{
                    onError: () => onUploadStateChange('error'),
                    onValidationFailure: (errors) => onUploadStateChange('failure', errors),
                    onValidationSuccess: () => onUploadStateChange('success'),
                    onUploadStart: () => onUploadStateChange('ongoing'),
                }}
            />

            {
                (!!uploadState && uploadState !== 'ongoing') &&
                <AlertContainerSCP>
                    <AlertImportICP
                        uploadState={uploadState}
                        onCheckErrorsClick={() => setShowErrorsModal(true)}
                    />
                </AlertContainerSCP>
            }

            <PersonImportValidationErrorsModalICP
                visible={showErrorsModal}
                errors={validationErrors ?? {}}
                onClose={() => setShowErrorsModal(false)}
            />
        </ModalCP>
    )
}

const AlertContainerSCP = styled.div`
    margin-top: 10px;
`
