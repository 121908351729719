import React from 'react'
import { ColumnProps } from 'antd/es/table'
import { BulkOperationResponseDTO } from 'submodules/nerit-micro-services-sdk/services/bulk/dtos/responses/BulkOperationResponseDTO'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { BulkOperationTableColumnTotalICP } from 'submodules/nerit-micro-services-ui/common/components/table-bulk-operations/inner/BulkOperationTableColumnTotalICP'
import { TagBulkOperationStatusCP } from 'submodules/nerit-micro-services-ui/common/components/tag-bulk-operation-status/TagBulkOperationStatusCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { IBulkOperationCustomArgs } from 'submodules/nerit-micro-services-sdk/services/bulk/interfaces/IBulkOperationCustomArgs'

export const BulkOperationTableUtils = {

    getTableColumns(
        onShowLogs: (record: BulkOperationResponseDTO<IBulkOperationCustomArgs>) => void
    ): Array<ColumnProps<BulkOperationResponseDTO<IBulkOperationCustomArgs>>> {

        return [
            {
                title: 'Data',
                dataIndex: 'date',
                key: 'date',
                render: (text, record) => (
                    <TextCP size={'small'} text={DateUtils.formatDate(record.creationDate, DateFormatEnum.BR_WITH_TIME_H_M)}/>
                )
            },
            {
                title: 'Responsável',
                dataIndex: 'person',
                key: 'person',
                render: (text, record) => <TextCP size={'small'} text={record.customArgs?.userCreator.name}/>
            },
            {
                title: 'Status',
                colSpan: 2,
                dataIndex: 'total',
                key: 'total',
                align: 'center',
                render: (text, record) => <BulkOperationTableColumnTotalICP log={record}/>
            },
            {
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => <TagBulkOperationStatusCP status={record.status}/>
            },
            { dataIndex: 'act',
                key: 'act',
                render: (text, record) => (
                    <FlexCP justifyContent={'flex-end'}>
                        <ButtonCP
                            tooltip={'Ver log de erros'}
                            icon={'file-search'}
                            onClick={() => onShowLogs(record)}
                            size={'small'}
                            type={'ghost'}
                        />
                    </FlexCP>
                ) },
        ]
    }

}
