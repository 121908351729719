import React from 'react'
import { ColumnProps } from 'antd/es/table'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { PersonImportValidationErrorsItemTP } from 'submodules/nerit-micro-services-ui/import/components/table-import-validation-errors/inner/PersonImportValidationErrorsItemTP'
import { PersonImportValidationErrorDescColumnICP } from 'submodules/nerit-micro-services-ui/import/components/table-import-validation-errors/inner/PersonImportValidationErrorDescColumnICP'

/**
 * Configuracao para exibir tabela de descricao de falhas de validacao em
 * arquivo .csv para importacao de pessoas.
 */
export const PERSON_IMPORT_VALIDATION_ERRORS_COLUMNS: Array<ColumnProps<PersonImportValidationErrorsItemTP>> = [
    {
        title: 'Linha',
        dataIndex: 'line',
        key: 'line',
        align: 'center',
        render: (text, lineError: PersonImportValidationErrorsItemTP) => (
            <TextCP size={'small'} strong={true} text={`nº ${lineError.line}`}/>
        ),
    },
    {
        title: 'Falhas',
        dataIndex: 'line',
        align: 'center',
        key: 'line',
        render: (text, lineErrors: PersonImportValidationErrorsItemTP) => (
            <PersonImportValidationErrorDescColumnICP lineErrors={lineErrors}/>
        ),
    },
]
