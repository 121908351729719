import { Layout } from 'antd'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import React from 'react'
import styled from 'styled-components'

type _ScpPropsTP = {
    isMainSider?: boolean,
    bgColor?: string,
    width?: number | string,
}

type _AntPropsTP = {
    id?: string
    collapsedWidth?: number | string,
    breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl',
    zeroWidthTriggerStyle?: React.CSSProperties,
    onCollapse?: () => void,
    trigger?: JSX.Element | null,
    collapsed?: boolean,
    collapsible?: boolean,
}

interface ISiderCPProps extends React.PropsWithChildren<_ScpPropsTP & _AntPropsTP> { }

/**
 * Barra lateral esquerda generica para compor layouts.
 */
export function SiderCP(props: ISiderCPProps): JSX.Element {

    return (
        <WrapperSCP
            id={props.id}
            isMainSider={props.isMainSider}
            width={props.width}
            bgColor={props.bgColor}
        >
            <Layout.Sider
                collapsedWidth={props.collapsedWidth}
                breakpoint={props.breakpoint}
                zeroWidthTriggerStyle={props.zeroWidthTriggerStyle}
                onCollapse={props.onCollapse}
                trigger={props.trigger}
                collapsed={props.collapsed}
                collapsible={props.collapsible}
                width={'100%'}
            >
                {props.children}
            </Layout.Sider>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<_ScpPropsTP>`

    height: ${props => (!!props.isMainSider ? '100vh' : '100%')};
    width: ${props => ((typeof props.width === 'string') ? props.width : (!!props.width ? `${props.width}px` : 'unset'))};

    .ant-layout-sider {

        position: static;
        height: 100%;
        background: ${props => (props.bgColor ? props.bgColor : ThemeAnt.menuBg)};
        .ant-layout-sider-children {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
`
