import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { AuthUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/utils/AuthUtils'
import { NeritEducaSystemApiEnums } from 'submodules/neriteduca-sdk/services/common/NeritEducaSystemApiEnums'

export class SysAdminRequests {

    public static CONTROLLER_ROOT = 'sys-admin'
    public static VALIDATE_SCHEMA_EP = 'validate-schema'

    static validateSchema = (schema: string): RequestConfigTP => ({
        baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
        url: `${SysAdminRequests.CONTROLLER_ROOT}/${SysAdminRequests.VALIDATE_SCHEMA_EP}`,
        method: HttpMethodEnum.GET,
        headers: [AuthUtils.getSchemaHeaderConfig(schema)]
    })

}
