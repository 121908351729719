import React, { useEffect, useState } from 'react'
import { Icon } from 'antd'
import { ButtonsSurveyScoreCP } from 'submodules/space4leads-components-ui/survey/components/buttons-survey-score/ButtonsSurveyScoreCP'
import { CSAT_COLORS } from 'submodules/space4leads-components-ui/survey/components/buttons-survey-score/inner/SurveyButtonScoreColors'
import { QuestionLabelsTP } from 'submodules/space4leads-components-ui/survey/components/survey-question-box/inner/QuestionLabelsTP'
import { SurveyTypeEnum } from 'submodules/space4leads-sdk/services/surveys/surveys/enums/SurveyTypeEnum'
import { ConditionalRenderCP } from 'submodules/nerit-framework-ui/common/components/conditional-render/ConditionalRenderCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'

interface ISurveyQuestionBoxCPProps {
    selectedScore?: number
    comment?: string
    disabled?: boolean
    hideObs?: boolean
    questionLabels: QuestionLabelsTP
    type: SurveyTypeEnum
    onSave?: (score: number, comments?: string) => void
    showScoreAfterAnswer: boolean
}

/**
 * BOX para resposta de uma pergunta de pesquisa.
 */
export function SurveyQuestionBoxCP(props: ISurveyQuestionBoxCPProps): JSX.Element {

    const [score, setScore] = useState<number>()
    const [obs, setObs] = useState<string>()
    const [showOnlyObs, setShowOnlyObs] = useState<boolean>(false)

    useEffect(() => setScore(props.selectedScore), [props.selectedScore])
    useEffect(() => setObs(props.comment), [props.comment])

    /**
     * Ao salvar os comentarios
     */
    function onSaveComments(): void {

        if (props.disabled || !score)
            return

        if (!obs) {
            NotificationHelper.warning('Tudo certo!', 'Nos ajude a melhorar ainda mais escrevendo os comentários.')
            return
        }

        if (!!props.onSave)
            props.onSave(score, obs)
    }

    return (
        <>
            {
                !showOnlyObs &&
                <>
                    <TitleCP textSize={props.disabled ? 'normal' : undefined}>
                        { props.questionLabels.mainQuestionLabel }
                    </TitleCP>

                    <FlexCP justifyContent={'center'} margin={{top: 10, bottom: 5}}>
                        <ButtonsSurveyScoreCP
                            type={props.type}
                            selectedScore={score}
                            onClick={(_score) => {
                                if (!props.disabled && !!props.onSave)
                                    props.onSave(_score, obs)
                                setScore(_score)
                                setShowOnlyObs(!props.showScoreAfterAnswer)
                            }}
                        />
                    </FlexCP>

                    <FlexCP justifyContent={'space-between'} margin={{right: 5, left: 5, bottom: 30}}>
                        <FlexCP alignItems={'center'}>
                            <Icon type={'frown'} theme={'twoTone'} twoToneColor={CSAT_COLORS[0]}/>
                            <TextCP text={'Insatisfeito'} marginLeft={5}/>
                        </FlexCP>
                        <FlexCP alignItems={'center'}>
                            <TextCP text={'Muito satisfeito'} marginRight={5}/>
                            <Icon type={'smile'} theme={'twoTone'} twoToneColor={CSAT_COLORS[4]}/>
                        </FlexCP>
                    </FlexCP>
                </>
            }

            <ConditionalRenderCP shouldRender={!props.hideObs}>
                <ConditionalRenderCP shouldRender={!!score || !!props.disabled}>
                    <TextCP
                        wrap={true}
                        text={score! <= 6 ? props.questionLabels.badAnswerQuestionLabel : props.questionLabels.goodAnswerQuestionLabel}
                    />
                    <TextAreaCP
                        placeholder={'Conte pra gente aqui ...'}
                        value={obs}
                        onChange={setObs}
                    />

                    <ConditionalRenderCP shouldRender={!!obs || !!props.disabled}>
                        <FlexCP justifyContent={'flex-end'}>
                            <ButtonCP
                                type={'primary'}
                                onClick={onSaveComments}
                                disabled={props.disabled}
                            >
                                Enviar
                            </ButtonCP>
                        </FlexCP>
                    </ConditionalRenderCP>
                </ConditionalRenderCP>
            </ConditionalRenderCP>
        </>
    )
}
