import React from 'react'
import styled from 'styled-components'
import CheckboxGroup, { CheckboxOptionType } from 'antd/lib/checkbox/Group'

export interface ICheckboxGroupCPProps {
    options: CheckboxOptionType[]
    value?: any
    onChange: (value: any) => void
    disabled?: boolean
    name?: string
}

/**
 * Checkbox group generico
 */
export function CheckboxGroupCP(props: ICheckboxGroupCPProps): JSX.Element {

    return (
        <WrapperSCP>
            <CheckboxGroup
                options={props.options}
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
                name={props.name}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

  .ant-checkbox-group-item {
    display: block;
  }
  
  .ant-checkbox-input {
    margin-right: 5px;
  }

`
