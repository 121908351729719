import { ImportUpdateBehaviorEnum, ImportUpdateBehaviorLabelEnum } from 'submodules/nerit-micro-services-sdk/services/bulk/enums/ImportUpdateBehaviorEnum'
import React from 'react'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'

interface ICPProps {
    onChange: (behavior: ImportUpdateBehaviorEnum) => void
    selectedValue?: ImportUpdateBehaviorEnum
    disabled?: boolean
}

/**
 * Area de definicao da acao a ser tomada em caso de deteccao de registros duplicados durante importacao de pessoas.
 */
export function SelectImportBehaviorCP(props: ICPProps): JSX.Element {

    return (
        <RadioGroupCP
            label={'O que fazer quando encontramos uma pessoa com o mesmo e-mail ou CPF?'}
            type={'button'}
            selected={props.selectedValue}
            onChange={props.onChange}
            buttonHeight={26}
            disabled={props.disabled}
            margin={{ bottom: 30}}
            options={[
                {
                    value: ImportUpdateBehaviorEnum.OVERWRITE,
                    content: <TextCP text={ImportUpdateBehaviorLabelEnum[ImportUpdateBehaviorEnum.OVERWRITE]}/>,
                    tooltip: 'Se a pessoa já existir, atualiza todos os campos dela'
                },
                {
                    value: ImportUpdateBehaviorEnum.UPDATE_BLANK,
                    content: <TextCP text={ImportUpdateBehaviorLabelEnum[ImportUpdateBehaviorEnum.UPDATE_BLANK]}/>,
                    tooltip: 'Se a pessoa já existir, atualiza apenas os campos que estiver em branco atualmente'
                },
                {
                    value: ImportUpdateBehaviorEnum.SKIP,
                    content: <TextCP text={ImportUpdateBehaviorLabelEnum[ImportUpdateBehaviorEnum.SKIP]}/>,
                    tooltip: 'Se a pessoa já existir, não faz nada. Pula para a próxima linha'
                }
            ]}
        />
    )
}
