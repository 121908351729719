import React, { useEffect } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { InvoiceAccountsRequests } from 'submodules/neriteduca-sdk/services/sales/invoice-account/InvoiceAccountsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { AppStateUtils } from 'common/framework-ui/utils/AppStateUtils'
import { GenerateCertificateRequestDTO } from 'submodules/neriteduca-sdk/services/sales/invoice-account/dtos/request/GenerateCertificateRequestDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

interface ICPProps {
    accountCode: number
    isApproved: boolean
}

/**
 * Botao para gerar certificado.
 */
export function ButtonGenerateCertificateCP(props: ICPProps): JSX.Element {

    const generateCertificateRequest = useRequest<string>()
    useEffect(onGenerateCertificateRequestChange, [generateCertificateRequest.isAwaiting])

    /**
     * Inicializa
     */
    function generateCertificate(): void {
        const dto: GenerateCertificateRequestDTO = {
            accountCode: props.accountCode,
            personCode: AppStateUtils.getLoggedUserData()!.user.code
        }
        generateCertificateRequest.runRequest(InvoiceAccountsRequests.generateCertificate(dto))
    }

    /**
     * Retorno da requisicao.
     */
    function onGenerateCertificateRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(generateCertificateRequest, 'Erro obter certificado'))
            return

        window.open(generateCertificateRequest.responseData, '_blank')
    }

    return (
        <ButtonCP
            icon={'download'}
            onClick={generateCertificate}
            disabled={!props.isApproved}
            loading={generateCertificateRequest.isAwaiting}
        >
            Certificado
        </ButtonCP>
    )
}
