import React from 'react'
import { TabUserCourseDetailCP } from 'modules/user/components/tab-user-course-detail/TabUserCourseDetailCP'
import { useParams } from 'react-router'
import { FallbackScreenContentCP } from 'common/framework-ui/components/fallback-screen-content/FallbackScreenContent'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'

/**
 * HOME do usuario.
 */
export function ScreenUserCouseDetail(): JSX.Element {

    const routeParams = useParams<{ accountCode: string, courseCode: string }>()

    if (!routeParams.accountCode || !routeParams.courseCode)
        return <FallbackScreenContentCP status={'error'} title={'Conta ou Curso inválidos'}/>

    return (
        <ContentCP overflowVertical={true}>
            <TabUserCourseDetailCP
                accountCode={+routeParams.accountCode}
                productReleaseCode={+routeParams.courseCode}
            />
        </ContentCP>
    )
}
