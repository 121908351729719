export enum ClassTypeEnum {
    PRESENCIAL = 'PRESENCIAL',
    VIRTUAL = 'VIRTUAL',
    IN_COMPANY = 'IN COMPANY'
}

export enum ClassTypeLabelEnum {
    PRESENCIAL = 'PRESENCIAL',
    VIRTUAL = 'VIRTUAL',
    IN_COMPANY = 'IN COMPANY'
}
