import { Layout } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'

interface ICProps {
    children: React.ReactNode
    style?: React.CSSProperties
    overflowVertical?: boolean
    loading?: boolean
}

/**
 * Area de conteúdo principal dentro de 01 layout.
 */
export function ContentCP(props: ICProps): JSX.Element {

    const screenSize = useScreenSize()

    return (
        <WrapperSCP isSmallScreen={screenSize.smd}>
            <Layout.Content style={props.style}>
                {
                    props.overflowVertical
                        ?
                        <ContentSCP>
                            {
                                props.loading
                                    ?
                                    <LoadingOverlayCP show={true}/>
                                    :
                                    props.children
                            }
                        </ContentSCP>
                        :
                        props.children
                }
            </Layout.Content>
        </WrapperSCP>
    )
}

const ContentSCP = styled.div`
  overflow-y: auto;
`

const WrapperSCP = styled.div<{isSmallScreen: boolean}>`

    height: 100%;
    flex: 1;
    ${props => (props.isSmallScreen ? 'width: 100%;' : '')}

    .ant-layout-content {
        display: flex;
        height: 100%;
        flex-direction: column;
        overflow: hidden;
        position: relative;
    }

`
