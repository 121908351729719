import React from 'react'
import styled, { css } from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'
import { LoadingCP } from 'common/framework-ui/components/loading/LoadingCP'
import { ButtonCP } from 'common/framework-ui/components/button/ButtonCP'
import { FlexCP } from 'common/framework-ui/components/flex/FlexCP'
import { IconCP } from 'common/framework-ui/components/icons/IconCP'

type ScpPropsTP = {
    color?: string,
    underLine?: boolean,
    isStrong?: boolean,
    textSize?: FontSizeTP,
    center?: boolean,
    marginRight?: number,
    marginLeft?: number,
    marginTop?: number,
    marginBottom?: number,
}

type WithButtonTP = {
    loading?: boolean,
    onClick?: () => void,
    icon?: string,
}

interface ITitleCPProps extends ScpPropsTP, WithButtonTP {
    children: JSX.Element | string
    titleIcon?: string
}

/**
 * COMPONTENTE
 * Exibe texto como 'titulo' de algo.
 */
export function TitleCP(props: ITitleCPProps): JSX.Element {

    function getTitle(): JSX.Element {

        return (
            <TitleSCP
                color={props.color}
                isStrong={props.isStrong}
                textSize={props.textSize}
                underLine={props.underLine}
                center={props.center}
                marginTop={props.marginTop ?? 0}
                marginRight={props.marginRight ?? 0}
                marginBottom={props.marginBottom ?? 20}
                marginLeft={props.marginLeft ?? 0}
            >
                <FlexCP alignItems={'center'}>
                    {
                        props.titleIcon &&
                        <IconCP antIcon={props.titleIcon} marginRight={5}/>
                    }
                    { props.children }
                </FlexCP>
            </TitleSCP>
        )
    }

    return (
        <>
            {
                props.onClick
                    ?
                    <FlexCP alignItems={'flex-start'}>
                        { getTitle() }
                        <div>
                            <LoadingCP show={props.loading ?? false} size={'1x'}/>
                            {
                                !props.loading &&
                                <ButtonCP
                                    onClick={props.onClick}
                                    icon={props.icon ?? 'edit'}
                                    type={'link'}
                                    marginLeft={20}
                                />
                            }
                        </div>
                    </FlexCP>
                    :
                    getTitle()
            }
        </>
    )
}

const afterUnderline = css`
    ::after{
        content: '';
        width: 58px;
        height: 2px;
        position: absolute;
        background: ${props => props.theme.secondaryColor};
        bottom: -10px;
        left: 0;
        border-radius: 80px;
    }
`

const TitleSCP = styled.h3<ScpPropsTP>`
    position: relative;
    color: ${props => (props.color ? props.color : props.theme.black)};
    font-weight: ${props => props.isStrong ? 700 : 500};
    font-size: ${props => props.theme.fontSizes[props.textSize ?? 'extraLarge']};
    margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
    text-align: ${props => props.center ? 'center' : 'unset'};
    ${props => (!!props.underLine ? afterUnderline : '')}
`
