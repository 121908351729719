import React from 'react'
import { RecoverPasswordFormCP } from 'modules/auth/components/recover-password-form/RecoverPasswordFormCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { AuthLayoutCP } from 'modules/auth/components/auth-layout/AuthLayoutCP'
import { PublicRouter } from 'app/routes/PublicRouter'

/**
 * TELA: Recuperar senha.
 *
 * @author hjcostabr
 */
export function RecoverPasswordScreen(): JSX.Element {

    return (
        <AuthLayoutCP
            content={
                <RecoverPasswordFormCP goToLogin={() => NeritFrameworkRoutingHelper.historyPush(PublicRouter.USER_LOGIN)}/>
            }
        />
    )
}
