import React, { useState } from 'react'
import cep from 'cep-promise'
import { InputMaskTypeEnum } from 'common/framework-ui/enums/InputMaskTypeEnum'
import { InputCP } from 'common/framework-ui/components/form-fields/input/InputCP'
import { IFormItemCommonProps } from 'common/framework-ui/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { FormModel } from 'common/framework-ui/form-state-manager/FormModel'
import { IZipCodeResponseFullAddress } from 'common/framework-ui/interfaces/IZipCodeResponseFullAddress'

interface IZipCodeSearchInputCPProps<FModelTP extends FormModel> extends IFormItemCommonProps<FModelTP> {
    onSearchEnd: (address?: IZipCodeResponseFullAddress) => void
    onSearchStart?: () => void
    isRequired?: boolean
}

/**
 * COMPONENTE
 * Input para CEP - Utiliza o cep promise para busca do endereço completo
 */
export function ZipCodeSearchInputCP<FModelTP extends FormModel>(props: IZipCodeSearchInputCPProps<FModelTP>): JSX.Element {

    const [isLoading, setLoading] = useState<boolean>(false)

    function handleChange(zipCode: string): void {
        if (!!zipCode && zipCode.length === 8)
            searchZipCode(zipCode)
    }

    function searchZipCode(zipCode: string): void {

        setLoading(true)
        if (!!props.onSearchStart)
            props.onSearchStart()

        let foundAddress: IZipCodeResponseFullAddress | undefined

        cep(zipCode)
            .then(response => {
                foundAddress = response
                handleSuccess(response)
            })
            .catch(err => console.error(`FALHA | ZipCodeSearchInputCP.searchZipCode - Busca pelo cep: "${zipCode}"`, err))
            .finally(() => {
                props.onSearchEnd(foundAddress)
                setLoading(false)
            })
    }

    function handleSuccess(address: IZipCodeResponseFullAddress): void {

        const hasStateManager = (!!props.formStateManager && !!props.fieldName)
        if (!hasStateManager)
            return

        if (!!address.city)
            props.formStateManager!.changeFieldValue('city' as keyof FModelTP, address.city)

        if (!!address.state)
            props.formStateManager!.changeFieldValue('state' as keyof FModelTP, address.state)

        if (!!address.street)
            props.formStateManager!.changeFieldValue('street' as keyof FModelTP, address.street)

        if (!!address.neighborhood)
            props.formStateManager!.changeFieldValue('neighborhood' as keyof FModelTP, address.neighborhood)
    }

    return (
        <InputCP
            label={'CEP'}
            type={'search'}
            loading={isLoading}
            required={props.isRequired}
            onChange={handleChange}
            mask={InputMaskTypeEnum.CEP}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
        />
    )
}
