import { ReducersMapObject } from 'redux'
import { ReduxUtils } from 'common/framework-ui/redux/ReduxUtils'
import { DomainDataTP } from 'common/framework-ui/redux/types/DomainDataTP'
import { AuthActions } from 'modules/auth/AuthActions'
import { IReduxState } from 'common/framework-ui/redux/interfaces/IReduxState'
import { AuthResponseDTO } from 'submodules/neriteduca-sdk/services/auth/authenticate/dtos/response/AuthResponseDTO'

/**
 * Determina mapa de reducers para gestao de estado da aplicacao via redux.
 */
export const Reducers: ReducersMapObject<IReduxState> = {
    userData: ReduxUtils.getPayloadReplacingReducer<AuthResponseDTO>(AuthActions.TYPE_UPDATE_USER),
    domainData: ReduxUtils.getPayloadReplacingReducer<DomainDataTP>(AuthActions.TYPE_UPDATE_DOMAIN),
}
