import React from 'react'
import 'video.js/dist/video-js.css'
import { EadContentTypeEnum } from 'submodules/neriteduca-sdk/services/ead/ead-course/enums/EadContentTypeEnum'
import { IconByContentUtils } from 'modules/ead/components/icon-by-content-type/inner/IconByContentUtils'
import { IconICP } from 'common/framework-ui/components/icons/inner/IconICP'

interface ICPProps {
    contentType: EadContentTypeEnum
}

/**
 * Icone de acordo com o tipo do conteudo.
 */
export function IconByContentCP(props: ICPProps): JSX.Element {

    return (
        <IconICP
            iconName={IconByContentUtils.getIconName(props.contentType)}
        />
    )
}
