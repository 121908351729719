import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import 'video.js/dist/video-js.css'
import { EadFormResponseDTO } from 'submodules/neriteduca-sdk/services/ead/ead-course/dtos/responses/inner/EadFormResponseDTO'
import { TitleCP } from 'common/framework-ui/components/title/TitleCP'
import { CheckboxGroupCP } from 'common/framework-ui/components/checkbox/CheckboxGroupCP'
import { RadioGroupCP } from 'common/framework-ui/components/form-fields/radio-group/RadioGroupCP'
import { HtmlRawCP } from 'common/framework-ui/components/html-raw/HtmlRawCP'
import { EadFormQuestionTypeEnum } from 'submodules/neriteduca-sdk/services/ead/ead-course/enums/EadFormQuestionTypeEnum'
import { FlexCP } from 'common/framework-ui/components/flex/FlexCP'
import { ButtonCP } from 'common/framework-ui/components/button/ButtonCP'
import { FormAnswersSaveRequestDTO } from 'submodules/neriteduca-sdk/services/ead/ead-forms/dtos/requests/FormAnswersSaveRequestDTO'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { NotificationHelper } from 'common/framework-ui/helpers/NotificationHelper'
import { useRequest } from 'common/framework-ui/request-manager/use-request/UseRequest'
import { RequestUtils } from 'common/framework-ui/request-manager/RequestUtils'
import { EadFormRequests } from 'submodules/neriteduca-sdk/services/ead/ead-forms/EadFormRequests'
import { AlertCP } from 'common/framework-ui/components/alert/AlertCP'
import { MaskUtils } from 'common/framework-ui/utils/MaskUtils'

export type OnTestActionDoneTP = 'redoTest' | 'testSubmited'

interface ICPProps {
    accountCode: number
    eadForm: EadFormResponseDTO
    onDoTestAgain: (type: OnTestActionDoneTP) => void
}

/**
 * Monta conteudo do tipo formulario.
 */
export function ClassContentFormCP(props: ICPProps): JSX.Element {

    const [answers, setAnswers] = useState<Map<number, string[]>>(new Map<number, string[]>())

    const saveFormAnswerRequest = useRequest<void>('none')
    useEffect(onSaveFormAnswerRequestChange, [saveFormAnswerRequest.isAwaiting])

    useEffect(init, [props.eadForm])

    /**
     * Inicializa
     */
    function init(): void {

        const _answers = new Map<number, string[]>()
        props.eadForm.userFormAnswer?.answers.forEach((answerData) => {

            let answerFormatted: string[] = []

            // Formata de acordo com o tipo informado
            if (!!answerData.answer)
                answerFormatted.push(answerData.answer)

            else if (Array.isArray(answerData.optionCode))
                answerFormatted = answerData.optionCode?.map((answer) => answer.toString())

            else if (!!answerData.optionCode)
                answerFormatted.push(answerData.optionCode.toString())

            _answers.set(answerData.questionCode, answerFormatted)
        })

        setAnswers(_answers)
    }

    /**
     * Ao selecionar uma resposta.
     */
    function onChangeVal(questionCode: number, val: string[]): void {
        answers.set(questionCode, val)
        setAnswers(new Map<number, string[]>(answers))
    }

    /**
     * Salva respostas
     */
    function saveAnswer(): void {

        const saveDto: FormAnswersSaveRequestDTO = {
            formCode: props.eadForm.code,
            accountCode: props.accountCode,
            answers: []
        }

        let hasAnsweredAll = true

        // Percorre para ver se todas as questoes foram respondidas e monta dto para salvar
        props.eadForm.questions.forEach((question, index) => {

            // Se nao tiver resposta no mapa, avisa usuario
            const foundAnswer = answers.get(question.code)
            if (!foundAnswer || ArrayUtils.isEmpty(foundAnswer)) {
                hasAnsweredAll = false
                NotificationHelper.error('Ops!', `Questão ${index + 1} não respondida`)
                return
            }

            // Resposta aberta, utilizada apenas em questoes abertas
            let answerText: string | undefined
            let optionCode: number | undefined
            let optionCodes: number[] | undefined

            // Para questoes abertas, salva o texto informado
            if (question.type === EadFormQuestionTypeEnum.OPEN_ANSWER) {
                answerText = answers[0]

            } else {
                // Para questoes de escolha, salva o codigo das opcoes
                // Para resposta unica obtem apenas o codigo
                // Para resposta de multipla escolha, transforma opcoes em codigos
                if (question.type === EadFormQuestionTypeEnum.ONE_ANSWER)
                    optionCode = +foundAnswer![0]
                else
                    optionCodes = foundAnswer!.map((option) => +option)
            }

            saveDto.answers.push({
                questionCode: question.code,
                answer: answerText,
                optionCodes: optionCode ?? optionCodes
            })

        })

        // Mensagem de erro ja foi emitida, apenas nao prossegue
        if (!hasAnsweredAll)
            return

        saveFormAnswerRequest.runRequest(EadFormRequests.saveAnswers(saveDto))
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveFormAnswerRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveFormAnswerRequest, 'Ocorreu algun erro ao salvar respostas', true))
            return

        NotificationHelper.info('Yes', 'Questionário respondido com sucesso')
        props.onDoTestAgain('testSubmited')
    }

    return (
        <>
            {
                !!props.eadForm.userFormAnswer &&
                <AlertCP
                    message={`Você já respondeu o questionário, sua nota final é: ${MaskUtils.applyNumberMask(props.eadForm.userFormAnswer.formGrade, 0)}`}
                    type={'warning'}
                    marginBottom={30}
                />
            }

            {
                props.eadForm.questions.map((question, index) => (

                    <ContentWrapperSCP>
                        <TitleCP textSize={'normal'} underLine={true}>
                            {question.title}
                        </TitleCP>
                        {
                            !!question.description &&
                            <DescriptionSCP>
                                <HtmlRawCP html={question.description}/>
                            </DescriptionSCP>
                        }

                        {
                            question.type === EadFormQuestionTypeEnum.TRUE_OR_FALSE &&
                            <CheckboxGroupCP
                                disabled={!!props.eadForm.userFormAnswer}
                                value={answers.get(question.code)}
                                onChange={(val: any) => onChangeVal(question.code, val)}
                                options={question.options?.map((option) => ({
                                    label: option.text,
                                    value: option.code.toString(),
                                })) ?? []}
                            />
                        }

                        {
                            question.type === EadFormQuestionTypeEnum.ONE_ANSWER &&
                            <RadioGroupCP
                                disabled={!!props.eadForm.userFormAnswer}
                                type={'vertical-radio'}
                                options={question.options?.map((option) => ({
                                    content: option.text,
                                    value: option.code.toString(),
                                })) ?? []}
                                selected={answers.get(question.code)?.[0]}
                                onChange={(val: any) => onChangeVal(question.code, [val])}
                            />
                        }

                    </ContentWrapperSCP>
                ))
            }

            <FlexCP justifyContent={'center'} marginTop={50}>
                {
                    !!props.eadForm.userFormAnswer
                        ?
                        <ButtonCP
                            type={'ghost'}
                            onClick={() => props.onDoTestAgain('redoTest')}
                            marginRight={20}
                            confirmMsg={'Você tem certeza que deseja cancelar as respostas anteriores e refazer o Questionário?'}
                        >
                            Refazer Teste
                        </ButtonCP>
                        :
                        <ButtonCP
                            type={'primary'}
                            onClick={saveAnswer}
                            loading={saveFormAnswerRequest.isAwaiting}
                        >
                            Enviar respostas
                        </ButtonCP>
                }
            </FlexCP>
        </>
    )
}

const DescriptionSCP = styled.div`
  font-style: italic;
  margin-bottom: 10px;
`

const ContentWrapperSCP = styled.div`
    margin-bottom: 20px;
`
