import { IsNotEmpty } from 'common/framework-ui/validation/decorators/IsNotEmpty'
import { FormModel } from 'common/framework-ui/form-state-manager/FormModel'

/**
 * VALIDADOR para form de alteracao de senha
 *
 * @author renatofs
 */
export class ChangePasswordFormValidator extends FormModel {

    @IsNotEmpty()
    currentPassword: string

    @IsNotEmpty()
    newPassword: string

    @IsNotEmpty()
    confirmNewPassword: string

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
