import React, { useEffect, useState } from 'react'
import md5 from 'md5'
import { InputCP } from 'common/framework-ui/components/form-fields/input/InputCP'
import { LockIconCP } from 'common/framework-ui/components/icons/LockIconCP'
import { HttpStatusEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/framework-ui/form-state-manager/UseFormStateManager'
import { useRequest } from 'common/framework-ui/request-manager/use-request/UseRequest'
import { AuthFormFooterCP } from 'modules/auth/components/auth-form-footer/AuthFormFooterCP'
import { UserLoginFormValidator } from 'modules/auth/components/user-login-form/inner/UserLoginFormValidator'
import { RequestUtils } from 'common/framework-ui/request-manager/RequestUtils'
import { AppStateUtils } from 'common/framework-ui/utils/AppStateUtils'
import { AuthActions } from 'modules/auth/AuthActions'
import { AuthResponseDTO } from 'submodules/neriteduca-sdk/services/auth/authenticate/dtos/response/AuthResponseDTO'
import { AuthRequests } from 'submodules/neriteduca-sdk/services/auth/authenticate/AuthRequests'
import { UserLoginRequestDTO } from 'submodules/neriteduca-sdk/services/auth/authenticate/dtos/requests/UserLoginRequestDTO'
import { SchemaResponseDTO } from 'submodules/neriteduca-sdk/services/sys-admin/schema/dtos/response/SchemaResponseDTO'
import { SysAdminRequests } from 'submodules/neriteduca-sdk/services/sys-admin/SysAdminRequests'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'

interface ILoginFormICPProps {
    onRecoverPassword: () => void
    onLoginSuccess: () => void
}

/**
 * Formulario de login de usuario do sistema.
 */
export function LoginFormCP(props: ILoginFormICPProps): JSX.Element {

    const [formValidator] = useState<UserLoginFormValidator>(new UserLoginFormValidator({ schema: AppStateUtils.getDomainSlug() }))

    const loginRequest = useRequest<AuthResponseDTO>()
    const domainSchemaRequest = useRequest<SchemaResponseDTO>()

    const formStateManager = useFormStateManager(formValidator)

    useEffect(onLoginRequestChange, [loginRequest.isAwaiting])
    useEffect(onSchemaRequestChange, [domainSchemaRequest.isAwaiting])

    async function onFormSubmit(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()!

        const loginDto: UserLoginRequestDTO = {
            login: formValues.email,
            password: md5(formValues.password)
        }

        loginRequest.runRequest(AuthRequests.login(formValues.schema, loginDto))
    }

    function onLoginRequestChange(): void {

        if (loginRequest.isAwaiting || !loginRequest.wasTried)
            return

        const isSuccess = (loginRequest.isSuccess && !!loginRequest.responseData)
        if (!isSuccess)
            return handleLoginFailure()

        const schemaOnForm = formStateManager.getFieldValue('schema')
        const resDto = loginRequest.responseData as AuthResponseDTO

        if (AppStateUtils.getDomainSlug() === schemaOnForm)
            return onLoginSuccess(resDto)

        domainSchemaRequest.runRequest(SysAdminRequests.validateSchema(schemaOnForm))
    }

    /** Retorno de dados do schema, chamado depois do login. */
    function onSchemaRequestChange(): void {
        if (RequestUtils.isValidRequestReturn({
            request: domainSchemaRequest,
            errorMsg: 'Falha ao capturar dados do dominio',
            failureLogMsg: 'FALHA - LoginFormCP.onSchemaRequestChange: ',
            mustReport403: true,
        })) {
            AuthActions.setDomain(domainSchemaRequest.responseData!)
            onLoginSuccess(loginRequest.responseData!)
        }
    }

    /** Se deu certo o login. */
    function onLoginSuccess(auth: AuthResponseDTO): void {
        AuthActions.login(auth)
        props.onLoginSuccess()
    }

    /** Trata casos de erro no login. */
    function handleLoginFailure(): void {

        let errorNotification = 'Falha ao tentar realizar login'

        if (loginRequest.responseStatus === HttpStatusEnum.FORBIDDEN)
            errorNotification = 'Domínio inativo ou não reconhecido'

        else if ([HttpStatusEnum.UNAUTHORIZED, HttpStatusEnum.NOT_FOUND].includes(loginRequest.responseStatus!))
            errorNotification = 'e-mail ou senha inválidos'
        else
            console.error('FALHA - UserLoginFormCP.handleLoginFailure: ', 'Falha inesperada', loginRequest.responseData, loginRequest.error)

        RequestUtils.showDefaultErrorNotification(loginRequest.error, errorNotification)
    }

    return (
        <>
            <InputCP
                label={'Login'}
                fieldName={'email'}
                formStateManager={formStateManager}
                required={true}
                onFormSubmit={onFormSubmit}
                icon={<IconICP iconName={'user'}/>}
            />

            <InputCP
                label={'Senha'}
                type={'password'}
                fieldName={'password'}
                formStateManager={formStateManager}
                required={true}
                onFormSubmit={onFormSubmit}
                icon={<LockIconCP/>}
            />

            <AuthFormFooterCP
                link={{
                    text: 'Esqueci minha senha',
                    onClick: props.onRecoverPassword,
                }}
                button={{
                    text: 'Entrar',
                    onClick: onFormSubmit,
                }}
                awaiting={loginRequest.isAwaiting || domainSchemaRequest.isAwaiting}
            />
        </>
    )
}
