import { Popconfirm } from 'antd'
import React from 'react'

interface IPopConfirmCPProps {
    title: string
    onConfirm?: () => void
    onCancel?: () => void
    children: JSX.Element
    okText?: string
    cancelText?: string
    disabled?: boolean
    placement?: 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom'
}

/**
 * Componente de MODAL de CONFIRMACAO
 * @param {IPopConfirmCPProps} props
 *
 * @author renatofs
 */
export function PopConfirmCP(props: IPopConfirmCPProps): JSX.Element {

    return (
        <Popconfirm
            title={props.title}
            onConfirm={props.onConfirm}
            onCancel={props.onCancel}
            okText={'Sim'}
            cancelText={'Não'}
            placement={props.placement}
            disabled={props.disabled}
        >
            {props.children}
        </Popconfirm>

    )

}