import React from 'react'
import styled from 'styled-components'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

interface ICPProps extends BasicStyleWrapperCPProps {
    show?: boolean
    size?: number
    text?: string
}

/**
 * Componente loading.
 */
export function LoadingCP(props: ICPProps): JSX.Element {

    if (props.show !== undefined && !props.show)
        return <></>

    return (
        <BasicStyleWrapperCP margin={props.margin}>
            <LoadingWrapperSCP>
                <IconICP iconName={'loading'} size={props.size ?? 32}/>
                {
                    !!props.text &&
                    <TextCP text={props.text} marginLeft={10}/>
                }
            </LoadingWrapperSCP>
        </BasicStyleWrapperCP>
    )

}

const LoadingWrapperSCP = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

