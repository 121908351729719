import videojs from 'video.js'

export const VideoUtils = {

    getVideoJSOptions(videoUrl: string, isYoutube: boolean): videojs.PlayerOptions {

        return {
            autoplay: true,
            controls: true,
            responsive: true,
            fluid: true,
            playbackRates: [0.75, 1.0, 1.25, 1.5, 2.0],
            sources: [{
                src: videoUrl,
                type: isYoutube ? 'video/youtube' : 'video/mp4'
            }]
        };
    },
}
