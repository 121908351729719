import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRequest } from 'common/framework-ui/request-manager/use-request/UseRequest'
import { RequestUtils } from 'common/framework-ui/request-manager/RequestUtils'
import { IGenericListResponseDTO } from 'common/framework-ui/dtos/responses/IGenericListResponseDTO'
import { LoadingOverlayCP } from 'common/framework-ui/components/loading/loading-overlay/LoadingOverlayCP'
import { CardUserPurchaseCP } from 'modules/user/components/card-user-purchase/CardUserPurchaseCP'
import { InvoiceAccountsRequests } from 'submodules/neriteduca-sdk/services/sales/invoice-account/InvoiceAccountsRequests'
import { UserPurchaseResponseDTO } from 'submodules/neriteduca-sdk/services/sales/invoice-account/dtos/response/UserPurchaseResponseDTO'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'

/**
 * HOME do usuario.
 */
export function ScreenUserCourses(): JSX.Element {

    const [userPurchases, setUserPurchases] = useState<UserPurchaseResponseDTO[]>([])
    const getUserPurchasesRequest = useRequest<IGenericListResponseDTO<UserPurchaseResponseDTO>>()
    useEffect(onGetUserPurchasesRequestChange, [getUserPurchasesRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa
     */
    function init(): void {
        getUserPurchasesRequest.runRequest(InvoiceAccountsRequests.getUserPurchases())
    }

    /**
     * Retorno da requisicao.
     */
    function onGetUserPurchasesRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getUserPurchasesRequest, 'Erro obter compras do usuario'))
            return

        setUserPurchases(getUserPurchasesRequest.responseData!.list)
    }

    return (
        <ContentCP overflowVertical={true}>
            <LoadingOverlayCP show={getUserPurchasesRequest.isAwaiting}/>

            <ContentWrapperSCP>

                {
                    userPurchases.map((purchase) => (
                        <CardUserPurchaseCP purchase={purchase}/>
                    ))
                }

            </ContentWrapperSCP>
        </ContentCP>
    )
}

const ContentWrapperSCP = styled.div`
  padding: 40px;

  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 320px))  ;
  grid-gap: 1rem;
  width: 100%;
  justify-content: center;

`
