import moment from 'moment-timezone'
import { MonthEnum } from 'common/framework-ui/enums/MonthEnum'
import { DateFormatEnum } from 'common/framework-ui/enums/DateFormatEnum'
import { SystemConfig } from 'config/SystemConfig'
import { OrNullTP } from 'common/framework-ui/types/OrNullTP'
import { TimeBaseEnum } from 'common/framework-ui/enums/TimeBaseEnum'
import { OrUndefTP } from 'common/framework-ui/types/OrUndefTP'
import { DayOfMonthTP } from 'common/framework-ui/types/DayOfMonthTP'
import { momentPtBrLocale } from 'common/framework-ui/utils/date/moment-pt-br-locale'
import { DatePropsTP } from 'common/framework-ui/types/DatePropsTP'
import { QuickDateEnum } from 'common/framework-ui/enums/QuickDateEnum'
import { Moment } from 'moment'

/**
 * UTILITARIOS
 * Reune metodos genericos uteis para manipulacao de datas.
 */
export class DateUtils {

    /** Retorna uma instancia de Data de acordo com valor, formato e timezone passados. */
    static fixTimeZone(
        date: Date | string,
        format: DateFormatEnum = DateFormatEnum.US_WITHOUT_TIME,
        timezone: string = SystemConfig.getInstance().defaultTimeZone
    ): OrNullTP<Date> {
        const fixedDate = !!date ? moment(moment.tz(date, timezone).format(format)).toDate() : null
        return fixedDate ?? null
    }

    /** Aplica horario da data 1 a data 2. */
    static mergeHour(date1: Date, date2: Date): Date {
        const newDate = new Date()
        newDate.setTime(date1.getTime())
        newDate.setHours(date2.getHours(), date2.getMinutes(), date2.getSeconds())
        return newDate
    }

    static getFormatted(date: string | Date | undefined, format: DateFormatEnum): string {
        if (!date)
            return ''
        moment.defineLocale('pt_BR', momentPtBrLocale)
        return moment(date).format(format)
    }

    /** Transforma uma data, string, de um formato para outro. Ex.: 22/04/1987 para 1987-04-22. */
    static transformDateStrFormat(dateStr: string, originalFormat: DateFormatEnum, finalFormat: DateFormatEnum): string {
        return DateUtils.getFormatted(DateUtils.toDate(dateStr, originalFormat), finalFormat)
    }

    /** Transforma string data. */
    static toDate(dateStr: string, dateStrFormat: DateFormatEnum): Date {
        return moment(dateStr, dateStrFormat).toDate()
    }

    /** Valida 01 string quanto a representar 01 data valida (formato americano). */
    static isValidUSADateString(dateString: string): boolean {
        try {
            if (!dateString.match(/^\d{4}-\d{2}-\d{2}$/))
                return false
            return moment(dateString, DateFormatEnum.US_WITHOUT_TIME).isValid()

        } catch (error) {
            return false
        }
    }

    /** Define HORAS & MINUTOS de 01 data. */
    static setTime(date: Date, hours: number, minutes: number): Date {
        return moment(date)
            .set(TimeBaseEnum.HOUR, hours)
            .set(TimeBaseEnum.MINUTE, minutes)
            .toDate()
    }

    /** Obtem hora:minuros do horario de uma data. */
    static getTimeStr(date: Date): string {
        const hour = `${moment(date).get(TimeBaseEnum.HOUR)}`.padStart(2, '0')
        const minute = `${moment(date).get(TimeBaseEnum.MINUTE)}`.padStart(2, '0')
        return `${hour}:${minute}`
    }

    /** SOMA valor de tempo a 01 data. */
    static add(date: Date, value: number, timeBase: TimeBaseEnum): Date {
        return moment(date).add(value, timeBase).toDate()
    }

    /** SUBTRAI valor de tempo a 01 data. */
    static subtract(date: Date, value: number, timeBase: TimeBaseEnum): Date {
        return moment(date).subtract(value, timeBase).toDate()
    }

    /** Retorna diferenca entre data informada e data atual, numa determinada unidade de tempo. */
    static getDiff(timeBase: TimeBaseEnum, date: Date, secondDate: Date = new Date()): number {
        return +moment(secondDate).diff(moment(date), timeBase)
    }

    static getPresentableDiff(date: Date, secondDate: Date = new Date()): string {

        const yearDiff = DateUtils.getDiff(TimeBaseEnum.YEAR, date, secondDate)
        if (yearDiff > 0)
            return `${yearDiff} ${yearDiff === 1 ? 'ano' : 'anos'}`

        const monthDiff = DateUtils.getDiff(TimeBaseEnum.MONTH, date, secondDate)
        if (monthDiff > 0)
            return `${monthDiff} ${monthDiff === 1 ? 'mês' : 'meses'}`

        const dayDiff = DateUtils.getDiff(TimeBaseEnum.DAY, date, secondDate)
        if (dayDiff > 0)
            return `${dayDiff} ${dayDiff === 1 ? 'dia' : 'dias'}`

        return 'poucas horas'
    }

    /** Retorna hora & minutos extraidos de 01 string que venha na forma [hora]:[minutos]. */
    static getHourAndMinutes(timeStr: string): { hour: number, minutes: number } {

        let timeArray: OrUndefTP<[number, number]>
        if (/^((2[0-3])|([01][0-9])):([0-5][0-9])$/.test(timeStr))
            timeArray = timeStr.split(':').map(timeUnit => +timeUnit) as [number, number]

        return {
            hour: timeArray?.[0] ?? 0,
            minutes: timeArray?.[1] ?? 0,
        }
    }

    /** Extrai & retorna dia de 01 mes. */
    static getDay(date?: Date): DayOfMonthTP {
        return moment(date ?? new Date()).date() as DayOfMonthTP
    }

    /** Extrai & retorna mes de 01 data. */
    static getMonth(date?: Date): MonthEnum {
        return moment(date ?? new Date()).month()
    }

    static getMonthName(month: MonthEnum, format: DateFormatEnum.MONTH | DateFormatEnum.MONTH_3 = DateFormatEnum.MONTH): string {
        return DateUtils.getFormatted(DateUtils.getDate({ month }), format)
    }

    static getMonthNumbers(): number[] {
        return Object.values(MonthEnum).map(Number).filter(month => !Number.isNaN(month))
    }

    static getYear(date?: Date): number {
        return moment(date ?? new Date()).year()
    }

    static isValidDate(params: Required<DatePropsTP>): boolean {
        const date = new Date(params.year, params.month, params.day)
        return (!Number.isNaN(+date) && date.getDate() === +params.day)
    }

    /**
     * TODO: 04/12/2020 - Usar DateFormatEnum
     */
    static getPresentableDateHour(beginTime: Date | string): string {

        if (moment(beginTime).isSame(new Date(), 'd'))
            return `Hoje às ${moment(beginTime).format('HH:mm')}`

        if (moment(beginTime).isSame(moment(new Date()).subtract(1, 'days').startOf('day'), 'd'))
            return `Ontem às ${moment(beginTime).format('HH:mm')}`

        return DateUtils.getFormatted(beginTime, DateFormatEnum.BR_WITH_TIME_H_M)
    }

    static getDate(params?: DatePropsTP): Date {

        const now = new Date()

        return new Date(
            params?.year ?? now.getFullYear(),
            params?.month ?? now.getMonth(),
            params?.day ?? now.getDate(),
            0,
            0,
            0,
            0,
        )
    }

    static isSameDay(date1: Date, date2?: Date): boolean {
        return DateUtils._isSameDateUnit(TimeBaseEnum.DAY, date1, date2)
    }

    static isSameMonth(date1: Date, date2?: Date): boolean {
        return DateUtils._isSameDateUnit(TimeBaseEnum.MONTH, date1, date2)
    }

    static getLastDayOfMonth(month: MonthEnum, year?: number): DayOfMonthTP {

        if (month === MonthEnum.DEC)
            return 31

        const nextMonthFirstDay = DateUtils.getDate({
            day: 1,
            month: (month + 1),
            year,
        })

        return DateUtils.getDay(DateUtils.subtract(nextMonthFirstDay, 1, TimeBaseEnum.DAY))
    }

    /** Determinar se valor corresponde a alguma das constantes de 'valor rapido' de data. */
    static isQuickDateValue(valueToTest: unknown): boolean {
        return Object.values(QuickDateEnum).includes(valueToTest as QuickDateEnum)
    }

    /** Obtem moment de uma string com horario. */
    static getMomentFromTime(timeStr?: string): Moment | undefined {
        return timeStr ? moment(`${timeStr}`, 'HH:mm') : undefined
    }

    /** Determina e 02 datas possuem 01 determinada unidade com valores identicos. */
    private static _isSameDateUnit(type: TimeBaseEnum.DAY | TimeBaseEnum.MONTH, date1: Date, date2?: Date): boolean {

        date2 = date2 ?? new Date()
        const dateObj1: DatePropsTP = { month: date1.getMonth(), year: date1.getFullYear() }
        const dateObj2: DatePropsTP = { month: date2.getMonth(), year: date2.getFullYear() }

        if (type === TimeBaseEnum.DAY) {
            dateObj1.day = date1.getDate() as DayOfMonthTP
            dateObj2.day = date2.getDate() as DayOfMonthTP
        }

        return (DateUtils.getDate(dateObj1).getTime() === DateUtils.getDate(dateObj2).getTime())
    }

}
