import { MimeTypeEnum } from 'common/framework-ui/enums/MimeTypeEnum'

/**
 * UTILITARIOS para lidar com Mime-Types de arquivos.
 */
export const MimeTypeUtils = {

    isCsvMimeType(mimeType: string): boolean {
        return MimeTypeUtils.getCsvMimeTypes().includes(mimeType as MimeTypeEnum)
    },

    getCsvMimeTypes(): MimeTypeEnum[] {
        return [
            MimeTypeEnum.CSV_STANDARD,
            MimeTypeEnum.CSV_ALTERNATIVE_1,
            MimeTypeEnum.CSV_ALTERNATIVE_2,
            MimeTypeEnum.CSV_ALTERNATIVE_3,
            MimeTypeEnum.CSV_ALTERNATIVE_4,
            MimeTypeEnum.CSV_MICROSOFT,
        ]
    },
}
