import React from 'react'
import { PersonImportValidationErrorsItemTP } from 'submodules/nerit-micro-services-ui/import/components/table-import-validation-errors/inner/PersonImportValidationErrorsItemTP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import styled from 'styled-components'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'

interface IPersonImportValidationErrorDescColumnICPProps {
    lineErrors: PersonImportValidationErrorsItemTP
}

/**
 * Coluna de detalhamento de falhas em 01 linha da tabela de exibicao de falhas de validacao
 * de arquivo .csv para importacao de pessoas.
 */
export function PersonImportValidationErrorDescColumnICP(props: IPersonImportValidationErrorDescColumnICPProps): JSX.Element {

    /* eslint-disable react/jsx-no-useless-fragment */
    return (
        <>
            {
                Object.keys(props.lineErrors.errors).map((errorProp, index) => {

                    const propErrors = props.lineErrors.errors[errorProp]

                    return (
                        <ErrorDescriptionsWrapperSCP key={`validation_error_${props.lineErrors.line}_${errorProp}_${index}`}>
                            <TextCP
                                text={errorProp}
                                strong={true}
                                center={true}
                                style={{ width: 125 }}
                                size={'small'}
                            />

                            {
                                propErrors.map((error: any, idx: any) => (
                                    <ErrorDescriptionsWrapperSCP key={`validation_error_${props.lineErrors.line}_${errorProp}_${idx}`}>
                                        <IconICP
                                            iconName={'right'}
                                            style={{ margin: '0 4px' }}
                                            size={10}
                                        />

                                        <TextCP size={'small'} text={error}/>
                                    </ErrorDescriptionsWrapperSCP>
                                ))
                            }
                        </ErrorDescriptionsWrapperSCP>
                    )
                })
            }
        </>
    )
    /* eslint-enable react/jsx-no-useless-fragment */
}

const ErrorDescriptionsWrapperSCP = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
`
