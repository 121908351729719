import React, { useEffect } from 'react'
import { MimeTypeEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/MimeTypeEnum'
import { PersonImportValidationErrorsTP } from 'submodules/nerit-micro-services-ui/import/components/modal-import/inner/PersonImportValidationErrorsTP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { HttpStatusEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpStatusEnum'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import styled from 'styled-components'
import { UploadFileCP } from 'submodules/nerit-framework-ui/common/components/file/upload-file/UploadFileCP'

interface ICPProps {
    canDoUpload: boolean
    requestConfigTP: (file: File) => RequestConfigTP | undefined
    stateCallbacks: {
        onUploadStart: () => void,
        onValidationSuccess: () => void,
        onValidationFailure: (errors: PersonImportValidationErrorsTP) => void,
        onError: () => void,
    }
}

/**
 * Area de upload para card de importacoes
 */
export function UploadImportCP(props: ICPProps): JSX.Element {

    const importRequest = useRequest<void>('none')
    useEffect(onImportRequestChange, [importRequest.isAwaiting])

    /**
     * Faz upload
     */
    function uploadFile(file: File): void {

        const requestConfig = props.requestConfigTP(file)
        if (!requestConfig)
            return

        importRequest.runRequest(requestConfig)
        props.stateCallbacks.onUploadStart()
    }

    /**
     * Retorno da importacao.
     */
    function onImportRequestChange(): void {

        if (!RequestUtils.isRequestConcluded(importRequest))
            return

        if (RequestUtils.isRequestSuccess(importRequest, true))
            return props.stateCallbacks.onValidationSuccess()

        const errorDescription = importRequest.error?.data?.errors
        if (importRequest.responseStatus === HttpStatusEnum.BAD_REQUEST && typeof errorDescription === 'object')
            return props.stateCallbacks.onValidationFailure(errorDescription)

        console.error('FALHA - UploadStepICP.onImportRequestChange: ', importRequest.responseData, importRequest.error)
        props.stateCallbacks.onError()
    }

    return (
        <WrapperSCP className={!props.canDoUpload ? 'upload_step_disabled' : ''}>
            <UploadTitleSCP>
                <TextCP
                    wrap={true}
                    strong={true}
                    text={'Faça upload do seu arquivo no formato CSV'}
                />
            </UploadTitleSCP>

            <UploadFileCP
                multiple={false}
                disabled={!props.canDoUpload}
                fileTypes={[MimeTypeEnum.XLSX]}
                loading={importRequest.isAwaiting}
                loadingMsg={'Realizando upload e verificando dados da planilha'}
                uploadFiles={uploadFile}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    &.upload_step_disabled {

        opacity: .5;
        cursor: not-allowed;
        transition: opacity .3s;

        &:hover {
            opacity: .3;
        }
    }
`

const UploadTitleSCP = styled.div`
    margin-top: 30px;
    margin-bottom: 10px;
`
