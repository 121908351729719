import React, { useEffect, useState } from 'react'
import { useRequest } from 'common/framework-ui/request-manager/use-request/UseRequest'
import { RequestUtils } from 'common/framework-ui/request-manager/RequestUtils'
import { EadCourseRequests } from 'submodules/neriteduca-sdk/services/ead/ead-course/EadCourseRequests'
import { CascaderCP } from 'common/framework-ui/components/cascader/CascaderCP'
import { FlexCP } from 'common/framework-ui/components/flex/FlexCP'
import { LoadingCP } from 'common/framework-ui/components/loading/LoadingCP'
import { CascaderValueTP } from 'common/framework-ui/components/cascader/inner/CascaderValueTP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PrivateRouter } from 'app/routes/PrivateRouter'
import { ISearchResult } from 'submodules/nerit-framework-utils/sdk-utils/dtos/interfaces/ISearchResult'
import { EadCourseModuleStructureResponseDTO } from 'submodules/neriteduca-sdk/services/ead/ead-course/dtos/responses/EadCourseModuleStructureResponseDTO'
import { EadClassStructureRequestDTO } from 'submodules/neriteduca-sdk/services/ead/ead-course/dtos/requests/EadClassStructureRequestDTO'

interface ICPProps {
    productReleaseCode: number
    classContentCode: number
    accountCode: number
}

/**
 * Aba do conteudo EAD.
 */
export function CascaderEadCourseStructureCP(props: ICPProps): JSX.Element {

    const [selectedOption, setSelectedOption] = useState<CascaderValueTP>()

    const [eadCourseStructure, setEadCourseStructure] = useState<EadCourseModuleStructureResponseDTO[]>()
    const getEadCourseStructureRequest = useRequest<ISearchResult<EadCourseModuleStructureResponseDTO>>()
    useEffect(onGetEadCourseStructureRequestChange, [getEadCourseStructureRequest.isAwaiting])

    useEffect(init, [props.productReleaseCode])

    /**
     * Inicializa
     */
    function init(): void {

        const dto: EadClassStructureRequestDTO = {
            accountCode: props.accountCode,
            productReleaseCode: props.productReleaseCode
        }
        getEadCourseStructureRequest.runRequest(EadCourseRequests.getCourseStructure(dto))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetEadCourseStructureRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getEadCourseStructureRequest, 'Erro obter conteudo do curso'))
            return

        const result = getEadCourseStructureRequest.responseData!.list
        setEadCourseStructure(result)

        let foundModule = ''
        let foundClass = ''

        // Percorre a estrutura para obter o modulo e aula baseado no conteudo passado como parametro
        result.forEach((moduleStructure) => {
            moduleStructure.eadClasses?.forEach((classStructure) => {
                classStructure.eadContents.forEach((contentStructure) => {
                    if (contentStructure.eadContent.code !== props.classContentCode)
                        return

                    foundClass = classStructure.eadClass.code.toString()
                    foundModule = moduleStructure.eadModule.code.toString()
                })
            })
        })

        setSelectedOption([foundModule, foundClass, props.classContentCode.toString()])
    }

    if (!eadCourseStructure) {
        return (
            <FlexCP justifyContent={'center'}>
                <LoadingCP show={true}/>
            </FlexCP>
        )
    }

    return (
        <CascaderCP
            options={
                eadCourseStructure.map((moduleStructure) => ({
                    key: `key-module-${moduleStructure.eadModule.code}`,
                    isLeaf: false,
                    label: moduleStructure.eadModule.name,
                    value: moduleStructure.eadModule.code.toString(),
                    children: moduleStructure.eadClasses?.map((moduleClassStructure) => ({
                        isLeaf: false,
                        label: moduleClassStructure.eadClass.name,
                        value: moduleClassStructure.eadClass.code.toString(),
                        children: moduleClassStructure.eadContents.map((classContent) => ({
                            isLeaf: true,
                            label: classContent.eadContent.name,
                            value: classContent.eadContent.code.toString()
                        }))
                    }))
                }) ?? [])
            }
            value={selectedOption}
            onChange={(value) => NeritFrameworkRoutingHelper.historyPush(`${PrivateRouter.USER_COURSE_EAD_ROOT}/${props.productReleaseCode}/${value[2]}`)}
        />
    )

}
