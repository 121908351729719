import React from 'react'
import { Progress } from 'antd'
import styled from 'styled-components'
import { TextCP } from 'common/framework-ui/components/text/TextCP'
import { FlexCP } from 'common/framework-ui/components/flex/FlexCP'
import { TooltipCP } from 'common/framework-ui/components/tooltip/TooltipCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'

interface IScpProps {
    primaryColor?: string
    secondColor?: string
    backgroudColorArea?: string
    width?: number
    size?: 'small' | 'default' | 'big'
}

interface IProgressCPProps extends IScpProps {
    type?: 'line' | 'circle' | 'dashboard'
    percent: number
    status?: 'normal' | 'active' | 'success' | 'exception'
    strokeLinecap?: 'round' | 'square'
    gradientColor?: boolean
    invertGradientColor?: boolean
    showInfo?: boolean
    secondPercent?: number
    descriptionText?: string
    tooltip?: string | JSX.Element
}

/**
 * Wrapper para componente PROGRESS do antd
 */
export function ProgressCP(props: IProgressCPProps): JSX.Element {

    function render(): JSX.Element {
        return (
            <FlexCP>
                <ProgressWrapperSCP
                    primaryColor={props.primaryColor}
                    secondColor={props.secondColor}
                    width={props.width}
                    backgroudColorArea={props.backgroudColorArea}
                    size={props.size}
                >
                    <Progress
                        type={props.type}
                        size={props.size === 'big' ? 'default' : props.size}
                        percent={props.percent}
                        status={(props.invertGradientColor && props.percent >= 100) ? 'exception' : props.status}
                        strokeColor={props.gradientColor
                            ? props.invertGradientColor ? { '0%': ThemeAnt.successColor, '100%': ThemeAnt.errorColor } : { '0%': ThemeAnt.errorColor, '100%': ThemeAnt.successColor }
                            : undefined}
                        strokeLinecap={props.strokeLinecap}
                        showInfo={props.showInfo}
                        successPercent={props.secondPercent}
                    />
                </ProgressWrapperSCP>
                <>
                    {
                        props.descriptionText &&
                        <TextCP text={props.descriptionText} marginLeft={5}/>
                    }
                </>
            </FlexCP>
        )
    }

    return (
        <>
            {
                props.tooltip
                    ?
                    <TooltipCP text={props.tooltip} showSpan={true}>
                        { render() }
                    </TooltipCP>
                    :
                    render()
            }
        </>
    )

}

const ProgressWrapperSCP = styled.div<IScpProps>`

    width: ${props => props.width ? `${props.width}px` : '100%'}

    .ant-progress-inner {
      ${props => props.size === 'big' ? 'height:  20px' : undefined}
      background-color: ${props => props.backgroudColorArea}
    }
    
    .ant-progress-bg {
      margin: 2px;
      background-color: ${props => props.primaryColor ?? props.theme.primaryColor};
      height: ${props => props.size === 'big' ? '19px !important' : undefined};
    }
    
    .ant-progress-success-bg {
      background-color: ${props => props.secondColor ?? undefined}
    }

`
