import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { RequestHeaderTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestHeaderTP'
import { NeritDocsApiEnums } from 'submodules/nerit-micro-services-sdk/common/NeritDocsApiEnums'
import { BulkOperationFinishRequestDTO } from 'submodules/nerit-micro-services-sdk/services/bulk/dtos/requests/BulkOperationFinishRequestDTO'
import { BulkOperationLogUpdateRequestDTO } from 'submodules/nerit-micro-services-sdk/services/bulk/dtos/requests/BulkOperationLogUpdateRequestDTO'
import { BulkOperationSearchRequestDTO } from 'submodules/nerit-micro-services-sdk/services/bulk/dtos/requests/BulkOperationSearchRequestDTO'
import { BulkOperationStartSaveRequestDTO } from 'submodules/nerit-micro-services-sdk/services/bulk/dtos/requests/BulkOperationStartSaveRequestDTO'
import { BulkOperationUpdateRecordCountRequestDTO } from 'submodules/nerit-micro-services-sdk/services/bulk/dtos/requests/BulkOperationUpdateRecordCountRequestDTO'

export class BulkRequests {

	public static CONTROLLER_ROOT = 'bulk-operations'
	public static POST_START_BULK_OPERATION_EP = 'start-bulk-operation'
	public static PUT_UPDATE_TOTAL_RECORDS_EP = 'update-total-records'
	public static PUT_FINISH_BULK_OPERATION_EP = 'finish-bulk-operation'
	public static PUT_ADD_SUCCESS_RECORD_EP = 'add-success-record'
	public static PUT_ADD_IGNORED_RECORD_EP = 'add-ignored-record'
	public static PUT_ADD_ERROR_RECORD_EP = 'add-error-record'
	public static GET_ONE_EP = 'get/:code'
	public static GET_SEARCH_EP = 'search'

	static search = (dto?: BulkOperationSearchRequestDTO, headers?: RequestHeaderTP[]): RequestConfigTP => ({
		baseUrlType: NeritDocsApiEnums.NERIT_DOCS_API,
		url: `${BulkRequests.CONTROLLER_ROOT}/${BulkRequests.GET_SEARCH_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
		headers
	})

	static get = (code: number, headers?: RequestHeaderTP[]): RequestConfigTP => ({
		baseUrlType: NeritDocsApiEnums.NERIT_DOCS_API,
		url: `${BulkRequests.CONTROLLER_ROOT}/${BulkRequests.GET_ONE_EP}/${code}`,
		method: HttpMethodEnum.GET,
		headers
	})

	static startBulkOperation = (dto: BulkOperationStartSaveRequestDTO, headers?: RequestHeaderTP[]): RequestConfigTP => ({
		baseUrlType: NeritDocsApiEnums.NERIT_DOCS_API,
		url: `${BulkRequests.CONTROLLER_ROOT}/${BulkRequests.POST_START_BULK_OPERATION_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
		headers
	})

	static updateTotalRecords = (dto: BulkOperationUpdateRecordCountRequestDTO, headers?: RequestHeaderTP[]): RequestConfigTP => ({
		baseUrlType: NeritDocsApiEnums.NERIT_DOCS_API,
		url: `${BulkRequests.CONTROLLER_ROOT}/${BulkRequests.PUT_UPDATE_TOTAL_RECORDS_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
		headers
	})

	static finishBulkOperation = (dto: BulkOperationFinishRequestDTO, headers?: RequestHeaderTP[]): RequestConfigTP => ({
		baseUrlType: NeritDocsApiEnums.NERIT_DOCS_API,
		url: `${BulkRequests.CONTROLLER_ROOT}/${BulkRequests.PUT_FINISH_BULK_OPERATION_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
		headers
	})

	static addSuccessRecord = (dto: BulkOperationUpdateRecordCountRequestDTO, headers?: RequestHeaderTP[]): RequestConfigTP => ({
		baseUrlType: NeritDocsApiEnums.NERIT_DOCS_API,
		url: `${BulkRequests.CONTROLLER_ROOT}/${BulkRequests.PUT_ADD_SUCCESS_RECORD_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
		headers
	})

	static addIgnoredRecord = (dto: BulkOperationLogUpdateRequestDTO, headers?: RequestHeaderTP[]): RequestConfigTP => ({
		baseUrlType: NeritDocsApiEnums.NERIT_DOCS_API,
		url: `${BulkRequests.CONTROLLER_ROOT}/${BulkRequests.PUT_ADD_IGNORED_RECORD_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
		headers
	})

	static addErrorRecord = (dto: BulkOperationLogUpdateRequestDTO, headers?: RequestHeaderTP[]): RequestConfigTP => ({
		baseUrlType: NeritDocsApiEnums.NERIT_DOCS_API,
		url: `${BulkRequests.CONTROLLER_ROOT}/${BulkRequests.PUT_ADD_ERROR_RECORD_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
		headers
	})



}
