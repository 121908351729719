import React from 'react'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { LoginFormCP } from 'modules/auth/components/user-login-form/LoginFormCP'
import { AuthLayoutCP } from 'modules/auth/components/auth-layout/AuthLayoutCP'
import { PrivateRouter } from 'app/routes/PrivateRouter'
import { AuthUtils } from 'common/framework-ui/utils/AuthUtils'
import { PublicRouter } from 'app/routes/PublicRouter'
import { RedirectCP } from 'submodules/nerit-framework-ui/common/router/components/redirect/RedirectCP'

/**
 * TELA: Login.
 */
export function LoginScreen(): JSX.Element {

    if (AuthUtils.isUserLogged())
        return <RedirectCP path={PrivateRouter.USER_COURSES_ROOT}/>

    return (
        <AuthLayoutCP
            content={
                <LoginFormCP
                    onLoginSuccess={() => NeritFrameworkRoutingHelper.historyReplace(PrivateRouter.USER_COURSES_ROOT)}
                    onRecoverPassword={() => NeritFrameworkRoutingHelper.historyPush(PublicRouter.RECOVER_PWD)}
                />
            }
        />
    )
}
