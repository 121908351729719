import React, { useState } from 'react'
import { InputCP } from 'common/framework-ui/components/form-fields/input/InputCP'
import { ColumnCP } from 'common/framework-ui/components/grid/ColumnCP'
import { RowCP } from 'common/framework-ui/components/grid/RowCP'
import { FormModel } from 'common/framework-ui/form-state-manager/FormModel'
import { IFormStateManager } from 'common/framework-ui/form-state-manager/IFormStateManager'
import { ZipCodeSearchInputCP } from 'common/framework-ui/components/zip-code-search-input/ZipCodeSearchInputCP'
import styled from 'styled-components'
import { AddressSaveRequestDTO } from 'submodules/neriteduca-sdk/services/addresses/dtos/requests/AddressSaveRequestDTO'

interface IAddressPanelCPProps<FModelTP extends FormModel> {
    formStateManager: IFormStateManager<FModelTP>
}

/**
 * Campos de endereco para paineis de formularios de cadastro.
 */
export function AddressPanelCP<FModelTP extends (FormModel & AddressSaveRequestDTO)>(props: IAddressPanelCPProps<FModelTP>): JSX.Element {

    const [isAwaiting, setIsAwaiting] = useState<boolean>(false)

    return (
        <AddressPanelSCP>

            <RowCP spaceBetweenCols={16}>
                <ColumnCP size={12}>
                    <ZipCodeSearchInputCP
                        formStateManager={props.formStateManager}
                        fieldName={'zipCode' as keyof FModelTP}
                        onSearchStart={() => setIsAwaiting(true)}
                        onSearchEnd={() => setIsAwaiting(false)}
                    />
                </ColumnCP>
            </RowCP>
            <RowCP spaceBetweenCols={16}>
                <ColumnCP size={14}>
                    <InputCP
                        label={'Rua'}
                        loading={isAwaiting}
                        disabled={isAwaiting}
                        formStateManager={props.formStateManager}
                        fieldName={'street'}
                    />
                </ColumnCP>

                <ColumnCP size={4}>
                    <InputCP
                        label={'No.'}
                        loading={isAwaiting}
                        disabled={isAwaiting}
                        formStateManager={props.formStateManager}
                        fieldName={'number'}
                    />
                </ColumnCP>

                <ColumnCP size={6}>
                    <InputCP
                        label={'Comp.'}
                        loading={isAwaiting}
                        disabled={isAwaiting}
                        formStateManager={props.formStateManager}
                        fieldName={'complement'}
                    />
                </ColumnCP>

            </RowCP>
            <RowCP spaceBetweenCols={16}>

                <ColumnCP size={10}>
                    <InputCP
                        label={'Bairro'}
                        loading={isAwaiting}
                        disabled={isAwaiting}
                        formStateManager={props.formStateManager}
                        fieldName={'neighborhood'}
                    />
                </ColumnCP>

                <ColumnCP size={10}>
                    <InputCP
                        label={'Cidade'}
                        loading={isAwaiting}
                        disabled={isAwaiting}
                        formStateManager={props.formStateManager}
                        fieldName={'city'}
                    />
                </ColumnCP>

                <ColumnCP size={4}>
                    <InputCP
                        label={'UF'}
                        loading={isAwaiting}
                        disabled={isAwaiting}
                        formStateManager={props.formStateManager}
                        fieldName={'state'}
                    />
                </ColumnCP>

            </RowCP>
        </AddressPanelSCP>
    )
}

const AddressPanelSCP = styled.div`
    display: flex;
    flex-wrap: inherit;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 10px;
    .ant-input {
        padding: inherit !important;
    }
`
