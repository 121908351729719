import React from 'react'
import 'video.js/dist/video-js.css'
import { PrivateRouter } from 'app/routes/PrivateRouter'
import { FlexCP } from 'common/framework-ui/components/flex/FlexCP'
import { ButtonCP } from 'common/framework-ui/components/button/ButtonCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'

interface ICPProps {
    accountCode: number
    productReleaseCode: number
    nextContentCode?: number
    previousContentCode?: number
    hideLabel?: boolean
}

/**
 * Botoes de Navegacao do conteudo.
 */
export function ButtonsClassContentNavCP(props: ICPProps): JSX.Element {

    const contentRootUrl = `${PrivateRouter.USER_COURSE_EAD_ROOT}/${props.accountCode}/${props.productReleaseCode}`

    // Se nao tem proximo conteudo, é o ultimo. Mostra botao de certificado
    if (!props.nextContentCode) {
        return (
            <ButtonCP
                icon={'file-done'}
                onClick={() => NeritFrameworkRoutingHelper.historyPush(PrivateRouter.USER_CERTIFICATES_ROOT)}
            >
                Ver Certificado
            </ButtonCP>
        )
    }

    return (
        <FlexCP>
            {
                props.previousContentCode &&
                <ButtonCP
                    icon={'left-circle'}
                    onClick={() => NeritFrameworkRoutingHelper.historyPush(`${contentRootUrl}/${props.previousContentCode}`)}
                >
                    {!props.hideLabel && 'Anterior'}
                </ButtonCP>
            }
            <ButtonCP
                icon={'right-circle'}
                marginLeft={5}
                onClick={() => NeritFrameworkRoutingHelper.historyPush(`${contentRootUrl}/${props.nextContentCode}`)}
            >
                {!props.hideLabel && 'Próximo'}
            </ButtonCP>
        </FlexCP>
    )
}
