import React from 'react'
import { Helmet } from 'react-helmet'

interface IHelmetCPProps {
    title: string
}

/**
 * Componente helmet
 *
 * @author renato.assis
 */
export function HelmetCP(props: IHelmetCPProps): JSX.Element {
    return (
        <Helmet>
            <title>
                {`${props.title} | NeritEduca - Portal`}
            </title>
            <link
                rel={'icon'}
                type={'image/png'}
                sizes={'95x86'}
                href={require('common/assets/logo-sistema-icon.png')}
            />
        </Helmet>

    )
}
