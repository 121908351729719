import React from 'react'
import styled from 'styled-components'
import { UploadStateTP } from 'submodules/nerit-micro-services-ui/import/components/modal-import/inner/UploadStateTP'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'

interface IImportAlertICPProps {
    uploadState: UploadStateTP
    onCheckErrorsClick: () => void
}

/**
 * Retorno visual apos conclusao de 01 tentativa de importacao de arquivo no modal de importacao de arquivo lista de cadastros de pessoa.
 */
export function AlertImportICP(props: IImportAlertICPProps): JSX.Element {

    switch (props.uploadState) {

        case 'success':
            return (
                <AlertCP
                    message={'Yes!'}
                    type={'success'}
                    description={'Já validamos e está tudo certinho com a planilha. Iniciamos o processo de importação. Você pode acompanhar o andamento no painel abaixo.'}
                />
            )

        case 'failure':
            return (
                <AlertCP
                    message={'Ops!'}
                    type={'warning'}
                    description={
                        <FailureAlertSCP>
                            <p>
                                Encontramos inconsistências na planilha. Favor corrigí-las e fazer upload novamente.
                            </p>

                            <ButtonCP type={'link'} onClick={props.onCheckErrorsClick}>
                                <TextCP size={'small'} text={'Ver inconsistências'}/>
                            </ButtonCP>
                        </FailureAlertSCP>
                    }
                />
            )

        case 'error':
        default:
            return (
                <AlertCP
                    message={'Ops!'}
                    type={'error'}
                    description={'Falha ao tentar validar arquivo. Por favor, tente novamente.'}
                />
            )
    }
}

const FailureAlertSCP = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`
