import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NeritEducaSystemApiEnums } from 'submodules/neriteduca-sdk/services/common/NeritEducaSystemApiEnums'
import { FormAnswersSaveRequestDTO } from 'submodules/neriteduca-sdk/services/ead/ead-forms/dtos/requests/FormAnswersSaveRequestDTO'

export class EadFormRequests {

    public static CONTROLLER_ROOT = 'forms'
    public static SAVE_FORM_ANSWER_EP = 'save-answer'

    static saveAnswers = (dto: FormAnswersSaveRequestDTO): RequestConfigTP => ({
        baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
        url: `${EadFormRequests.CONTROLLER_ROOT}/${EadFormRequests.SAVE_FORM_ANSWER_EP}`,
        method: HttpMethodEnum.POST,
        params: dto
    })
}
