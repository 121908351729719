import { Icon } from 'antd'
import React from 'react'
import { IIconProps } from 'common/framework-ui/components/icons/inner/IIconProps'

/**
 * ICONE: Cadeado
 *
 * @author Stella
 */
export const LockIconCP = (props: IIconProps): JSX.Element => {

    return (
        <Icon
            type={'lock'}
            style={{
                fill: props.color ?? 'inherit',
                color: props.color ?? 'inherit',
                fontSize: props.size ?? '20px',
                ...props.style
            }}
        />
    )
}
