import React, { useState } from 'react'
import { CollapseForumsCP } from 'modules/user/components/collapse-forums/CollapseForumsCP'
import { ForumPostsCP } from 'modules/user/components/forum-posts/ForumPostsCP'

interface ICPProps {
    productReleaseCode: number
}

/**
 * Aba de Forum.
 */
export function UserCourseForumTabICP(props: ICPProps): JSX.Element {

    const [selectedForumTopicCode, setSelectedForumTopicCode] = useState<number>()

    return (
        <>
            {
                !!selectedForumTopicCode
                    ?
                    <ForumPostsCP
                        forumTopicCode={selectedForumTopicCode}
                        onGoBack={() => setSelectedForumTopicCode(undefined)}
                    />
                    :
                    <CollapseForumsCP
                        productReleaseCode={props.productReleaseCode}
                        onSelectTopic={setSelectedForumTopicCode}
                    />
            }
        </>
    )
}
