import { IThemeProject } from 'config/theme/project/IThemeProject'

export const ThemeProjectRL: IThemeProject = {
    headerFontColor: '#23A7AB',
    collapsedMenuButtonColor: '#23A7AB',

    primaryMenuColor: '#FFF',
    primaryMenuItemColor: '#23A7AB',

    secondaryMenuColor: '#FFF',
    secondaryBorderColor: '#f3f3f3',
    secondaryMenuItemColor: '#23A7AB',
    secondaryMenuTitleColor: '#145a5d',

    headerBackgroundColor: '#FFF',
    titleBarColor: '#23A7AB',

    checkedBackgroundColor: '#f3b900',
    checkedColor: '#000',

    loginCoverColor: '#f3b900',

    widgetFooterColor: '',
    serviceWorkerBarColor: '#23A7AB',
    serviceWorkerFontColor: '#000',
    primaryButtonBackgroundColor: '',
    primaryButtonFontColor: ''
}
