import React from 'react'
import styled from 'styled-components'
import { UserPurchaseCoverICP } from 'modules/user/components/card-user-purchase/inner/UserPurchaseCoverICP'
import { TextCP } from 'common/framework-ui/components/text/TextCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PrivateRouter } from 'app/routes/PrivateRouter'
import { UserPurchaseResponseDTO } from 'submodules/neriteduca-sdk/services/sales/invoice-account/dtos/response/UserPurchaseResponseDTO'
import { InvoiceAccountStatusEnum, InvoiceAccountStatusLabelEnum } from 'submodules/neriteduca-sdk/services/sales/old/invoices/enums/InvoiceAccountStatusEnum'
import { TagCP } from 'common/framework-ui/components/tag/TagCP'
import { FlexCP } from 'common/framework-ui/components/flex/FlexCP'

interface ICPProps {
    purchase: UserPurchaseResponseDTO
}

/**
 * Card de um curso comprado pelo usuario.
 */
export function CardUserPurchaseCP(props: ICPProps): JSX.Element {

    const isActive: boolean = [InvoiceAccountStatusEnum.OK, InvoiceAccountStatusEnum.CONCLUDED].includes(props.purchase.invoiceAccountStatus)

    return (
        <CourseWrapperSCP
            onClick={() => isActive || true ? NeritFrameworkRoutingHelper.historyPush(`${PrivateRouter.USER_COURSES_ROOT}/${props.purchase.accountCode}/${props.purchase.productRelease.code}`) : undefined}
        >

            <UserPurchaseCoverICP purchase={props.purchase}/>

            <CourseDescriptionSCP
                isActive={isActive}
            >
                <h3>{props.purchase.productRelease.name}</h3>
                <TextCP fontStyle={'italic'} text={`Curso ${props.purchase.classType}`}/>

                {
                    !isActive &&
                    <FlexCP justifyContent={'flex-end'} marginTop={10}>
                        <TagCP
                            content={InvoiceAccountStatusLabelEnum[props.purchase.invoiceAccountStatus]}
                            color={'red'}
                        />
                    </FlexCP>
                }
            </CourseDescriptionSCP>
        </CourseWrapperSCP>
    )
}

const CourseDescriptionSCP = styled.div<{ isActive: boolean }>`

  cursor: ${props => props.isActive ? 'pointer' : 'not-allowed'};
  padding: 1rem .5rem;
  flex: 1;

  h3 {
    font-size: 0.9rem;
    color: var(--primary-color);
    line-height: 1rem;
  }
`

const CourseWrapperSCP = styled.div`

  height: 16rem;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 0.5rem;
  position: relative;

  margin-bottom: 10px;
`
