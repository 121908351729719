import React from 'react'

interface ICPProps {
    html: string
}

/**
 * HTML puro.
 */
export function HtmlRawCP(props: ICPProps): JSX.Element {

    return (
        <div dangerouslySetInnerHTML={{__html: props.html}}/>
    )
}
