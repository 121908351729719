import React from 'react'
import { Tag } from 'antd'
import styled from 'styled-components'
import { TagCPColorTP } from 'common/framework-ui/components/tag/inner/TagCPColorTP'
import { FontStyleTP } from 'common/framework-ui/types/FontStyleTP'
import { TagCpUtils } from 'common/framework-ui/components/tag/inner/TagCpUtils'

const CLASS_SMALL = 'tag_small'

interface ITagCPProps {
    content: string | JSX.Element
    color?: TagCPColorTP | string
    onClose?: () => void
    small?: boolean
    fontStyle?: FontStyleTP
    minWidth?: number
    onClick?: () => void
}

/**
 * Componente para visualizacao de texto em forma de 'tag'.
 */
export function TagCP(props: ITagCPProps): JSX.Element {
    return (
        <WrapperSCP
            className={!!props.small ? CLASS_SMALL : ''}
            fontStyle={props.fontStyle ?? 'normal'}
            minWidth={props.minWidth}
            isClickable={!!props.onClick}
        >
            <Tag
                closable={!!props.onClose}
                onClose={props.onClose}
                color={TagCpUtils.getParsedColor(props.color)}
                onClick={() => props.onClick?.()}
            >
                {props.content}
            </Tag>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<{ fontStyle: FontStyleTP, minWidth?: number, isClickable: boolean }>`

    display: flex;
    align-items: center;
    text-align: center;

    .ant-tag {
        font-style: ${props => props.fontStyle};
        min-width: ${props => (!!props.minWidth ? props.minWidth : 0)}px;
        cursor: ${props => props.isClickable ? 'pointer' : ''};
    }

    &.${CLASS_SMALL} {
        .ant-tag {
            font-size: .65rem;
            padding: 0 4px;
            margin: 1px 2px;
            line-height: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 18px;

            svg {
                height: .7rem;
                width: .7rem;
            }
        }
    }
`
