export const EadCourseViewScreenUtils = {

    showOrHideMainMenu(action: 'show' | 'hide'): void {

        const mainMenu = window.document.getElementById('main-menu-id')
        if (!mainMenu)
            return

        mainMenu.style.display = action === 'hide' ? 'none' : 'block';
    }

}
