import React from 'react'
import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { HelmetCP } from 'common/framework-ui/components/helmet/HelmetCP'

interface ScpProps {
    backgroundColor?: string
}

interface IPageHeaderCPProps extends ScpProps {
    title: string
    children?: JSX.Element
}

/**
 * Titulo de uma pagina.
 */
export function PageHeaderCP(props: IPageHeaderCPProps) {

    return (
        <HeaderContainerSCP
            backgroundColor={props.backgroundColor}
        >
            <HelmetCP
                title={props.title}
            />
            <TitleContainerSCP>
                <TitleSCP>
                    {props.title}
                </TitleSCP>
            </TitleContainerSCP>
            <ChildrenWrapperSCP>
                {props.children}
            </ChildrenWrapperSCP>
        </HeaderContainerSCP>
    )
}

const HeaderContainerSCP = styled.div<ScpProps>`
    height: 65px;
    background: ${props => props.backgroundColor ?? props.theme.primaryColor};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
`

const TitleContainerSCP = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    
    &::before{
        content: '';
        position: absolute;
        background: ${props => props.theme.secondaryColor};
        border-radius: 8px 8px 0 0;
        width: 50px;
        height: 4px;
        bottom: 0;
        left: 0;
    }
`

const TitleSCP = styled.h3`
  font-weight: 600;
  font-size: 1.3rem;
  color: white;
`

const ChildrenWrapperSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    
    .ant-select{
        margin-left: 20px;
    }
`
