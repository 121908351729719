export enum ImportUpdateBehaviorEnum {
    SKIP = 'SKIP',
    UPDATE_BLANK = 'UPDATE_BLANK',
    OVERWRITE = 'OVERWRITE'
}

export enum ImportUpdateBehaviorLabelEnum {
    SKIP = 'Pular registros',
    UPDATE_BLANK = 'Atualizar quando vazio',
    OVERWRITE = 'Atualizar campos'
}
