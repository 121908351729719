import React from 'react'
import styled from 'styled-components'

interface ICPProps {
    text: string
    isViewed: boolean
}

export function ClassContentNameStyledByViewCP(props: ICPProps): JSX.Element {

    return (
        <ClassContentNameSCP
            isViewed={props.isViewed}
        >
            { props.text }
        </ClassContentNameSCP>
    )
}

const ClassContentNameSCP = styled.span<{ isViewed: boolean }>`
  color: ${props => props.isViewed ? '#adadad' : undefined};
  text-decoration: ${props => props.isViewed ? 'line-through' : undefined};
`
