import { SelectFullOptionTP } from 'common/framework-ui/components/form-fields/select/inner/types/SelectFullOptionTP'
import { StringUtils } from 'common/framework-ui/utils/StringUtils'
import { INameAndCodeResponseDTO } from 'common/framework-ui/dtos/responses/INameAndCodeResponseDTO'
import { TransientValueTrimmerConfigTP } from 'common/framework-ui/transient-value-trimmer/types/TransientValueTrimmerConfigTP'

type SetSearchStringTP = (searchString: string) => void

/**
 * UTILITARIOS auxiliares para componente generico de automcomplete.
 * @see AutocompletePickerCP
 */
export const AutocompletePickerUtils = {

    getSelectOptions(options: INameAndCodeResponseDTO[]): SelectFullOptionTP[] {
        return options.map((option, i) => ({
            key: `autocomplete-${i}-${StringUtils.getSlugStyleString(option.name)}`,
            value: option.code,
            label: option.name
        }))
    },

    getUniqueOptionsList(items: SelectFullOptionTP[]): SelectFullOptionTP[] {
        const uniqueItemsMap = new Map<number | string, SelectFullOptionTP>()
        items.forEach(item => uniqueItemsMap.set(item.value, item))
        const uniqueList: SelectFullOptionTP[] = []
        uniqueItemsMap.forEach(mappedOption => uniqueList.push(mappedOption))
        return uniqueList
    },

    getSearchStrTrimmerConfig(setSearchString: SetSearchStringTP): TransientValueTrimmerConfigTP<string> {
        return {
            delay: 350,
            onFinish: setSearchString,
            debugType: 'disabled',
            debugName: 'auto-complete-picker',
        }
    }
}
