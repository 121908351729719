import React, { useEffect, useState } from 'react'
import { BasicModalCPProps } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { ModalImportCP } from 'submodules/nerit-micro-services-ui/import/components/modal-import/ModalImportCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { SelectImportBehaviorCP } from 'submodules/nerit-micro-services-ui/import/components/select-import-behavior/SelectImportBehaviorCP'
import { InvoiceAccountsRequests } from 'submodules/neriteduca-sdk/services/sales/invoice-account/InvoiceAccountsRequests'
import { InvoiceAccountAddGuestsImportRequestDTO } from 'submodules/neriteduca-sdk/services/sales/invoice-account/dtos/request/InvoiceAccountAddGuestsImportRequestDTO'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'

/**
 */
export function ModalImportSubscriptionsCP(props: BasicModalCPProps): JSX.Element {

    const [importDto, setImportDto] = useState<InvoiceAccountAddGuestsImportRequestDTO>({} as any)

    useEffect(init, [props.visible])

    /**
     * Inicializa.
     */
    function init() {
        setImportDto({} as any)
    }

    return (
        <ModalImportCP
            visible={props.visible}
            onCancel={props.onCancel}
            onSave={props.onSave}
            requestConfigTP={(file) => {

                if (!importDto.updateBehavior || !importDto.saleCode) {
                    NotificationHelper.error('Ops', 'Informe todos os dados para continuar')
                    return
                }

                const formData = new FormData()
                formData.append('file', file)
                formData.append('data', JSON.stringify(importDto))

                return InvoiceAccountsRequests.addGuests(formData)
            }}
        >

            <SelectImportBehaviorCP
                onChange={(behavior) => {
                    importDto.updateBehavior = behavior
                    setImportDto({ ...importDto })
                }}
                selectedValue={importDto.updateBehavior}
                disabled={false}
            />

            <InputCP
                label={'Código da Venda'}
                required={true}
                value={importDto.saleCode}
                type={'number'}
                width={200}
                onChange={(saleCode) => {
                    importDto.saleCode = saleCode
                    setImportDto({ ...importDto })
                }}
            />

        </ModalImportCP>
    )
}
