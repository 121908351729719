import React from 'react'
import { Collapse } from 'antd'
import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { CollapseCPPanelTP } from 'common/framework-ui/components/collapse/inner/CollapseCPPanelTP'
import { SystemUtils } from 'common/framework-ui/utils/SystemUtils'
import { EmptyCP } from 'common/framework-ui/components/empty/EmptyCP'
import { LoadingCP } from 'common/framework-ui/components/loading/LoadingCP'

type _ScpPropsTP = {
    overflow?: string,
}

interface ICollapseCPProps extends _ScpPropsTP {
    panels?: CollapseCPPanelTP[]
    defaultPanelKeys?: string[]
    bordered?: boolean
    bgColor?: string
    loading?: boolean
    lockPanels?: boolean
    accordion?: boolean
}

/**
 * Painel com blocos de conteudo colapsavel.
 */
export function CollapseCP(props: ICollapseCPProps): JSX.Element {

    if (props.loading)
        return <LoadingCP show={props.loading}/>

    return (
        <WrapperSCP
            overflow={props.overflow}
        >
            {
                !!props.panels && !SystemUtils.isEmpty(props.panels)
                    ?
                    <Collapse
                        bordered={props.bordered ?? false}
                        defaultActiveKey={props.defaultPanelKeys ?? []}
                        style={{ backgroundColor: props.bgColor ?? 'transparent' }}
                        accordion={props.accordion}
                    >
                        {
                            props.panels.map((panel, i) => (
                                <Collapse.Panel
                                    key={panel.key ?? `panel=${i}`}
                                    header={panel.title}
                                    extra={panel.extra}
                                    disabled={!!props.lockPanels}
                                    showArrow={!props.lockPanels}
                                >
                                    {panel.content}
                                </Collapse.Panel>
                            ))
                        }
                    </Collapse>
                    :
                    <EmptyCP description={'Nenhum dado encontrado'}/>

            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<_ScpPropsTP>`

  width: 100%;
  .ant-collapse {
    border-color: #e0e0e0 !important;
  }
  
  .ant-collapse-content {
    border-color: #e0e0e0 !important;
    overflow: ${props => props.overflow};
  }

  .ant-collapse-item {
    margin: 0;
    border-color: #e0e0e0 !important;

    .ant-collapse-header {
      display: flex;
      justify-content: space-between;
    }
  }

  .ant-collapse-item-disabled {
    .ant-collapse-header {
      cursor: auto !important;
    }
  }
`
