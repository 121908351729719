import React, { useState } from 'react'
import * as _ from 'lodash'
import { PersonImportValidationErrorsTP } from 'submodules/nerit-micro-services-ui/import/components/modal-import/inner/PersonImportValidationErrorsTP'
import { PersonImportValidationErrorsItemTP } from 'submodules/nerit-micro-services-ui/import/components/table-import-validation-errors/inner/PersonImportValidationErrorsItemTP'
import { PERSON_IMPORT_VALIDATION_ERRORS_COLUMNS } from 'submodules/nerit-micro-services-ui/import/components/table-import-validation-errors/inner/PersonImportValidationErrorsColumns'
import { TableCP } from 'submodules/nerit-framework-ui/common/components/table/TableCP'

const MAX_LINES = 4

interface IPersonImportValidationErrorsTableCPProps {
    errors: PersonImportValidationErrorsTP
}

/**
 * Listagem de falhas de validacao em arquivos .csv para importacao de pessoas.
 */
export function TableImportValidationErrorsCP(props: IPersonImportValidationErrorsTableCPProps): JSX.Element {

    const [currentPage, setCurrentPage] = useState<number>(1)

    function getTableData(): PersonImportValidationErrorsItemTP[] {

        if (!props.errors)
            return []

        const errorLines = Object.keys(props.errors)
        const errorsToReturn: PersonImportValidationErrorsItemTP[] = []

        errorLines.forEach(line => {

            if (_.isEmpty(props.errors[line]))
                return

            errorsToReturn.push({
                line: +line,
                errors: props.errors[line]
            })
        })

        return errorsToReturn
    }

    return (
        <TableCP<PersonImportValidationErrorsItemTP>
            columns={PERSON_IMPORT_VALIDATION_ERRORS_COLUMNS}
            bordered={true}
            data={getTableData()}
            rowKeyGetter={lineErrors => lineErrors.line.toString()}
            onSortOrChangePagination={(sortAndPage) => setCurrentPage(sortAndPage?.pagination?.current ?? 1)}
            sortAndPage={{
                pagination: {
                    current: currentPage,
                    pageSize: MAX_LINES,
                    total: Object.keys(props.errors).length
                }
            }}
        />
    )
}
