import { faCog, faGraduationCap, faHome, faUserEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/framework-ui/components/icons/FontAwsomeIconCP'
import React from 'react'
import { PrivateRouter } from 'app/routes/PrivateRouter'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { MenuItemTP } from 'submodules/nerit-framework-ui/common/components/menu/menu/inner/MenuItemTP'
import { AppStateUtils } from 'common/framework-ui/utils/AppStateUtils'

/**
 * UTILITARIOS do menu principal do sistema
 */
export const UserMenuUtils = {

    getMenuOptionsTop(): MenuItemTP[] {
        return [
            {
                route: PrivateRouter.USER_COURSES_ROOT,
                title: 'Página Inicial',
                icon: <FontAwsomeIconCP type={faHome} color={ThemeProject.primaryMenuItemColor} style={{ fontSize: '20px' }}/>,
                enabled: true
            },
            {
                route: PrivateRouter.USER_CERTIFICATES_ROOT,
                title: 'Meus Certificados',
                icon: <FontAwsomeIconCP type={faGraduationCap} color={ThemeProject.primaryMenuItemColor} style={{ fontSize: '20px' }}/>,
                enabled: true
            },
        ]
    },

    getMenuOptionsBottom(): MenuItemTP[] {
        return [
            {
                route: PrivateRouter.ADMIN_ROOT,
                title: 'Administrativo',
                icon: <FontAwsomeIconCP type={faCog} color={ThemeProject.primaryMenuItemColor} style={{ fontSize: '20px' }}/>,
                enabled: AppStateUtils.getLoggedUserData()!.user.isAdmin
            },
            {
                route: PrivateRouter.PROFILE,
                title: 'Meu Perfil',
                icon: <FontAwsomeIconCP type={faUserEdit} color={ThemeProject.primaryMenuItemColor} style={{ fontSize: '20px' }}/>,
                enabled: true
            },
        ]
    },

}
