import React from 'react'
import { IAutocompleteCommonProps } from 'common/framework-ui/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { AutocompletePickerCP } from 'common/framework-ui/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { FormModel } from 'common/framework-ui/form-state-manager/FormModel'
import { INameAndCodeResponseDTO } from 'common/framework-ui/dtos/responses/INameAndCodeResponseDTO'
import { TeacherRequests } from 'submodules/neriteduca-sdk/services/product/teacher/TeacherRequests'

interface IPersonAutoCompletePickerCPProps<FModelTP extends FormModel = any> extends IAutocompleteCommonProps<INameAndCodeResponseDTO, FModelTP> {
    label: string
    productReleaseCode: number
}

/**
 * Seletor de professores de um curso.
 */
export function AutocompleteTeacherCP<FModelTP extends FormModel = any>(props: IPersonAutoCompletePickerCPProps<FModelTP>): JSX.Element {

    return (
        <AutocompletePickerCP<INameAndCodeResponseDTO, FModelTP>
            requestConfigGetter={() => TeacherRequests.getNames(props.productReleaseCode)}
            showOptionsOnLoad={true}
            // NOTE: Usando operador 'spread' excepcionalmente para garantir compatibilidade com props extendidas de outro componente
            {...props/* eslint-disable-line react/jsx-props-no-spreading */}
        />
    )
}
