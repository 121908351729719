import { Layout } from 'antd'
import { useScreenSize } from 'common/framework-ui/responsiveness/use-screen-size/UseScreenSize'
import React from 'react'
import styled from 'styled-components'

type _AntPropsTP = {
    style?: React.CSSProperties,
}

interface IContentCPProps extends React.PropsWithChildren<_AntPropsTP> {}

/**
 * Area de conteúdo principal dentro de 01 layout.
 */
export function ContentCP(props: IContentCPProps): JSX.Element {

    const screenSize = useScreenSize()
    return (
        <WrapperSCP isSmallScreen={screenSize.smd} data-tour={'content-cp'}>
            <Layout.Content style={props.style}>
                { props.children }
            </Layout.Content>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<{ isSmallScreen: boolean }>`

  height: 100%;
  flex: 1;
  min-width: 0;
  ${props => (props.isSmallScreen ? 'width: 100%;' : '')}

  .ant-layout-content {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    
    overflow-y: scroll;
  }

`
