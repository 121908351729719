import { MenuLogoCP } from 'common/framework-ui/components/menu/menu-logo/MenuLogoCP'
import { AppStateUtils } from 'common/framework-ui/utils/AppStateUtils'
import React from 'react'
import { AuthActions } from 'modules/auth/AuthActions'
import { ProfileMenuItemICP } from 'modules/user/components/user-main-menu/inner/ProfileMenuItemICP'
import { UserMenuUtils } from 'modules/user/components/user-main-menu/inner/UserMenuUtils'
import { MenuUserMainCP } from 'submodules/nerit-framework-ui/common/components/menu/menu-user-main/MenuUserMainCP'
import styled from 'styled-components'

interface ICPProps {
    onSelectMenuItem: () => void
}

/**
 * Menu lateral do sistema.
 */
export function UserMainMenuCP(props: ICPProps): JSX.Element {

    return (
        <MenuUserMainCP
            onSelectMenuItem={props.onSelectMenuItem}
            menuTop={{
                options: UserMenuUtils.getMenuOptionsTop(),
                topContent: (
                    <>
                        <MenuLogoCP/>
                        <MenuDividerSCP/>
                    </>
                )
            }}
            menuBottom={{
                options: UserMenuUtils.getMenuOptionsBottom(),
                bottomContent: (
                    <>
                        <MenuDividerSCP/>
                        <ProfileMenuItemICP
                            profileImage={AppStateUtils.getLoggedUserData()?.user.photoUrl}
                            optionTitle={AppStateUtils.getLoggedUserData()?.user.name ?? 'Usuário'}
                            profileOptions={[
                                {
                                    text: 'Sair',
                                    action: (): void => {
                                        AuthActions.logout()
                                    }
                                }
                            ]}
                        />
                    </>
                )
            }}
        />
    )
}

const MenuDividerSCP = styled.div`
    width: 67px;
    height: 2px;
    background: rgba(255,255,255,0.5);
    margin-bottom: 15px;
`
