import React from 'react'
import * as _ from 'lodash'
import { LayoutCP } from 'common/framework-ui/components/screen-layout/layout/LayoutCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { AppStateUtils } from 'common/framework-ui/utils/AppStateUtils'
import { ContentAuthCP } from 'modules/auth/components/content-auth/ContentAuthCP'
import { useScreenSize } from 'common/framework-ui/responsiveness/use-screen-size/UseScreenSize'
import { AuthScreenCoverCP } from 'modules/auth/components/auth-screen-cover/AuthScreenCoverCP'

interface IContentAuthCPProps {
    content: JSX.Element
}

/**
 * Layout das telas de autenticacao.
 */
export function AuthLayoutCP(props: IContentAuthCPProps): JSX.Element | null {

    const loggedUserData = AppStateUtils.getLoggedUserData()
    const screenSize = useScreenSize()

    if (!_.isEmpty(loggedUserData?.user)) {
        NeritFrameworkRoutingHelper.historyReplace('default')
        return null
    }

    return (
        <LayoutWrapperSCP>
            <LayoutCP
                mainLayout={true}
                sider={screenSize.smd ? undefined : <AuthScreenCoverCP/>}
                content={
                    <ContentAuthCP>
                        {props.content}
                    </ContentAuthCP>
                }
            />
        </LayoutWrapperSCP>
    )
}

const LayoutWrapperSCP = styled.div`
    .ant-layout {
	    background: #fff;
	}
`
