import { IconICP } from 'common/framework-ui/components/icons/inner/IconICP'
import React from 'react'

import { IIconProps } from 'common/framework-ui/components/icons/inner/IIconProps'

/**
 * ICONE: Upload com nuvem
 */
export const IconUploadCloudCP = (props: IIconProps): JSX.Element => {
    return <IconICP iconName={'cloud-upload'} {...props}/> // eslint-disable-line react/jsx-props-no-spreading
}
