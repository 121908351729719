import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { ReduxHelper } from 'common/framework-ui/redux/helpers/ReduxHelper'
import { RequestHelper as RequestHelperOLD } from 'common/framework-ui/request-manager/RequestHelper'
import { AuthUtils } from 'common/framework-ui/utils/AuthUtils'
import { SchemaResponseDTO } from 'submodules/neriteduca-sdk/services/sys-admin/schema/dtos/response/SchemaResponseDTO'
import { RequestHelper } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import { AuthResponseDTO } from 'submodules/neriteduca-sdk/services/auth/authenticate/dtos/response/AuthResponseDTO'
import { PublicRouter } from 'app/routes/PublicRouter'

/**
 * Encapsula definicao de acoes envolvendo estado global (redux) relativas ao modulo de autenticacao.
 */
export class AuthActions {

    static readonly TYPE_UPDATE_DOMAIN = 'update_domain'
    static readonly TYPE_UPDATE_USER = 'update_user'

    /** Encapsula procedimento completo de 'logar' 01 usuario do sistama. */
    static login(auth: AuthResponseDTO): void {

        // Define usuario logado
        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_USER,
            payload: auth,
        })

        this.refreshLoginData(auth.token)
    }

    /** Atualiza dados do cliente (schema). */
    static setDomain(schema: SchemaResponseDTO, mktToken?: string): void {

        RequestHelperOLD.addDefaultHeader('schema', schema.slug)
        RequestHelper.addDefaultHeader('schema', schema.slug)

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_DOMAIN,
            payload: { schema, mktToken }
        })

    }

    /** Encapsula procedimento completo de 'deslogar'. */
    static logout(): void {

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_USER,
            payload: null,
        })

        this.refreshLoginData(null)
        NeritFrameworkRoutingHelper.historyReplace(PublicRouter.USER_LOGIN)
    }

    /**
     * Atualiza propriedades estaticas que guardam o token de autenticacao do usuario atual sincronizando-as
     * com o conteudo mantido no estado global da aplicacao (redux).
     */
    static refreshLoginData(token: string | null): void {

        if (!!token) {
            RequestHelperOLD.addDefaultHeader('Authorization', AuthUtils.getBearerAuthHeaderValue(token))
            RequestHelper.addDefaultHeader('Authorization', AuthUtils.getBearerAuthHeaderValue(token))
        }
    }
}
