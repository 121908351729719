import React, { useEffect, useState } from 'react'
import { useRequest } from 'common/framework-ui/request-manager/use-request/UseRequest'
import { RequestUtils } from 'common/framework-ui/request-manager/RequestUtils'
import { CollapseCP } from 'common/framework-ui/components/collapse/CollapseCP'
import { EadCourseRequests } from 'submodules/neriteduca-sdk/services/ead/ead-course/EadCourseRequests'
import { EadCourseStructureViewModeTP } from 'modules/ead/components/collapse-ead-course-structure/inner/EadCourseStructureViewModeTP'
import { EadCourseStructureCollapseItemICP } from 'modules/ead/components/collapse-ead-course-structure/inner/EadCourseStructureCollapseItemICP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { EadCourseModuleStructureResponseDTO } from 'submodules/neriteduca-sdk/services/ead/ead-course/dtos/responses/EadCourseModuleStructureResponseDTO'
import { ISearchResult } from 'submodules/nerit-framework-utils/sdk-utils/dtos/interfaces/ISearchResult'
import { ClassContentNameStyledByViewCP } from 'modules/ead/components/class-content-name-styled-by-viewed/ClassContentNameStyledByViewCP'
import { EadClassStructureRequestDTO } from 'submodules/neriteduca-sdk/services/ead/ead-course/dtos/requests/EadClassStructureRequestDTO'

interface ICPProps {
    accountCode: number
    productReleaseCode: number
    viewMode: EadCourseStructureViewModeTP
    backgroundColor?: string
    titleColor?: string
    bordered?: boolean
}

/**
 * Aba do conteudo EAD.
 */
export function CollapseEadCourseStructureCP(props: ICPProps): JSX.Element {

    const [eadCourseStructure, setEadCourseStructure] = useState<EadCourseModuleStructureResponseDTO[]>()
    const getEadCourseStructureRequest = useRequest<ISearchResult<EadCourseModuleStructureResponseDTO>>()
    useEffect(onGetEadCourseStructureRequestChange, [getEadCourseStructureRequest.isAwaiting])

    useEffect(init, [props.productReleaseCode])

    /**
     * Inicializa
     */
    function init(): void {

        const dto: EadClassStructureRequestDTO = {
            accountCode: props.accountCode,
            productReleaseCode: props.productReleaseCode
        }
        getEadCourseStructureRequest.runRequest(EadCourseRequests.getCourseStructure(dto))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetEadCourseStructureRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getEadCourseStructureRequest, 'Erro obter conteudo do curso'))
            return

        setEadCourseStructure(getEadCourseStructureRequest.responseData!.list)
    }

    return (
        <CollapseCP
            bordered={props.bordered ?? true}
            accordion={true}
            bgColor={props.backgroundColor ?? '#fff'}
            loading={getEadCourseStructureRequest.isAwaiting}
            defaultPanelKeys={!ArrayUtils.isEmpty(eadCourseStructure) ? [`module-${eadCourseStructure![0].eadModule.code}`] : undefined}
            panels={
                eadCourseStructure?.map((moduleStructure) => ({
                    key: `module-${moduleStructure.eadModule.code}`,
                    title: (
                        <ClassContentNameStyledByViewCP
                            isViewed={moduleStructure.isViewed}
                            text={moduleStructure.eadModule.name}
                        />
                    ),
                    content: (
                        <>
                            {
                                moduleStructure.eadClasses?.map((moduleClassStructure) => (
                                    <EadCourseStructureCollapseItemICP
                                        accountCode={props.accountCode}
                                        titleColor={props.titleColor}
                                        productReleaseCode={props.productReleaseCode}
                                        eadCourseModuleClassStructure={moduleClassStructure}
                                        viewMode={props.viewMode}
                                    />
                                ))
                            }
                        </>
                    )
                }))
            }
        />
    )
}
