import React from 'react'
import { Card } from 'antd'
import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'

interface ICardCPProps extends React.PropsWithChildren<{
    dataTour?: string,
    title?: string | JSX.Element,
    titleRight?: JSX.Element,
    isLoading?: boolean,
    loadingType?: 'skeleton' | 'loader' | undefined,
    margin?: string,
    minHeight?: number | string,
    innerSpacing?: 'normal' | 'small' | 'none',
    paddingTitle?: string,
    width?: string,
    overflow?: string,
    bordered?: boolean,
}> {}

/**
 * Container em forma de 'cartao' (wrapper para componente do antd).
 */
export function CardCP(props: ICardCPProps): JSX.Element {

    function getPadding(): string {
        if (!props.innerSpacing || props.innerSpacing === 'normal')
            return '15px 25px'
        if (props.innerSpacing === 'small')
            return '5px 10px'
        return '0'
    }

    function getMinHeight(): string {
        if (typeof props.minHeight === 'string')
            return props.minHeight
        if (typeof props.minHeight === 'number')
            return `${props.minHeight}px`
        return 'unset'
    }

    return (
        <WrapperSCP
            margin={props.margin ?? '20px'}
            minHeight={getMinHeight()}
            padding={getPadding()}
            data-tour={props.dataTour}
            paddingTitle={props.paddingTitle}
            width={props.width}
            overflow={props.overflow}
        >
            <LoadingOverlayCP show={(props.isLoading || false) && (!props.loadingType || props.loadingType === 'loader')}/>

            <Card
                title={props.title}
                loading={props.isLoading && props.loadingType === 'skeleton'}
                bordered={props.bordered}
                extra={props.titleRight}
            >
                {props.children}
            </Card>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<{ margin: string, minHeight: string, padding: string, paddingTitle?: string, width?: string, overflow?: string }>`

  position: relative;
  width: ${props => props.width};

  .ant-card {
    border-radius: 0.5rem;
    margin: ${props => props.margin};
    min-height: ${props => props.minHeight};
    overflow-x: ${props => props.overflow};
    overflow-y: hidden;

    .ant-card-body {
      padding: ${props => props.padding};
      height: 100%;
    }

    .ant-card-head {

      border-bottom: #f7f7f7 solid 1px;
      padding: ${props => (props.paddingTitle ? '0px' : '')} !important;
      min-height: ${props => (props.paddingTitle ? '0px' : '')} !important;

      :after {
        content: none;
      }

      .ant-card-head-wrapper {

        display: flex;
        flex-direction: row;
        justify-content: center;

        .ant-card-head-title {
          font-family: Montserrat, sans-serif;
          font-weight: 600;
          font-size: 14px;
          border: none;
          padding: ${props => props.paddingTitle ?? ''} !important;
        }
      }
    }
  }
`
