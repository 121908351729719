import React, { useState } from 'react'
import styled from 'styled-components'
import { TabItemTP } from 'common/framework-ui/components/tabs/inner/TabItemTP'
import { LoadingCP } from 'common/framework-ui/components/loading/LoadingCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface ICPProps {
    tabs: TabItemTP[]
    isLoading?: boolean
}

/**
 * Card de um curso comprado pelo usuario.
 */
export function TabsBigButtonCP(props: ICPProps): JSX.Element {

    const [activeTab, setActiveTab] = useState<TabItemTP>(props.tabs[0])

    return (
        <WrapperSCP>

            <div className='nrt-tab-container'>
                <div className={'tabs-container'}>
                    {
                        props.tabs.filter((tab) => !tab.hide).map((tab) => (
                                <button
                                    key={tab.key}
                                     onClick={() => {
                                         if (!tab.disabled)
                                             setActiveTab(tab)
                                     }}
                                    className={activeTab?.key === tab.key ? 'active' : ''}
                                    style={{ opacity: tab.disabled ? .4 : 1 }}
                                >
                                    { tab.title }
                                </button>
                            )
                        )
                    }
                </div>
            </div>

            <ContentSCP>
                {
                    props.isLoading
                        ?
                        <LoadingCP show={true}/>
                        :
                        activeTab?.content
                }
            </ContentSCP>
        </WrapperSCP>
    )
}

const ContentSCP = styled.div`
    margin: 20px;
`

const WrapperSCP = styled.div`
  
  .nrt-tab-container {

    display: flex;
    flex-direction: column;
    width:  100%;
    margin: 20px 0;
    
    .tabs-container {

      width: fit-content;
      border: 2px solid rgba(0,0,0,.05);
      align-self: center;
      background: rgba(50,50,50,.05);
      border-radius: 30rem;
      padding: .2rem;
      display: flex;
      justify-content: space-around;
      height: 4rem;
      .tab{
        margin-left: 0.6rem;
        @media screen and (max-width: 425px) {
          width: 95%;
          font-size: 1rem;
          margin-bottom: 0.8rem;
        }
      }
      @media screen and (max-width: 425px) {
        flex-direction: column;
        width: 100%;
        height: auto;
        align-items: center;
        padding: 0;
        background: none;
        border: none;
      }
    }

  }

  .nrt-tab-container button {
    font-size: .9rem;
    font-family: var(--title-font);
    font-weight: var(--title-font-heavy-weight);
    padding: .5rem 1rem;
    background: none;
    border: none;
    margin-left: 1.5rem;
    cursor: pointer;
    transition: all .3s ease;
    min-width: 6rem;
    border-radius: 40rem;
    color: ${ThemeAnt.primaryColor};
    
    @media screen and (max-width: 425px) {
      margin-left: 0px;
    }

    &:first-child {
      margin-left: 0;
    }

    &.active {
      opacity: 1;
      background: ${ThemeProject.titleBarColor};
      color:#fff;
    }

  }
`
