import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { ForumSaveRequestDTO } from 'submodules/neriteduca-sdk/services/ead/forum/dtos/request/ForumSaveRequestDTO'
import { ForumSearchRequestDTO } from 'submodules/neriteduca-sdk/services/ead/forum/dtos/request/ForumSearchRequestDTO'
import { ForumTopicSearchByNameRequestDTO } from 'submodules/neriteduca-sdk/services/ead/forum/dtos/request/ForumTopicSearchByNameRequestDTO'
import { NeritEducaSystemApiEnums } from 'submodules/neriteduca-sdk/services/common/NeritEducaSystemApiEnums'

export class ForumRequests {

    public static CONTROLLER_ROOT = 'forums'
    public static SEARCH_EP = 'search'
    public static FORUM_NAMES = 'names'
    public static TOPIC_NAMES = 'topics/names'
    public static GET_POSTS = ':forumTopicCode/posts'
    public static CREATE_POST_EP = ':forumTopicCode/posts'

    static getForums = (dto: ForumSearchRequestDTO): RequestConfigTP => ({
        baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
        url: `${ForumRequests.CONTROLLER_ROOT}/${ForumRequests.SEARCH_EP}`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static getForumNames = (dto: ForumSearchRequestDTO): RequestConfigTP => ({
        baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
        url: `${ForumRequests.CONTROLLER_ROOT}/${ForumRequests.FORUM_NAMES}`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static getForumTopics = (dto: ForumTopicSearchByNameRequestDTO): RequestConfigTP => ({
        baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
        url: `${ForumRequests.CONTROLLER_ROOT}/${ForumRequests.TOPIC_NAMES}`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static getPosts = (forumTopicCode: number): RequestConfigTP => ({
        baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
        url: `${ForumRequests.CONTROLLER_ROOT}/${ForumRequests.GET_POSTS.replace(':forumTopicCode', forumTopicCode.toString())}`,
        method: HttpMethodEnum.GET,
    })

    static createPost = (forumTopicCode: number, dto: ForumSaveRequestDTO): RequestConfigTP => ({
        baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
        url: `${ForumRequests.CONTROLLER_ROOT}/${ForumRequests.GET_POSTS.replace(':forumTopicCode', forumTopicCode.toString())}`,
        method: HttpMethodEnum.POST,
        params: dto,
    })
}
