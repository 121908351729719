import { MediaQueryFeatures } from 'react-responsive'
import { BreakpointsTP } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/BreakpointsTP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

/**
 * Parametros para definir faixa de tamanho de 01 breakpoint:
 * NOTE: Novo tipo contendo apenas props utilizadas mas, sem se desvincular do tipo original (garantindo seguranca na tipagem);
 */
type BreakpointBoundsTP = Partial<Pick<MediaQueryFeatures, 'minWidth' | 'maxWidth'>>

/**
 * Utilitarios para hook de escuta de tamanho de tela.
 */
export class UseScreenSizeUtils {

    /** Lista de breakpoints utilizados em ordem crescente. */
    private static _SIZE_SEQUENCE: Readonly<BreakpointsTP[]> = ['xs', 'sm', 'md', 'lg', 'xl']

    /**
     * Estabelece tamanhos minimos para cada breakpoint utilizado. Os respectivos valores maximos
     * sao determinados relativomente ao tamanho do breakpoint posterior (se houver).
     */
    private static readonly _MIN_SIZES: Readonly<Record<BreakpointsTP, number>> = {
        xl: 1500,
        lg: 992,
        md: 768,
        sm: 576,
        xs: 0,
    }

    static getBreakpointBounds(breakpoint: BreakpointsTP): BreakpointBoundsTP {

        const i = UseScreenSizeUtils._SIZE_SEQUENCE.indexOf(breakpoint)

        const bounds: BreakpointBoundsTP = {}

        if (i < ArrayUtils.getLastIndex(UseScreenSizeUtils._SIZE_SEQUENCE as any)) {
            const nextBreakPoint = UseScreenSizeUtils._SIZE_SEQUENCE[i + 1]
            bounds.maxWidth = (UseScreenSizeUtils._MIN_SIZES[nextBreakPoint] - .02)
        }

        if (UseScreenSizeUtils._MIN_SIZES[breakpoint])
            bounds.minWidth = UseScreenSizeUtils._MIN_SIZES[breakpoint]

        return bounds
    }

    static getSmdBounds(): BreakpointBoundsTP {
        return {
            ...UseScreenSizeUtils.getBreakpointBounds('md'),
            minWidth: 0
        }
    }

}
