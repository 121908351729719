import React from 'react'
import { Result } from 'antd'
import { ResultStatusType } from 'antd/es/result'

interface ICPProps {
    status?: ResultStatusType
    title?: string
    message?: React.ReactNode
    bottomContent?: JSX.Element
    icon?: JSX.Element
}

/**
 * Result do ANT para mostrar erros, etc..
 */
export function ScreenResultCP(props: ICPProps): JSX.Element {

    return (
        <Result
            icon={props.icon}
            status={props.status}
            title={props.title}
            subTitle={props.message}
            extra={props.bottomContent}
        />
    )
}
