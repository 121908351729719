import { IsNotEmpty } from 'common/framework-ui/validation/decorators/IsNotEmpty'
import { ObjectPropsTP } from 'common/framework-ui/types/ObjectPropsTP'
import { FormModel } from 'common/framework-ui/form-state-manager/FormModel'
import { IsOptional } from 'class-validator/decorator/decorators'

export class TeacherDirectMessageFormModel extends FormModel {

    @IsNotEmpty()
    teacherCode: number

    @IsOptional()
    message: string

    constructor(initialData?: ObjectPropsTP<TeacherDirectMessageFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }
}
