import { HttpStatusEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpStatusEnum'
import { NotificationHelper } from 'common/framework-ui/helpers/NotificationHelper'
import { IApiReturn } from 'common/framework-ui/interfaces/IApiReturn'
import { IsValidReqReturnConfigTP } from 'common/framework-ui/request-manager/types/IsInvalidReqReturnConfigTP'
import { RequestTP } from 'common/framework-ui/request-manager/types/RequestTP'
import { OrUndefTP } from 'common/framework-ui/types/OrUndefTP'
import { SystemUtils } from 'common/framework-ui/utils/SystemUtils'

type _RequestAssistParamsTP = {
    request: RequestTP<any>,
    config?: IsValidReqReturnConfigTP,
}

/**
 * UTILS
 * Reune metodos auxiliares uteis para lidar com requisicoes http
 * realizadas utilizando as funcoes do modulo 'request helpers'.
 *
 * TODO: Add metodo para gerar log de falha padrao
 * TODO: Melhorar questao das props do tipo 'function' no config
 */
export class RequestUtils {

    private static _requestIdsCounter = 0

    private constructor() {}

    /** Exibe mensagem de notificacao de falha em requisicao determinada via procedimento generico. */
    static showDefaultErrorNotification(error: unknown, defaultMsg: string, notificationTitle = 'Ops!'): void {

        const apiErrorMsg = (error as IApiReturn)?.data?.message ?? (error as IApiReturn)?.message
        const errorTitle = (typeof apiErrorMsg === 'string') ? apiErrorMsg : notificationTitle

        const apiErrors = (error as IApiReturn)?.data?.errors
        let errorMsg = defaultMsg
        if (typeof apiErrors === 'string')
            errorMsg = apiErrors
        else if (Array.isArray(apiErrors))
            errorMsg = (apiErrors as string[]).join(', ')

        NotificationHelper.error(errorTitle, errorMsg)
    }

    /** Determina se execucao de 01 requisicao foi concluida. */
    static isRequestConcluded(request: RequestTP<any>): boolean {
        return (request.wasTried && !request.isAwaiting)
    }

    /** Avalia 01 requisicao & determina se foi bem sucedida. */
    static isRequestSuccess(request: RequestTP<any>, isVoidRequest = false): boolean {
        return (this.isRequestConcluded(request) && request.isSuccess && (isVoidRequest || !!request.responseData))
    }

    /** Avalia 01 requisicao & determina se houve erro durante a execucao (cancelamento nao eh considerado erro). */
    static isRequestError(request: RequestTP<any>, isVoidRequest?: boolean): boolean {
        if (!this.isRequestConcluded(request))
            return false
        return (!this.isRequestSuccess(request, isVoidRequest) && !request.isCancelled)
    }

    static handleError(request: RequestTP<any>, defaultMsg: string): void           // Formato de chamada 01
    static handleError(config: IsValidReqReturnConfigTP, defaultMsg?: string): void // Formato de chamada 02
    static handleError(params: _RequestAssistParamsTP, defaultMsg?: string): void   // Formato de chamada 03

    /** Executa tratamento padrao para erro em requisicoes. */
    static handleError(
        param1: RequestTP<any> | IsValidReqReturnConfigTP | _RequestAssistParamsTP,
        defaultMsg?: string
    ): void {

        const isAssistParams = (!this._isConfigParam(param1) && !!(param1 as _RequestAssistParamsTP)?.request)
        const params = isAssistParams ? (param1 as _RequestAssistParamsTP) : this._getRequestAssistParams(param1)

        const _errorMsg = params.config?.errorMsg ?? defaultMsg
        const failureLogMsg = this._getFailureLogMsg(params.config) ?? _errorMsg
        if (!!failureLogMsg)
            console.error(failureLogMsg, params.request.responseData, params.request.error)

        if (!!_errorMsg && this._shouldReportFailure(params.config, params.request.responseStatus))
            RequestUtils.showDefaultErrorNotification(params.request.error, _errorMsg)
    }

    static getNewRequestId(): string {
        this._requestIdsCounter++
        const idNumberString = (this._requestIdsCounter < 1000) ? `000${this._requestIdsCounter}`.slice(-3) : this._requestIdsCounter
        return `request-${idNumberString}`
    }

    static resetRequestCount(): void {
        this._requestIdsCounter = 0
    }

    static isValidRequestReturn(config: IsValidReqReturnConfigTP): boolean;
    static isValidRequestReturn(request: RequestTP<any>, errorMsg?: string, isVoidRequest?: boolean): boolean;

    /**
     * Valida o retorno de uma requisicao para os casos mais comuns.
     *
     * Falhas do tipo 401 (nao autorizado), 403 (acesso proibido) & 404 (nao encontrada), por padrao,
     * nao emitem notificacao de falha porque:
     *
     * - 401: Preve logout automatico de usuario (que deve ser tratado em outro lugar);
     * - 403: Preve redirecionamento de tela (que deve ser tratado em outro lugar);
     * - 404: Nem sempre representa erro de execucao;
     */
    static isValidRequestReturn(
        param1: RequestTP<any> | IsValidReqReturnConfigTP,
        errorMsg?: string,
        isVoidRequest = false,
    ): boolean {

        const params = this._getRequestAssistParams(param1)

        if (this.isRequestSuccess(params.request, isVoidRequest))
            return true

        if (this.isRequestError(params.request, isVoidRequest))
            this.handleError(params, errorMsg)

        return false
    }

    static async getUserIp(): Promise<string> {

        const result = await fetch('https://api.ipify.org/?format=json').then((response) => {
            if (response.status === HttpStatusEnum.OK)
                return response.json()

            console.error('> Erro', response.status, response.statusText)
            return

        }).catch((error) => {
            console.error('> Falha ao realizar requisição ', error)
            return
        })

        return result.ip
    }

    /**
     * Determina se o metodo de tratamento generico de retorno de requisicoes deve emitir notificacoes
     * em caso de falha.
     */
    private static _shouldReportFailure(config?: IsValidReqReturnConfigTP, status?: HttpStatusEnum): boolean {

        if (config?.shouldReportFailure === false)
            return false

        switch (status) {
            case HttpStatusEnum.UNAUTHORIZED:
                return !!config?.mustReport401
            case HttpStatusEnum.FORBIDDEN:
                return !!config?.mustReport403
            case HttpStatusEnum.NOT_FOUND:
                return !!config?.mustReport404
            default:
                return true
        }
    }

    private static _getFailureLogMsg(config?: IsValidReqReturnConfigTP): string | void {

        if (!!config?.failureLogMsg)
            return config.failureLogMsg

        if (!config?.component && !config?.componentMethod)
            return

        return `FALHA - ${config.component?.name ?? 'component'}.${config.componentMethod?.name ?? 'component'}`
    }

    private static _getRequestAssistParams(param: RequestTP<any> | IsValidReqReturnConfigTP): _RequestAssistParamsTP {

        const config: OrUndefTP<IsValidReqReturnConfigTP> = SystemUtils.nvl(
            !!(param as IsValidReqReturnConfigTP)?.request,
            (param as IsValidReqReturnConfigTP)
        )

        const request = (config?.request ?? param) as RequestTP<any>
        return { config, request }
    }

    private static _isConfigParam(param: any): boolean {

        if (typeof param !== 'object' || !(param as IsValidReqReturnConfigTP)?.request)
            return false

        const configKeys: Array<keyof IsValidReqReturnConfigTP> = [
            'request',
            'errorMsg',
            'isVoidRequest',
            'failureLogMsg',
            'shouldReportFailure',
            'mustReport404',
            'mustReport403',
            'mustReport401',
            'component',
            'componentMethod',
        ]

        for (const paramKey of Object.keys(param)) {
            if (!(configKeys as string[]).includes(paramKey))
                return false
        }

        return true
    }
}
