import React, { useState } from 'react'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ImportInstructionsCP } from 'submodules/nerit-micro-services-ui/import/components/import-instructions/ImportInstructionsCP'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { TableBulkOperations } from 'submodules/nerit-micro-services-ui/common/components/table-bulk-operations/TableBulkOperations'
import styled from 'styled-components'
import { ModalImportSubscriptionsCP } from 'modules/admin/components/modal-import-subscriptions/ModalImportSubscriptionsCP'

export function ScreenContentAdminImportSubscriptionCP(): JSX.Element {

    const [updateHistory, setUpdateHistory] = useState<number>(TableUtils.getReloadNumber())
    const [isImportModalVisible, setIsImportModalVisible] = useState<boolean>(false)

    return (
        <>
            <HeaderCP title={'Importar Inscrições'}>
                <ButtonCP
                    onClick={() => setIsImportModalVisible(true)}
                >
                    Importar
                </ButtonCP>
            </HeaderCP>

            <ContentSCP>
                <CardWrapperSCP>
                    <ImportInstructionsCP
                        instrutions={[
                            { columnName: 'nome', columnDescription: 'Nome do Participante' },
                            { columnName: 'cpf', columnDescription: 'CPF do Participante' },
                            { columnName: 'email', columnDescription: 'E-mail do Participante' },
                            { columnName: 'telefone', columnDescription: 'Telefone do Participante' },
                        ]}
                    />
                </CardWrapperSCP>

                <CardWrapperSCP>
                    <CardCP title={'Histórico de Importações'}>
                        <TableBulkOperations
                            reloadList={updateHistory}
                        />
                    </CardCP>
                </CardWrapperSCP>
            </ContentSCP>

            <ModalImportSubscriptionsCP
                visible={isImportModalVisible}
                onSave={() => {
                    setUpdateHistory(TableUtils.getReloadNumber())
                    setIsImportModalVisible(false)
                }}
                onCancel={() => setIsImportModalVisible(false)}
            />
        </>
    )
}

const ContentSCP = styled.div`
    display: flex;
    overflow-x: auto;
`

const CardWrapperSCP = styled.div`
    width: 50%;
`
