import React from 'react'
import { LayoutSubmenuCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/layout/LayoutSubmenuCP'
import { AppStateUtils } from 'common/framework-ui/utils/AppStateUtils'
import { PrivateRouter } from 'app/routes/PrivateRouter'
import { ScreenContentAdminImportSubscriptionCP } from 'modules/admin/screens/screen-admin-beerads/content/ScreenContentAdminImportSubscriptionCP'
import { AccessControl } from 'app/components/access-control/AccessControl'

const SUB_MENU_SUBSCRIPTION_KEY = 'SUB_MENU_MAIN_KEY'
type ViewTP = 'importSubscription'

/**
 * Tela administrativa, por enquanto tera aqui o que for ADMIN para o NeritEduca.
 */
export function ScreenAdmin(): JSX.Element {

    return (
        <AccessControl isAdmin={true} redirect={{ shouldRedirect: true }}>
            <LayoutSubmenuCP<ViewTP>
                loggedDomain={AppStateUtils.getDomainSlug()}
                menuTitle={'Admistrativo'}
                defaultSubmenu={'importSubscription'}
                defaultOpenKeys={[SUB_MENU_SUBSCRIPTION_KEY]}
                submenuConfigs={[
                    {
                        key: SUB_MENU_SUBSCRIPTION_KEY,
                        title: 'Inscrições',
                        items: [
                            {
                                name: 'Importar inscrições',
                                view: 'importSubscription',
                                route: PrivateRouter.ADMIN_ROOT,
                                content: <ScreenContentAdminImportSubscriptionCP/>
                            },
                        ],
                        enabled: true
                    },
                ]}
            />
        </AccessControl>
    )
}
