import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NeritEducaSystemApiEnums } from 'submodules/neriteduca-sdk/services/common/NeritEducaSystemApiEnums'

export class ProductReleaseRequests {

    public static CONTROLLER_ROOT = 'product-releases'
    public static GET_RELEASE_EP = ':code'
    public static GET_WELCOME_PAGE = ':code/welcome-page'
    public static GET_PUBLISHED_RELEASES = 'published-releases'

    static getRelease = (code: number): RequestConfigTP => ({
        baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
        url: `${ProductReleaseRequests.CONTROLLER_ROOT}/${ProductReleaseRequests.GET_RELEASE_EP.replace(':code', code.toString())}`,
        method: HttpMethodEnum.GET,
    })

    static getWelcomePage = (code: number): RequestConfigTP => ({
        baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
        url: `${ProductReleaseRequests.CONTROLLER_ROOT}/${ProductReleaseRequests.GET_WELCOME_PAGE.replace(':code', code.toString())}`,
        method: HttpMethodEnum.GET,
    })

    static getPublishedReleases = (): RequestConfigTP => ({
        baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
        url: `${ProductReleaseRequests.CONTROLLER_ROOT}/${ProductReleaseRequests.GET_PUBLISHED_RELEASES}`,
        method: HttpMethodEnum.GET,
    })
}
