import * as React from 'react'
import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'

interface IScpProps {
    alignItems?: 'center' | 'flex-end' | 'space-between' | 'flex-start'
    justifyContent?: 'center' | 'flex-end' | 'space-between' | 'flex-start'
    flexDirection?: 'column'
    flexWrap?: 'wrap'
    marginRight?: number
    marginLeft?: number
    marginTop?: number
    marginBottom?: number
}

interface IFlexCPProps extends IScpProps{
    children: React.ReactNode
    className?: string
}

/**
 * COMPONENTE
 * Para fazer um displex flex
 */
export function FlexCP(props: IFlexCPProps): JSX.Element {
    return (
        <FilterWrapperSCP
            className={props.className}
            alignItems={props.alignItems}
            justifyContent={props.justifyContent}
            marginTop={props.marginTop ?? 0}
            marginRight={props.marginRight ?? 0}
            marginBottom={props.marginBottom ?? 0}
            marginLeft={props.marginLeft ?? 0}
            flexWrap={props.flexWrap}
            flexDirection={props.flexDirection}
        >
            {props.children}
        </FilterWrapperSCP>
    )
}

const FilterWrapperSCP = styled.div<IScpProps>`
  display: flex;
  align-items: ${props => props.alignItems ?? ''};
  justify-content: ${props => props.justifyContent ?? ''};
  flex-wrap: ${props => props.flexWrap ?? ''};
  margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
  flex-direction: ${props => props.flexDirection};
`
