import React, { useEffect, useState } from 'react'
import { useRequest } from 'common/framework-ui/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'common/framework-ui/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'common/framework-ui/request-manager/RequestUtils'
import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { DateUtils } from 'common/framework-ui/utils/date/DateUtils'
import { DateFormatEnum } from 'common/framework-ui/enums/DateFormatEnum'
import { TextCP } from 'common/framework-ui/components/text/TextCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { InputCP } from 'common/framework-ui/components/form-fields/input/InputCP'
import { ButtonCP } from 'common/framework-ui/components/button/ButtonCP'
import { ForumRequests } from 'submodules/neriteduca-sdk/services/ead/forum/ForumRequests'
import { LoadingOverlayCP } from 'common/framework-ui/components/loading/loading-overlay/LoadingOverlayCP'
import { ForumPostItemResponseDTO } from 'submodules/neriteduca-sdk/services/ead/forum/dtos/response/ForumPostItemResponseDTO'
import { NotificationHelper } from 'common/framework-ui/helpers/NotificationHelper'

interface ICPProps {
    forumTopicCode: number
    onGoBack: () => void
}

/**
 * Collapse de Forums.
 */
export function ForumPostsCP(props: ICPProps): JSX.Element {

    const [forumPosts, setForumPosts] = useState<ForumPostItemResponseDTO[]>([])
    const getForumPostsRequest = useRequest<IGenericListResponseDTO<ForumPostItemResponseDTO>>()
    useEffect(onGetForumPostsRequestChange, [getForumPostsRequest.isAwaiting])

    const [message, setMessage] = useState<string>()
    const saveForumPostRequest = useRequest<void>('none')
    useEffect(onSaveForumPostRequestChange, [saveForumPostRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa
     */
    function init(): void {

        getForumPostsRequest.runRequest(ForumRequests.getPosts(props.forumTopicCode))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetForumPostsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getForumPostsRequest, 'Erro postagens do forum'))
            return

        setForumPosts(getForumPostsRequest.responseData!.list)
    }

    /**
     * Salva conta bancaria
     */
    function saveForumPost(): void {

        if (!message) {
            NotificationHelper.error('Ops', 'Preencha a mensagem para enviar')
            return
        }

        saveForumPostRequest.runRequest(ForumRequests.createPost(props.forumTopicCode, { message }))
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveForumPostRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveForumPostRequest, 'Ocorreu algun erro ao salvar postagem', true))
            return

        NotificationHelper.info('Yes', 'Conta bancária salva com sucesso')
        setMessage(undefined)
        init()
    }

    return (
        <WrapperSCP>
            <LoadingOverlayCP show={getForumPostsRequest.isAwaiting}/>

            <ButtonCP
                type={'link'}
                icon={'left-circle'}
                marginBottom={20}
                onClick={props.onGoBack}
            >
                Voltar
            </ButtonCP>

            <div className='nrt-ead-forum-messages'>
                <div className='nrt-ead-forum-message-list'>

                    {
                        forumPosts.map((post) => (

                            <div className='nrt-ead-forum-message'>
                                <TextCP text={post.user.name} color={ThemeAnt.primaryColor}/>
                                <p>
                                    <span>{ post.message }</span>
                                </p>
                                <TimeSCP>
                                    { DateUtils.getFormatted(post.createDate, DateFormatEnum.BR_WITH_TIME_H_M) }
                                </TimeSCP>
                            </div>

                        ))

                    }

                </div>

                <SendMsgAreaSCP>
                    {
                        !saveForumPostRequest.isAwaiting &&
                        <InputCP
                            label={'Mensagem'}
                            required={true}
                            onChange={setMessage}
                        />
                    }

                    <ButtonCP
                        onClick={saveForumPost}
                        loading={saveForumPostRequest.isAwaiting}
                    >
                        Enviar
                    </ButtonCP>
                </SendMsgAreaSCP>
            </div>
        </WrapperSCP>
    )
}

const SendMsgAreaSCP = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  padding: 10px;
  border: 1px solid #e2e2e2;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  
  button {
    margin-top: 16px;
    margin-left: 20px;
  }
`

const TimeSCP = styled.div`
  font-size: 10px;
  color: #adadad;
  display: flex;
  justify-content: flex-end;
`

const WrapperSCP = styled.div`

  .nrt-ead-forum-messages {

    display: flex;
    flex-direction: column;
    flex: 1;
    align-self: stretch;


    height: 450px;
    max-width: 1200px;

    .nrt-spinner{
      svg{
        margin-right: 0;
      }
    }

  }
  
  .nrt-ead-forum-message-list {

    background: #f9f9f9;
    border: 1px solid #e2e2e2;
    border-top-left-radius: 5px;
    border-top-right-radius-radius: 5px;
    flex: 1;

    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding:1rem;
    display: flex;
    flex-direction: column;
  }

  .nrt-ead-forum-message {

    background: #fff;
    border: 1px solid #e7e7e7;
    box-shadow: .2rem .2rem .2rem rgba(0,0,0,.05);
    display: block;
    width: fit-content;
    padding: .5rem 1rem;
    margin: .7rem 0 ;

    max-width: 75%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;

    p {
      margin-top: .3rem;
      font-size: .9rem;
    }

    .fileName {
      color: #007eff;
      text-align: center;
    }

  }
`
