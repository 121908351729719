import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { AppStateUtils } from 'common/framework-ui/utils/AppStateUtils'
import { PublicRouter } from 'app/routes/PublicRouter'
import { RouterSwitchCP } from 'submodules/nerit-framework-ui/common/router/components/router-switch/RouterSwitchCP'
import { PrivateAccessVerifierCP } from 'app/components/private-access-verifier/PrivateAccessVerifierCP'
import { PrivateRouter } from 'app/routes/PrivateRouter'
import { ScreenError404 } from 'submodules/nerit-framework-ui/common/screens/ScreenError404'
import { RoutingHelper } from 'config/RoutingHelper'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'
import { FallbackScreenContentCP } from 'common/framework-ui/components/fallback-screen-content/FallbackScreenContent'
import { HttpStatusEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpStatusEnum'
import { AuthActions } from 'modules/auth/AuthActions'
import { ReduxHelper } from 'common/framework-ui/redux/helpers/ReduxHelper'

/**
 * Router principal da aplicacao
 *
 * 1. Instancia o Routing HELPER, para ser usado na aplicacao
 * 2. Valida se a ROTA possui os dominios WL
 * 3. Renderiza as ROTAS
 *  3.1 Publica, caso seja rota publica
 *  3.2 Caso nao seja rota publica, entrar no AccessVerifier para validar se esta logao
 */
export function AppRouter(): JSX.Element {

    const routeHistory = useHistory()
    const [isValidDomain, setIsValidDomain] = useState<boolean>()

    useEffect(() => { init() }, [])

    /**
     */
    async function init(): Promise<void> {

        let domain = AppStateUtils.getDomainSlug()

        // Inicializa o Roteador
        NeritFrameworkRoutingHelper.init(routeHistory)

        // Se o dominio do usuario logado eh diferente da URL, desloga
        if (AppStateUtils.getDomainSlug() !== NeritFrameworkRoutingHelper.getUrlCustomerDomain()) {

            ReduxHelper.getInstance().store.dispatch({
                type: AuthActions.TYPE_UPDATE_DOMAIN,
                payload: null
            })
            AuthActions.logout()

            // Depois de deslogado, agora pega o dominio apenas da URL
            domain = NeritFrameworkRoutingHelper.getUrlCustomerDomain()
        }

        // Valida se o dominio do cliente é valido
        setIsValidDomain(await RoutingHelper.isValidSchema(domain))
    }

    if (isValidDomain === undefined)
        return <LoadingCP/>

    if (!isValidDomain)
        return <FallbackScreenContentCP status={HttpStatusEnum.FORBIDDEN} message={'Domínio Inválido'}/>

    return (
        <RouterSwitchCP
            routes={[
                { path: PublicRouter.ROOT, component: PublicRouter },
                { path: PrivateRouter.ROOT, component: PrivateAccessVerifierCP },

                { component: () => <ScreenError404 redirectTo={PublicRouter.USER_LOGIN}/> },
            ]}
        />
    )
}
