import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NeritEducaSystemApiEnums } from 'submodules/neriteduca-sdk/services/common/NeritEducaSystemApiEnums'
import { PeopleChangePasswordRequestDTO } from 'submodules/neriteduca-sdk/services/people/dtos/requests/PeopleChangePasswordRequestDTO'
import { PeopleSaveRequestDTO } from 'submodules/neriteduca-sdk/services/people/dtos/requests/PeopleSaveRequestDTO'

export class PeopleRequests {

	public static CONTROLLER_ROOT = 'people'
	public static GET_FIND_ONE_EP = 'get/:code'
	public static PUT_UPDATE_ONE_EP = 'update/:code'
	public static PUT_CHANGE_PASSWORD_EP = 'change-password/:code'

	static getOne = (code: number): RequestConfigTP => ({
		baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
		url: `${PeopleRequests.CONTROLLER_ROOT}/${PeopleRequests.GET_FIND_ONE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static update = (code: number, dto: PeopleSaveRequestDTO): RequestConfigTP => ({
		baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
		url: `${PeopleRequests.CONTROLLER_ROOT}/${PeopleRequests.PUT_UPDATE_ONE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto
	})

	static changePassword = (code: number, dto: PeopleChangePasswordRequestDTO): RequestConfigTP => ({
		baseUrlType: NeritEducaSystemApiEnums.NERIT_EDUCA_API,
		url: `${PeopleRequests.CONTROLLER_ROOT}/${PeopleRequests.PUT_CHANGE_PASSWORD_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto
	})

}
