import React, { useState } from 'react'
import styled from 'styled-components'
import { LoggedUserDataCardCP } from 'modules/people/components/logged-user-data-card/LoggedUserDataCardCP'
import { ChangePasswordModalCP } from 'modules/people/components/change-password-modal/ChangePasswordModalCP'
import { ButtonCP } from 'common/framework-ui/components/button/ButtonCP'
import { AppStateUtils } from 'common/framework-ui/utils/AppStateUtils'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'

/**
 * TELA perfil.
 */
export function ScreenUserProfile(): JSX.Element {

    const [isPasswordModalVisible, setIsPasswordModalVisible] = useState<boolean>(false)

    return (
        <>
            <HeaderCP title={'Meu Perfil'}>
                <ButtonSCP>
                    <ButtonCP
                        onClick={() => setIsPasswordModalVisible(true)}
                        disabled={AppStateUtils.getLoggedUserData()!.user.code === 1}
                    >
                        Alterar senha
                    </ButtonCP>
                </ButtonSCP>
            </HeaderCP>

            <ContentWrapperSCP>
                <CardContainer>

                    <LoggedUserDataCardCP/>

                    <ChangePasswordModalCP
                        visible={isPasswordModalVisible}
                        onClose={() => setIsPasswordModalVisible(false)}
                    />

                </CardContainer>
            </ContentWrapperSCP>
        </>
    )
}

const ContentWrapperSCP = styled.div`
    display: flex;
    overflow-x: auto;
    justify-content: center;
`

const CardContainer = styled.div`
    width: 800px;
`

const ButtonSCP = styled.div`

`
