import React from 'react'
import styled from 'styled-components'
import { SiderCP } from 'common/framework-ui/components/screen-layout/sider/SiderCP'
import { CollapseEadCourseStructureCP } from 'modules/ead/components/collapse-ead-course-structure/CollapseEadCourseStructureCP'
import 'video.js/dist/video-js.css'
import { ButtonExitCourseView } from 'modules/ead/components/button-exit-course-view/ButtonExitCourseView'

interface ICPProps {
    accountCode: number
    productReleaseCode: number
}

export function SiderEadCourseViewCP(props: ICPProps): JSX.Element {

    return (
        <SiderCP
            width={250}
            bgColor={'#0E1529'}
            isMainSider={true}
        >
            <SiderWrapperSCP>
                <CollapseEadCourseStructureCP
                    accountCode={props.accountCode}
                    titleColor={'#fff'}
                    backgroundColor={'transparent'}
                    viewMode={'button'}
                    bordered={false}
                    productReleaseCode={props.productReleaseCode}
                />
            </SiderWrapperSCP>

            <ButtonExitCourseView
                mode={'text'}
                productReleaseCode={props.productReleaseCode}
            />
        </SiderCP>
    )
}

const SiderWrapperSCP = styled.div`
  
  overflow: scroll;
  
  .ant-collapse-content {
    background: transparent !important;
  }
  
  .ant-collapse-header {
    color: #fff !important;
  }
  
  .ant-list-item {
    border-bottom: none !important;
    padding: 3px !important;
    
    button {
      width: 100%;
    }
  }
`
