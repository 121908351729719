import { Menu } from 'antd'
import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import React, { ReactNode } from 'react'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { ObjectUtils } from 'submodules/nerit-framework-utils/utils/ObjectUtils'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { BarActiveMenuItemCP } from 'submodules/nerit-framework-ui/common/components/menu/bar-active-menu-item/BarActiveMenuItemCP'

export interface ITitleEventEntity {
    key: string
    domEvent: Event
}

interface IScpProps {
    hideArrow?: boolean
    padding?: string
}

interface ICPProps extends IScpProps {
    children: ReactNode
    title?: string | JSX.Element,
    onTitleClick?: (e: ITitleEventEntity) => void,
    openKeys?: string[],
    isActive?: boolean
}

/**
 * COMPONENTE: Sub Menu.
 */
export function SubMenuCP(props: ICPProps): JSX.Element {

    function onTitleClick(e: ITitleEventEntity): void {
        if (!!props.onTitleClick)
            props.onTitleClick(e)
    }

    function getAntProps(): ObjectPropsTP<ICPProps> {
        const customProps: Array<keyof ICPProps> = ['children']
        return ObjectUtils.getObjectsWithoutSomeProps(props, customProps)
    }

    return (
        <WrapperSCP
            hideArrow={props.hideArrow}
            padding={props.padding}
        >
            <Menu.SubMenu
                {...getAntProps()}
                onTitleClick={onTitleClick}
                title={props.title}
            >
                {props.children}
            </Menu.SubMenu>

            <BarActiveMenuItemCP
                isActive={props.isActive}
                isSecondaryMenu={false}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<IScpProps>`

  position: relative;

  .ant-menu-submenu > .ant-menu {
    background-color: transparent;
  }

  .ant-menu-submenu-title {
    font-weight: bold !important;
    color: ${ThemeProject.secondaryMenuItemColor};
    padding: ${props => props.padding ?? undefined} !important;
  }

  .ant-menu-submenu-arrow {
    display: ${props => !!props.hideArrow ? 'none' : undefined};
  }
`
