import React from 'react'
import { Tag } from 'antd'
import styled from 'styled-components'
import { TagCPColorTP } from 'submodules/nerit-framework-ui/common/components/tag/inner/TagCPColorTP'
import { FontStyleTP } from 'submodules/nerit-framework-ui/common/components/text/inner/FontStyleTP'
import { TagCpUtils } from 'submodules/nerit-framework-ui/common/components/tag/inner/TagCpUtils'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

const CLASS_SMALL = 'tag_small'

interface ICPProps extends BasicStyleWrapperCPProps {
    content: string | JSX.Element
    color?: TagCPColorTP | string
    onClose?: () => void
    small?: boolean
    fontStyle?: FontStyleTP
    minWidth?: number
    onClick?: () => void
    borderType?: 'circle'
}

/**
 * Componente para visualizacao de texto em forma de 'tag'.
 */
export function TagCP(props: ICPProps): JSX.Element {

    return (
        <BasicStyleWrapperCP margin={props.margin}>
            <WrapperSCP
                className={!!props.small ? CLASS_SMALL : ''}
                fontStyle={props.fontStyle ?? 'normal'}
                minWidth={props.minWidth}
                isClickable={!!props.onClick}
                borderType={props.borderType}
            >
                <Tag
                    closable={!!props.onClose}
                    onClose={props.onClose}
                    color={TagCpUtils.getParsedColor(props.color)}
                    onClick={() => props.onClick?.()}
                >
                    {props.content}
                </Tag>
            </WrapperSCP>
        </BasicStyleWrapperCP>
    )
}

const WrapperSCP = styled.div<{ fontStyle: FontStyleTP, minWidth?: number, isClickable: boolean, borderType?: 'circle' }>`

  display: flex;
  align-items: center;
  text-align: center;

  .ant-tag {
    font-style: ${props => props.fontStyle};
    min-width: ${props => (!!props.minWidth ? props.minWidth : 0)}px;
    cursor: ${props => props.isClickable ? 'pointer' : ''};
    border-radius: ${props => props.borderType === 'circle' ? '20px' : undefined};
    font-size: 11px;
  }

  &.${CLASS_SMALL} {
    .ant-tag {
      font-size: .65rem;
      padding: 0 4px;
      margin: 1px 2px;
      line-height: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 18px;

      svg {
        height: .7rem;
        width: .7rem;
      }
    }
  }
`
