import React, { useState } from 'react'
import { PrivateRouter } from 'app/routes/PrivateRouter'
import { UserMainMenuCP } from 'modules/user/components/user-main-menu/UserMainMenuCP'
import { ChatBalloonCP } from 'common/framework-ui/components/chat-balloon/ChatBalloonCP'
import { BoxCustomerSurveyCP } from 'app/components/customer-survey-box/BoxCustomerSurveyCP'
import { LayoutUserMainCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-user-main/LayoutUserMainCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'

/**
 * Layout principal da parte interna do sistema
 */
export function UserMainLayoutCP(): JSX.Element {

    const [refreshSiderCollapsed, setRefreshSiderCollapsed] = useState<number>(0)

    return (
        <LayoutUserMainCP
            menu={<UserMainMenuCP onSelectMenuItem={() => setRefreshSiderCollapsed(TableUtils.getReloadNumber())}/>}
            refreshMenuCollapsed={refreshSiderCollapsed}
            privateRouter={<PrivateRouter/>}
            extraContent={
                <>
                    <ChatBalloonCP/>
                    <BoxCustomerSurveyCP boxType={'bottom'}/>
                </>
            }
        />
    )
}
