import React from 'react'
import { InvoiceAccountsRequests } from 'submodules/neriteduca-sdk/services/sales/invoice-account/InvoiceAccountsRequests'
import { ClassTypeLabelEnum } from 'submodules/neriteduca-sdk/services/product/product/enums/ClassTypeEnum'
import { ProgressCP } from 'common/framework-ui/components/progress/ProgressCP'
import { HelpCP } from 'common/framework-ui/components/help/HelpCP'
import { FlexCP } from 'common/framework-ui/components/flex/FlexCP'
import { UserCertificateResponseDTO } from 'submodules/neriteduca-sdk/services/sales/invoice-account/dtos/response/UserCertificateResponseDTO'
import { MathUtils } from 'submodules/nerit-framework-utils/utils/MathUtils'
import { ButtonGenerateCertificateCP } from 'modules/user/components/button-generate-certificate/ButtonGenerateCertificateCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'
import { PopoverUserScoreDetailCP } from 'modules/user/components/popover-user-score-detail/PopoverUserScoreDetailCP'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'

/**
 * Tabela de Certificados do usuario.
 */
export function TableUserCertificatesCP(): JSX.Element {

    return (
        <>
            <TableFromApiCP<UserCertificateResponseDTO>
                appearance={{
                    wrappedOnCard: true,
                }}
                apiConfig={{
                    requestConfigTP: InvoiceAccountsRequests.getUserCertificates
                }}
                shouldLoadData={1}
                columns={[
                    {
                        title: 'Curso',
                        render: (text, record) => (
                            <>
                                <TextCP text={record.product.name}/>
                                <TextCP text={record.productRelease.name} size={'small'} color={'#adadad'}/>
                            </>
                        )
                    },
                    {
                        render: (text, record) => <TagCP content={ClassTypeLabelEnum[record.classType]}/>
                    },
                    {
                        title: 'Progresso',
                        render: (text, record) => (
                            <>
                                <ProgressCP
                                    percent={MathUtils.getPercentage(record.statistics.totalContentsViewed, record.statistics.totalContents, true)}
                                />
                                <HelpCP
                                    text={'Percentual de conteúdos assistidos'}
                                    type={'text'}
                                />
                            </>
                        )
                    },
                    {
                        title: 'Certificado',
                        align: 'center',
                        render: (text, record) => (
                            <>
                                <FlexCP justifyContent={'center'} marginBottom={10}>
                                    <ButtonGenerateCertificateCP
                                        accountCode={record.accountCode}
                                        isApproved={record.isApproved}
                                    />
                                </FlexCP>
                                <TextCP text={`Assistiu conteúdos? ${record.isContentViewApproved ? 'Sim' : 'Não'}`} size={'small'} color={'#adadad'}/>
                                <TextCP text={`Nota alcançada?: ${record.isScoreApproved ? 'Sim' : 'Não'}`} size={'small'} color={'#adadad'}/>

                                <FlexCP justifyContent={'center'}>
                                    <PopoverUserScoreDetailCP userScores={record.statistics.scoreByTests}/>
                                </FlexCP>
                            </>
                        )
                    },
                ]}
            />
        </>
    )
}
