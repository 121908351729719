import React from 'react'
import { LoadingCP } from 'common/framework-ui/components/loading/LoadingCP'
import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'

interface ILoadingOverlayCPProps {
    show?: boolean
    size?: number
    text?: string

}

/**
 * Componente de loading que ocupa espaco inteiro do container
 * dentro do qual for inserido.
 */
export function LoadingOverlayCP(props: ILoadingOverlayCPProps): JSX.Element {

    if (!props.show)
        return <></>

    return (
        <LoadingWrapperSCP>
            <div>
                <LoadingCP/>
                {
                    props.text &&
                    <TextSCP>
                        {props.text}
                    </TextSCP>
                }
            </div>
        </LoadingWrapperSCP>
    )
}

const TextSCP = styled.div`
  animation: blink 3s linear infinite;
  margin-top: 10px;
  color: ${props => props.theme.primaryColor};
  
  @keyframes blink{
    0%{opacity: .2;}
    20%{opacity: .6;}
    40%{opacity: 1;}
    60%{opacity: 1;}
    80%{opacity: .6;}
    100%{opacity: .2;}
  }
`

const LoadingWrapperSCP = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
    z-index: 999999;
`
