import React from 'react'
import { PersonImportValidationErrorsTP } from 'submodules/nerit-micro-services-ui/import/components/modal-import/inner/PersonImportValidationErrorsTP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { TableImportValidationErrorsCP } from 'submodules/nerit-micro-services-ui/import/components/table-import-validation-errors/TableImportValidationErrorsCP'

interface IPersonImportValidationErrorsModalICPProps {
    visible: boolean
    errors: PersonImportValidationErrorsTP
    onClose: () => void
}

/**
 * Modal para exibicao de falhas de validacao em arquivos .csv para importacao de pessoas.
 */
export function PersonImportValidationErrorsModalICP(props: IPersonImportValidationErrorsModalICPProps): JSX.Element {

    return (
        <ModalCP
            visible={props.visible}
            title={'Inconsistências do arquivo'}
            onOk={props.onClose}
            onClose={props.onClose}
            singleButton={true}
            width={'700px'}
            top={40}
            height={450}
        >
            <TableImportValidationErrorsCP errors={props.errors}/>
        </ModalCP>
    )
}
