import { Alert } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import { AlertCPTypeTP } from 'common/framework-ui/components/alert/inner/AlertCPTypeTP'

interface IScpProps {
    marginRight?: number
    marginLeft?: number
    marginTop?: number
    marginBottom?: number
}

interface IAlertCPProps extends IScpProps {
    message: string | JSX.Element
    onClick?: () => void
    description?: string | JSX.Element
    type: AlertCPTypeTP
    showIcon?: boolean
    show?: boolean
}

/**
 * COMPONENTE
 * Exibe mensagem estatica para notificacao de forma destacada.
 */
export function AlertCP(props: IAlertCPProps): JSX.Element {

    if (props.show === false)
        return <></>

    return (
        <WrapperSCP
            onClick={props.onClick}
            marginTop={props.marginTop ?? 0}
            marginRight={props.marginRight ?? 0}
            marginBottom={props.marginBottom ?? 0}
            marginLeft={props.marginLeft ?? 0}
        >
            <Alert
                message={props.message}
                showIcon={props.showIcon}
                description={props.description}
                type={props.type}
            />
        </WrapperSCP>
    )
}
const WrapperSCP = styled.div<IScpProps>`
    
  cursor: ${props => !!props.onClick ? 'pointer' : undefined};
  margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
    
  .ant-alert{
    font-size: 12px;
  }
`
