import { IsNotEmpty } from 'common/framework-ui/validation/decorators/IsNotEmpty'
import { IsEmail } from 'common/framework-ui/validation/decorators/IsEmail'
import { NotContainsSpace } from 'common/framework-ui/validation/decorators/NotContainsSpace'
import { IsString } from 'common/framework-ui/validation/decorators/IsString'
import { FormModel } from 'common/framework-ui/form-state-manager/FormModel'

/**
 * VALIDADOR
 * Define validacao dos campos do formulario de RECUPERAR SENHA.
 */
export class RecoverPwdFormValidator extends FormModel {

    @NotContainsSpace()
    @IsString()
    @IsNotEmpty()
    schema: string

    @IsEmail()
    @IsNotEmpty()
    email: string

    constructor(initialValues?: Partial<RecoverPwdFormValidator>) {
        super()
        Object.assign(this, initialValues)
    }
}
