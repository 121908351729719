import { IsOptional } from 'class-validator/decorator/decorators'
import { FormModel } from 'common/framework-ui/form-state-manager/FormModel'
import { ObjectPropsTP } from 'common/framework-ui/types/ObjectPropsTP'
import { IsString } from 'common/framework-ui/validation/decorators/IsString'

/**
 * Define validação dos campos do formulário de dados do perfil de usuário
 */
export class ProfileFormModel extends FormModel {

    @IsOptional()
    phone?: string

    @IsOptional()
    birthDate?: string

    // address

    @IsOptional()
    @IsString()
    zipCode?: string

    @IsOptional()
    @IsString()
    street?: string

    @IsOptional()
    number?: number

    @IsOptional()
    @IsString()
    complement?: string

    @IsOptional()
    @IsString()
    neighborhood?: string

    @IsOptional()
    @IsString()
    city?: string

    @IsOptional()
    @IsString()
    state?: string

    constructor(initialData: ObjectPropsTP<ProfileFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }
}
