import { IAction } from 'common/framework-ui/redux/interfaces/IAction'

/**
 * MANAGER
 * Gerencia geracao de logs de execucao no sistema.
 *
 * @todo: Implementar...
 *
 * @author hjcostabr
 */
export class LoggingHelper {

    private constructor() { /** Construtor privado impede instanciacao. */ }

    /**
     * @todo Add descricao
     */
    static logReduxAction(action: IAction, state: any): void {
        console.warn('LoggingHelper.logReduxAction - @todo: Metodo NAO implementado...', action, state)
    }
}
