import React from 'react'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { DescriptionCP } from 'submodules/nerit-framework-ui/common/components/description/DescriptionCP'
import styled from 'styled-components'

interface ICPProps {
    instrutions: { columnName: string, columnDescription: string }[]
    templateFileUrl?: string
}

/**
 * Exibe instrucoes para importacao de pessoas.
 */
export function ImportInstructionsCP(props: ICPProps): JSX.Element {

    return (
        <CardCP title={'Instruções'}>
            <InstructionsSummarySCP>
                Importar os dados dos seus contatos no sistema é muito simples.
                Basta criar uma planilha no excel (formato .XLSX) e subir para o sistema.

                <br/>
                <br/>

                Veja abaixo os campos permitidos.
                {
                    !!props.templateFileUrl &&
                    <>
                        Ou se preferir baixe o modelo

                        <DownloadTemplateLinkSCP
                            href={props.templateFileUrl}
                            target={'_blank'}
                        >
                            clicando aqui
                        </DownloadTemplateLinkSCP>
                        .
                    </>
                }
            </InstructionsSummarySCP>

            <DescriptionCP
                bordered={true}
                numberOfColumns={1}
                items={
                    props.instrutions.map((instruction, index) => ({
                        index,
                        label: instruction.columnName,
                        text: instruction.columnDescription
                    }))
                }
            />
        </CardCP>
    )
}

const InstructionsSummarySCP = styled.p`
    font-size: .85rem;
    margin-bottom: 20px;
    margin-top: -2px;
`

const DownloadTemplateLinkSCP = styled.a`
    margin-left: 4px;
`
