import React, { useContext } from 'react'
import styled, { ThemeContext } from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { LinkCP } from 'common/framework-ui/components/link/LinkCP'
import { ButtonCP } from 'common/framework-ui/components/button/ButtonCP'

interface IAuthFormFooterCPProps {
    link: { text: string, onClick: () => void }
    button: { text: string, onClick: () => void }
    awaiting: boolean
}

/**
 * COMPONENTE
 * Rodape para formlarios do modulo de AUTENTICACAO.
 *
 * @author hjcostabr
 */
export function AuthFormFooterCP(props: IAuthFormFooterCPProps): JSX.Element {

    const theme: any = useContext(ThemeContext)

    return (
        <WrapperSCP>
            <LinkContainerSCP>
                <LinkCP text={props.link.text} onClick={props.link.onClick} color={theme.secondaryColor}/>
            </LinkContainerSCP>

            <ButtonCP
                size={'large'}
                type={'primary'}
                isSubmit={true}
                loading={props.awaiting}
                onClick={props.button.onClick}
            >
                {props.button.text}
            </ButtonCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
`

const LinkContainerSCP = styled.div`
    width: 100%;
    padding: 10px 0;
`
