import React from 'react'
import { FontAwsomeIconCP } from 'common/framework-ui/components/icons/FontAwsomeIconCP'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'

interface ILoadingCPProps {
    size?: SizeProp
    show?: boolean
}

/**
 * Componente loading.
 */
export function LoadingCP(props: ILoadingCPProps): JSX.Element {

    if (props.show !== undefined && !props.show)
        return <></>

    return (
        <LoadingWrapperSCP>
            <FontAwsomeIconCP type={faSpinner} color={'#6d1b99'} spin={true} size={props.size ?? '2x'}/>
        </LoadingWrapperSCP>
    )

}

const LoadingWrapperSCP = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

