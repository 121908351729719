import { LoginScreen } from 'modules/auth/screens/LoginScreen'
import React from 'react'
import { RouterSwitchCP } from 'submodules/nerit-framework-ui/common/router/components/router-switch/RouterSwitchCP'
import { ScreenError404 } from 'submodules/nerit-framework-ui/common/screens/ScreenError404'
import { RecoverPasswordScreen } from 'modules/auth/screens/RecoverPasswordScreen'

/**
 * ROTAS
 */
PublicRouter.ROOT = 'public'

PublicRouter.USER_LOGIN = `${PublicRouter.ROOT}/login`
PublicRouter.RECOVER_PWD = `${PublicRouter.ROOT}/recuperar-senha`

/**
 */
export function PublicRouter(): JSX.Element {

    return (
        <RouterSwitchCP
            routes={[
                { path: PublicRouter.USER_LOGIN, component: LoginScreen, exact: true },
                { path: PublicRouter.RECOVER_PWD, component: RecoverPasswordScreen, exact: true },
                { component: () => <ScreenError404 redirectTo={PublicRouter.USER_LOGIN}/> },
            ]}
        />
    )

}
